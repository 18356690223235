import { Box } from '@mui/material';
import styled from 'styled-components';

export const StyledItemCategory = styled(Box)({
  width: 380,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 10px',
  boxSizing: 'border-box',
  transition: '0.4s',
  cursor: 'pointer',

  '&:hover': {
    background: '#083061aa',
    color: '#fff',
  },

  '&:active': {
    background: '#083061',
    color: '#fff',
  },
});

/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import MonitorIcon from '@mui/icons-material/Monitor';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';

import { useParams } from 'react-router-dom';
import { StyledArea, StyledItemArea } from '../styles';
import AwardItem from './AwardItem';
import { useDebounce } from '../../../hooks/useDebounce';
import { compareStrings } from '../../../utils/string';

interface AwardsAreasProps {
  categories: any[];
  eventAreas?: any[];
  areas: number;
  event: any;
  numberDays: number;
  filters: any;
  verifyAthlete: (athlete: any) => void;
  deliveryAward: (athlete: any) => void;
  subscribeAthleteAbsolute: (athlete: any) => void;
  finishCategoryAward: (categoryId: string) => void;
}

const AwardsAreas: React.FC<AwardsAreasProps> = ({
  categories,
  eventAreas,
  areas,
  event,
  numberDays,
  verifyAthlete,
  deliveryAward,
  subscribeAthleteAbsolute,
  finishCategoryAward,
}): ReactElement => {
  const { eventId } = useParams();

  const isDash = useMemo(() => {
    return window.location.hash.includes('dash');
  }, []);

  const [areasObject, setAreasObject] = useState<any[]>([]);

  const [daySelected, setDaySelected] = useState('0');

  const [days, setDays] = useState<number[]>([]);

  const [search, setSearch] = useState('');

  const [showAll, setShowAll] = useState(false);

  const [selectedItem, setSelectedItem] = useState<any>(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenuOptions = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>, item: any) => {
    setAnchorEl(event.currentTarget);

    setSelectedItem(item);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const searchDebounce = useDebounce(search, 500);

  useEffect(() => {
    if (areas > 0 && eventAreas?.length && categories?.length) {
      let newItems = [...categories];
      const newDays: number[] = [];
      const newAreas = eventAreas.map((item) => {
        newDays.push(item.day);
        return {
          id: `${item.day}-${item.order}`,
          day: item.day,
          uuid: item.uuid,
          name: item.name,
          categories: item.categories?.length
            ? item.categories
                .filter((item: any) => item.status === 'completed')
                .filter((item: any) => item.generateKey === 'S')
                .map((category: any) => {
                  const categoryData = categories.find((itemCategory) => {
                    return itemCategory.categoryId === category.category;
                  });

                  newItems = newItems.filter(
                    (item) => item.categoryId !== category.category
                  );

                  return {
                    ...categoryData,
                    uuid: category.uuid,
                    awardStatus: category.awardStatus,
                    order: category.order,
                    fights: category.fights,
                    hasAbsolute: category.hasAbsolute,
                  };
                })

                .sort((a: any, b: any) => {
                  return a.order - b.order;
                })
            : [],
        };
      });

      setDays([...new Set(newDays)].sort());

      setAreasObject(newAreas);
    }
  }, [areas, eventAreas, categories, numberDays]);

  const filterByDay = (items: any[], day: number): any[] => {
    return items.filter((item) => item.day === day);
  };

  const getFisrt = (fight: any) => {
    let winner;
    for (const item of fight?.fights) {
      for (const athlete of item?.athletes) {
        if (athlete?.result?.position === 1) {
          winner = athlete;
          break;
        }
      }
      if (winner) {
        break;
      }
    }

    return winner;
  };

  const getSecond = (fight: any) => {
    let winner;
    for (const item of fight?.fights) {
      for (const athlete of item?.athletes) {
        if (athlete?.result?.position === 2) {
          winner = athlete;
          break;
        }
      }
      if (winner) {
        break;
      }
    }

    return winner;
  };

  const getThirds = useCallback((fight: any) => {
    const winners = [];
    for (const item of fight?.fights) {
      for (const athlete of item?.athletes) {
        if (athlete?.result?.position === 3) {
          winners.push(athlete);
        }
      }
    }

    return winners;
  }, []);

  const searchRef = useRef<HTMLInputElement>();

  const handleClickSearch = () => {
    if (search) {
      setSearch('');
    }

    // searchRef?.current?.focus();
  };

  const showOptions = (category: any): boolean => {
    if (!isDash) {
      return false;
    }

    return (
      category?.awardStatus === 'pending' &&
      category?.items.find((item: any) => !item.awardDelivered)
    );
  };

  const results = useMemo(() => {
    const day = daySelected ? Number(daySelected) : 0;

    const items = filterByDay(areasObject, day);

    const categories = [];

    for (const item of items) {
      for (const category of item.categories) {
        const items = [
          getFisrt(category),
          getSecond(category),
          ...getThirds(category),
        ].filter((item) => !!item);

        if (
          showAll ||
          (category.awardStatus === 'pending' &&
            items.find((item: any) => !item.awardDelivered))
        ) {
          categories.push({
            name: category.descriptionCategory,
            awardStatus: category.awardStatus,
            uuid: category.uuid,
            hasAbsolute: category.hasAbsolute,
            items,
          });
        }
      }
    }

    return categories;
  }, [areasObject, daySelected, getThirds, showAll]);

  const applySearch = useCallback(
    (items: any[]) => {
      if (!searchDebounce) {
        return items;
      }

      return items.filter((item) => {
        let hasItem = false;

        for (const athlete of item.items) {
          if (compareStrings(athlete.nameAthlete, searchDebounce)) {
            hasItem = true;
            break;
          }
        }

        return hasItem;
      });
    },
    [searchDebounce]
  );

  const getColor = (index: number): string => {
    const colors = ['#FFD700', '#C0C0C0', '#CD7F32', '#CD7F32'];

    return colors[index] || '';
  };

  const handleFinishCategoryAward = () => {
    finishCategoryAward(selectedItem?.uuid);
    handleClose();
  };

  return (
    <React.Fragment>
      {isDash && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            paddingTop: 24,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 20,
              flexDirection: 'column',
            }}
          >
            <div style={{ display: 'flex', gap: 16 }}>
              <Select
                value={daySelected}
                onChange={(event) => setDaySelected(event.target.value)}
                style={{
                  width: 120,
                }}
              >
                {days.map((item, index) => (
                  <MenuItem key={index} value={index}>
                    Dia {index + 1}
                  </MenuItem>
                ))}
              </Select>

              <TextField
                id="phone"
                inputRef={searchRef}
                variant="outlined"
                placeholder="Nome do atleta"
                name="phone"
                style={{ width: 420 }}
                InputLabelProps={{
                  style: {
                    fontSize: 16,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={handleClickSearch} edge="end">
                      {search ? <CloseIcon /> : <SearchIcon />}
                    </IconButton>
                  ),
                }}
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
            </div>

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showAll}
                    onChange={(e) => setShowAll(e.target.checked)}
                  />
                }
                label="Exibir todos (categorias com as premiações entregues seráo ocultadas)"
              />
            </FormGroup>
          </div>

          <Tooltip title="Espelhar tela em uma nova janela" arrow>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#fff',
                border: '1px solid #ccc',
                borderRadius: 8,
                padding: '4px 12px',
                boxSizing: 'border-box',
                gap: 16,
                minWidth: 50,
                width: 50,
                height: 50,
                cursor: 'pointer',
              }}
              onClick={() => {
                window.open(
                  `${window.location.origin}/#/events/${eventId}/awards`,
                  '_blank'
                );
              }}
            >
              <MonitorIcon fontSize="large" />
            </div>
          </Tooltip>
        </div>
      )}

      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          gap: 12,
        }}
      >
        <div style={{ width: '100%' }}>
          <div
            style={{
              width: '100%',
              overflowX: 'auto',
              display: 'flex',
              gap: 12,
              paddingLeft: 8,
              paddingTop: 48,
              justifyContent: isDash ? 'flex-start' : 'center',
            }}
          >
            <div style={{ minWidth: 500, width: 500 }}>
              <StyledItemArea>
                {applySearch(results)?.map((item: any, index: number) => (
                  <StyledArea key={index}>
                    <div
                      style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div>{item.name}</div>
                      {showOptions(item) && (
                        <div>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={
                              openMenuOptions ? 'long-menu' : undefined
                            }
                            aria-expanded={openMenuOptions ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={(event) => handleClick(event, item)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id="long-menu"
                            MenuListProps={{
                              'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={openMenuOptions}
                            onClose={handleClose}
                            slotProps={{
                              paper: {
                                style: {
                                  maxHeight: 48 * 4.5,
                                  width: '20ch',
                                },
                              },
                            }}
                          >
                            <MenuItem onClick={handleFinishCategoryAward}>
                              Ocultar categoria
                            </MenuItem>
                          </Menu>
                        </div>
                      )}
                    </div>

                    {item.items.map((athlete: any, index: number) => (
                      <AwardItem
                        key={index}
                        item={athlete}
                        color={getColor(index)}
                        backgroundTitle={
                          searchDebounce &&
                          compareStrings(athlete.nameAthlete, searchDebounce)
                            ? 'yellow'
                            : undefined
                        }
                        verifyAthlete={verifyAthlete}
                        deliveryAward={deliveryAward}
                        subscribeAthleteAbsolute={subscribeAthleteAbsolute}
                        isDash={isDash}
                        hasAbsolute={
                          item?.hasAbsolute &&
                          event?.momentSubscribeAbsolute === 'E' &&
                          event?.hasAbsolute === 'S'
                        }
                      />
                    ))}
                  </StyledArea>
                ))}

                {!applySearch(results)?.length && (
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      alignItems: 'center',
                    }}
                  >
                    {isDash
                      ? 'Nenhum resultado encontrado'
                      : 'Nenhuma premiação disponível'}
                  </div>
                )}
              </StyledItemArea>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AwardsAreas;

import { Box } from '@mui/material';
import styled from 'styled-components';

export const StyledRoot = styled(Box)({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  padding: '0px 24px 24px 24px',
  boxSizing: 'border-box',
});

export const StyledTitle = styled(Box)({
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  fontSize: 18,
  padding: 28,
  color: '#555',
  boxSizing: 'border-box',
});

/* eslint-disable no-plusplus */
import React, { ReactElement, useMemo } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { initialFilterFormValues } from './schema';
import { fightStatus } from '../../../utils/fight';

interface FiltersProps {
  areas: any[];
  filtersData?: {
    weight: string[];
    track: string[];
    category: string[];
    gym: string[];
    gender: string[];
  };
  hasFiltersApplied: boolean;
  handleSubmit: (values: any) => void;
}

const Filters: React.FC<FiltersProps> = ({
  areas,
  filtersData,
  hasFiltersApplied,
  handleSubmit,
}): ReactElement => {
  const data = useMemo(() => {
    const uniqueCategories = filtersData?.category || [];
    const uniqueTack = filtersData?.track || [];
    const uniqueWeight = filtersData?.weight || [];

    const uniqueGender = filtersData?.gender || [];

    const uniqueGym = filtersData?.gym || [];

    const uniqueAreas = areas.map((item) => ({
      id: item.id,
      name: item.name,
      day: item.day,
    }));

    const itemDays = areas.map((item) => item.day);

    const result = [...new Set(itemDays)];

    const uniqueDays = result.sort();

    return {
      uniqueCategories,
      uniqueTack,
      uniqueWeight,
      uniqueGender,
      uniqueGym,
      uniqueAreas,
      uniqueDays,
    };
  }, [filtersData, areas]);

  const onSubmit = (values: any) => {
    handleSubmit(values);
  };

  const formik = useFormik({
    initialValues: initialFilterFormValues,
    enableReinitialize: true,
    onSubmit,
  });

  const resetForm = () => {
    formik.setValues(initialFilterFormValues);
    handleSubmit(initialFilterFormValues);
  };

  const handleChangeCategory = (_event: any, value: any) => {
    formik.setFieldValue('category', value?.value ?? '');
  };

  const handleChangeArea = (_event: any, value: any) => {
    formik.setFieldValue('area', value?.value ?? '');
  };

  const handleChangeStatus = (_event: any, value: any) => {
    formik.setFieldValue('status', value?.value ?? '');
  };

  const handleChangeGym = (_event: any, value: any) => {
    formik.setFieldValue('gym', value?.value ?? '');
  };

  const handleChangeName = (event: any) => {
    formik.setFieldValue('name', event?.target?.value || '');
  };

  const handleChangeTrack = (_event: any, value: any) => {
    formik.setFieldValue('track', value?.value ?? '');
  };

  const handleChangeDay = (_event: any, value: any) => {
    formik.setFieldValue('day', value?.value ?? '');

    formik.setFieldValue('area', '');
  };

  const handleChangeWeight = (_event: any, value: any) => {
    formik.setFieldValue('weight', value?.value ?? '');
  };

  const handleChangeGender = (_event: any, value: any) => {
    formik.setFieldValue('gender', value?.value ?? '');
  };

  const filterByDay = (areas: any[], day: number | string): any[] => {
    return areas?.filter((item) => item.day === Number(day)) || [];
  };

  const statusOptions =
    fightStatus?.map((status: any) => ({
      label: status.label,
      value: status.value ?? status.status, // fallback se vier com .status
    })) || [];

  const selectedStatus =
    statusOptions.find((option) => option.value === formik.values.status) ||
    null;

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        padding: 16,
        gap: 16,
        boxSizing: 'border-box',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          gap: 16,
          boxSizing: 'border-box',
        }}
      >
        <TextField
          id="name"
          variant="outlined"
          placeholder="Nome do atleta"
          name="name"
          style={{ width: 420, maxWidth: '100%' }}
          InputLabelProps={{
            style: {
              fontSize: 16,
              textTransform: 'uppercase',
              background: '#fff',
              paddingRight: 5,
              color: '#000',
            },
            shrink: true,
          }}
          value={formik.values.name}
          onChange={handleChangeName}
        />

        {data.uniqueDays?.length > 1 && (
          <Autocomplete
            options={
              data.uniqueDays?.map((day: number) => ({
                label: `Dia ${day + 1}`,
                value: day,
              })) || []
            }
            value={
              data.uniqueDays
                ?.map((day: number) => ({
                  label: `Dia ${day + 1}`,
                  value: day,
                }))
                .find(
                  (option) => Number(option.value) === Number(formik.values.day)
                ) || null
            }
            isOptionEqualToValue={(option, value) =>
              option.value === value?.value
            }
            getOptionLabel={(option) => option.label || ''}
            onChange={handleChangeDay}
            onBlur={formik.handleBlur}
            fullWidth
            noOptionsText="Nenhuma opção encontrada"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                id="day"
                name="day"
                label="Dia"
                variant="outlined"
                value={formik.values.day}
                InputLabelProps={{
                  style: {
                    fontSize: 16,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
              />
            )}
          />
        )}

        <Autocomplete
          options={data.uniqueGym?.map((gym) => {
            return {
              label: gym,
              value: gym,
            };
          })}
          inputValue={formik.values.gym}
          isOptionEqualToValue={(item) => {
            return item.value === formik.values.gym;
          }}
          getOptionLabel={(item) => {
            return item.label;
          }}
          fullWidth
          onChange={handleChangeGym}
          onBlur={formik.handleBlur}
          noOptionsText="Nenhuma opção encontrada"
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              id="gym"
              variant="outlined"
              name="gym"
              label="Academia"
              value={formik.values.gym}
              InputLabelProps={{
                style: {
                  fontSize: 16,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />
          )}
        />

        <Autocomplete
          options={
            filterByDay(data.uniqueAreas, formik.values.day)?.map((area) => ({
              label: area.name,
              value: area.id,
            })) || []
          }
          value={
            filterByDay(data.uniqueAreas, formik.values.day)
              ?.map((area) => ({ label: area.name, value: area.id }))
              .find((option) => option.value === formik.values.area) || null
          }
          isOptionEqualToValue={(option, value) =>
            option.value === value?.value
          }
          getOptionLabel={(option) => option.label || ''}
          onChange={handleChangeArea}
          onBlur={formik.handleBlur}
          noOptionsText="Nenhuma opção encontrada"
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              id="area"
              name="area"
              label="Área"
              variant="outlined"
              error={Boolean(formik.touched.area && formik.errors.area)}
              helperText={formik.touched.area && formik.errors.area}
              InputLabelProps={{
                style: {
                  fontSize: 16,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />
          )}
        />

        <Autocomplete
          options={statusOptions}
          value={selectedStatus}
          isOptionEqualToValue={(option, value) =>
            option.value === value?.value
          }
          getOptionLabel={(option) => option.label || ''}
          onChange={handleChangeStatus}
          onBlur={formik.handleBlur}
          noOptionsText="Nenhuma opção encontrada"
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              id="status"
              name="status"
              label="Status"
              variant="outlined"
              error={Boolean(formik.touched.status && formik.errors.status)}
              helperText={formik.touched.status && formik.errors.status}
              InputLabelProps={{
                style: {
                  fontSize: 16,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />
          )}
        />
        <Accordion
          elevation={0}
          style={{ border: '1px solid #999', borderRadius: 4 }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <div>Categoria</div>
          </AccordionSummary>
          <AccordionDetails
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              gap: 16,
              boxSizing: 'border-box',
            }}
          >
            <Autocomplete
              options={data.uniqueCategories?.map((category) => ({
                label: category,
                value: category,
              }))}
              inputValue={formik.values.category}
              isOptionEqualToValue={(item) => {
                return item.value === formik.values.category;
              }}
              getOptionLabel={(item) => {
                return item.label;
              }}
              fullWidth
              onChange={handleChangeCategory}
              onBlur={formik.handleBlur}
              noOptionsText="Nenhuma opção encontrada"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  id="category"
                  variant="outlined"
                  name="category"
                  label="Categoria"
                  value={formik.values.category}
                  InputLabelProps={{
                    style: {
                      fontSize: 16,
                      textTransform: 'uppercase',
                      background: '#fff',
                      paddingRight: 5,
                      color: '#000',
                    },
                    shrink: true,
                  }}
                />
              )}
            />

            <Autocomplete
              options={data.uniqueTack?.map((track) => ({
                label: track,
                value: track,
              }))}
              inputValue={formik.values.track}
              isOptionEqualToValue={(item) => {
                return item.value === formik.values.track;
              }}
              getOptionLabel={(item) => {
                return item.label;
              }}
              fullWidth
              onChange={handleChangeTrack}
              onBlur={formik.handleBlur}
              noOptionsText="Nenhuma opção encontrada"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  id="track"
                  variant="outlined"
                  name="track"
                  label="Graduação"
                  value={formik.values.track}
                  InputLabelProps={{
                    style: {
                      fontSize: 16,
                      textTransform: 'uppercase',
                      background: '#fff',
                      paddingRight: 5,
                      color: '#000',
                    },
                    shrink: true,
                  }}
                />
              )}
            />

            <Autocomplete
              options={data.uniqueWeight?.map((weight) => ({
                label: weight,
                value: weight,
              }))}
              inputValue={formik.values.weight}
              isOptionEqualToValue={(item) => {
                return item.value === formik.values.weight;
              }}
              getOptionLabel={(item) => {
                return item.label;
              }}
              fullWidth
              onChange={handleChangeWeight}
              onBlur={formik.handleBlur}
              noOptionsText="Nenhuma opção encontrada"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  id="weight"
                  variant="outlined"
                  name="weight"
                  label="Peso"
                  value={formik.values.weight}
                  InputLabelProps={{
                    style: {
                      fontSize: 16,
                      textTransform: 'uppercase',
                      background: '#fff',
                      paddingRight: 5,
                      color: '#000',
                    },
                    shrink: true,
                  }}
                />
              )}
            />

            <Autocomplete
              options={data.uniqueGender?.map((gender) => ({
                label: gender,
                value: gender,
              }))}
              inputValue={formik.values.gender}
              isOptionEqualToValue={(item) => {
                return item.value === formik.values.gender;
              }}
              getOptionLabel={(item) => {
                return item.label;
              }}
              fullWidth
              onChange={handleChangeGender}
              onBlur={formik.handleBlur}
              noOptionsText="Nenhuma opção encontrada"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  id="gender"
                  variant="outlined"
                  name="gender"
                  label="Sexo"
                  value={formik.values.gender}
                  InputLabelProps={{
                    style: {
                      fontSize: 16,
                      textTransform: 'uppercase',
                      background: '#fff',
                      paddingRight: 5,
                      color: '#000',
                    },
                    shrink: true,
                  }}
                />
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Button
          color="primary"
          disableRipple
          variant="contained"
          type="submit"
          style={{
            letterSpacing: 1,
            fontSize: 14,
          }}
        >
          Filtrar
        </Button>
        {hasFiltersApplied && (
          <Button
            color="error"
            disableRipple
            style={{
              letterSpacing: 1,
              fontSize: 14,
            }}
            onClick={() => resetForm()}
          >
            Limpar filtros
          </Button>
        )}
      </div>
    </form>
  );
};

export default Filters;

/* eslint-disable no-plusplus */
import React, { ReactElement } from 'react';

import { Chart } from 'react-google-charts';

import { Avatar } from '@mui/material';
import {
  StyledCard,
  StyledContentCards,
  StyledCount,
  StyledNotFound,
  StyledRoot,
  StyledTable,
  StyledTableBody,
  StyledTableColumn,
  StyledTableColumnLeft,
  StyledTableHeader,
  StyledTableRow,
  StyledTitle,
} from './styles';
import {
  calcDiffInSeconds,
  formatOnlyHour,
  formatSecondsToMinutesAndSeconds,
  formatSecondsToTime,
} from '../../utils/date';
import { mapStage } from '../../utils/fight';

interface StatisticsReportsProps {
  data?: any;
}

const StatisticsReports: React.FC<StatisticsReportsProps> = ({
  data,
}): ReactElement => {
  return (
    <StyledRoot>
      <StyledContentCards>
        <StyledCard>
          <div>Áreas</div>
          <StyledCount>{data?.numberOfAreas || '0'}</StyledCount>
        </StyledCard>

        <StyledCard>
          <div>Atletas</div>
          <StyledCount>{data?.numberOfAthletes || '0'}</StyledCount>
        </StyledCard>

        <StyledCard>
          <div>Categorias</div>
          <StyledCount>{data?.numberOfCategories || '0'}</StyledCount>
        </StyledCard>

        <StyledCard>
          <div>Categorias com um atleta</div>
          <StyledCount>{data?.numberOfAthletesBye || '0'}</StyledCount>
        </StyledCard>

        <StyledCard>
          <div>Categorias finalizadas</div>
          <StyledCount>{data?.numberOfCategoriesCompleted || '0'}</StyledCount>
        </StyledCard>

        <StyledCard>
          <div>Lutas</div>
          <StyledCount>{data?.numberOfFights || '0'}</StyledCount>
        </StyledCard>

        <StyledCard>
          <div>Lutas finalizadas</div>
          <StyledCount>{data?.numberOfFightsCompleted || '0'}</StyledCount>
        </StyledCard>

        <StyledCard>
          <div>Lutas canceladas</div>
          <StyledCount>{data?.numberOfFightsCanceled || '0'}</StyledCount>
        </StyledCard>

        <StyledCard>
          <div>Lutas programadas</div>
          <StyledCount>{data?.numberOfFightsProgramed || '0'}</StyledCount>
        </StyledCard>

        <StyledCard>
          <div>Lutas pendentes de checkin</div>
          <StyledCount>{data?.numberOfFightsPending || '0'}</StyledCount>
        </StyledCard>
      </StyledContentCards>

      <div>
        <StyledTitle>Duração das Categorias</StyledTitle>

        <StyledTable>
          <StyledTableHeader>
            <StyledTableColumn>Categoria</StyledTableColumn>
            <StyledTableColumn>Inicio</StyledTableColumn>
            <StyledTableColumn>Fim</StyledTableColumn>
            <StyledTableColumn>Duração</StyledTableColumn>
          </StyledTableHeader>
          <StyledTableBody>
            {data?.categoriesTiming?.map((item: any, index: number) => (
              <StyledTableRow key={index}>
                <StyledTableColumnLeft>
                  {item.categoryName || '--'}
                </StyledTableColumnLeft>
                <StyledTableColumn>
                  {item.start ? formatOnlyHour(item.start) : '--'}
                </StyledTableColumn>
                <StyledTableColumn>
                  {item.start ? formatOnlyHour(item.finish) : '--'}
                </StyledTableColumn>
                {Boolean(item.finish) && Boolean(item.start) && (
                  <StyledTableColumn>
                    {formatSecondsToTime(
                      calcDiffInSeconds(item.finish, item.start)
                    )}
                  </StyledTableColumn>
                )}
              </StyledTableRow>
            ))}

            {!data?.categoriesTiming?.length && (
              <StyledNotFound>Nenhuma informação encontrada</StyledNotFound>
            )}
          </StyledTableBody>
        </StyledTable>
      </div>

      <div>
        <StyledTitle>Atletas</StyledTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {Boolean(data?.countAthletesDesclassified?.length) && (
            <div style={{ minWidth: 500 }}>
              <Chart
                chartType="PieChart"
                data={[
                  ['Motivo', 'Quantidade'],
                  ...data?.countAthletesDesclassified?.map((item: any) => {
                    return [item.reason, item.count];
                  }),
                ]}
                options={{ title: 'Atletas por tipo de desclassifição' }}
                width={'100%'}
                height={'300px'}
              />
            </div>
          )}

          {Boolean(data?.countAthletesWin?.length) && (
            <div style={{ minWidth: 500 }}>
              <Chart
                chartType="PieChart"
                data={[
                  ['Motivo', 'Quantidade'],
                  ...data?.countAthletesWin?.map((item: any) => {
                    return [item.typeVictoryDescription, item.count];
                  }),
                ]}
                options={{ title: 'Atletas por tipo de vitoria' }}
                width={'100%'}
                height={'300px'}
              />
            </div>
          )}
        </div>
      </div>

      <div>
        <StyledTitle>Top 10 lutas que mais pontuaram</StyledTitle>

        <StyledTable>
          <StyledTableHeader>
            <StyledTableColumn>Categoria</StyledTableColumn>
            <StyledTableColumn>Fase</StyledTableColumn>
            <StyledTableColumn>Pontos</StyledTableColumn>
          </StyledTableHeader>
          <StyledTableBody>
            {data?.topFightsPoints?.map((item: any, index: number) => (
              <StyledTableRow key={index}>
                <StyledTableColumnLeft>
                  {item?.eventAreaCategory?.categoryName || '--'}
                </StyledTableColumnLeft>
                <StyledTableColumn>{mapStage(item.stage)}</StyledTableColumn>
                <StyledTableColumn>{item.totalPoints || '0'}</StyledTableColumn>
              </StyledTableRow>
            ))}

            {!data?.topFightsPoints?.length && (
              <StyledNotFound>Nenhuma informação encontrada</StyledNotFound>
            )}
          </StyledTableBody>
        </StyledTable>
      </div>

      <div>
        <StyledTitle>Top 10 lutas mais rápidas</StyledTitle>

        <StyledTable>
          <StyledTableHeader>
            <StyledTableColumn>Atleta</StyledTableColumn>
            <StyledTableColumn>Categoria</StyledTableColumn>
            <StyledTableColumn>Fase</StyledTableColumn>
            <StyledTableColumn>Tempo</StyledTableColumn>
          </StyledTableHeader>
          <StyledTableBody>
            {data?.fightFaster?.map((item: any, index: number) => (
              <StyledTableRow key={index}>
                <StyledTableColumnLeft>
                  {item.athletes.map((item: any) => (
                    <React.Fragment key={item.nameAthlete}>
                      <Avatar
                        alt={item?.nameAthlete}
                        src={item?.photo}
                        sx={{ width: 32, height: 32 }}
                        style={{
                          border: '1px solid #000',
                        }}
                      />
                      <div>{item.nameAthlete || '--'}</div>
                    </React.Fragment>
                  ))}
                </StyledTableColumnLeft>
                <StyledTableColumn>
                  {item?.eventAreaCategory?.categoryName || '--'}
                </StyledTableColumn>
                <StyledTableColumn>{mapStage(item.stage)}</StyledTableColumn>
                <StyledTableColumn>
                  {item.minTimeFight
                    ? formatSecondsToMinutesAndSeconds(item.minTimeFight)
                    : '0s'}
                </StyledTableColumn>
              </StyledTableRow>
            ))}

            {!data?.fightFaster?.length && (
              <StyledNotFound>Nenhuma informação encontrada</StyledNotFound>
            )}
          </StyledTableBody>
        </StyledTable>
      </div>

      <div>
        <StyledTitle>Top 10 atletas que mais pontuaram</StyledTitle>

        <StyledTable>
          <StyledTableHeader>
            <StyledTableColumn>Atleta</StyledTableColumn>
            <StyledTableColumn>Categoria</StyledTableColumn>
            <StyledTableColumn>Pontos</StyledTableColumn>
          </StyledTableHeader>
          <StyledTableBody>
            {data?.athletesMorePoints?.map((item: any, index: number) => (
              <StyledTableRow key={index}>
                <StyledTableColumnLeft>
                  <Avatar
                    alt={item?.nameAthlete}
                    src={item?.photo}
                    sx={{ width: 32, height: 32 }}
                    style={{
                      border: '1px solid #000',
                    }}
                  />
                  <div>{item.nameAthlete || '--'}</div>
                </StyledTableColumnLeft>
                <StyledTableColumn>
                  {item.categoryName || '--'}
                </StyledTableColumn>
                <StyledTableColumn>{item.totalPoints || '0'}</StyledTableColumn>
              </StyledTableRow>
            ))}

            {!data?.athletesMorePoints?.length && (
              <StyledNotFound>Nenhuma informação encontrada</StyledNotFound>
            )}
          </StyledTableBody>
        </StyledTable>
      </div>

      <div>
        <StyledTitle>Top 10 atletas que mais finalizaram</StyledTitle>

        <StyledTable>
          <StyledTableHeader>
            <StyledTableColumn>Atleta</StyledTableColumn>
            <StyledTableColumn>Categoria</StyledTableColumn>
            <StyledTableColumn>Finalizações</StyledTableColumn>
          </StyledTableHeader>
          <StyledTableBody>
            {data?.athletesMoreFinish?.map((item: any, index: number) => (
              <StyledTableRow key={index}>
                <StyledTableColumnLeft>
                  <Avatar
                    alt={item?.nameAthlete}
                    src={item?.photo}
                    sx={{ width: 32, height: 32 }}
                    style={{
                      border: '1px solid #000',
                    }}
                  />
                  <div>{item.nameAthlete || '--'}</div>
                </StyledTableColumnLeft>
                <StyledTableColumn>
                  {item.categoryName || '--'}
                </StyledTableColumn>
                <StyledTableColumn>{item.total || '0'}</StyledTableColumn>
              </StyledTableRow>
            ))}

            {!data?.athletesMoreFinish?.length && (
              <StyledNotFound>Nenhuma informação encontrada</StyledNotFound>
            )}
          </StyledTableBody>
        </StyledTable>
      </div>

      <div>
        <StyledTitle>Atletas desclassificados</StyledTitle>

        <StyledTable>
          <StyledTableHeader>
            <StyledTableColumn>Atleta</StyledTableColumn>
            <StyledTableColumn>Categoria</StyledTableColumn>
            <StyledTableColumn>Motivo</StyledTableColumn>
          </StyledTableHeader>
          <StyledTableBody>
            {data?.athletesDesclassifiedList?.map(
              (item: any, index: number) => (
                <StyledTableRow key={index}>
                  <StyledTableColumnLeft>
                    <Avatar
                      alt={item?.nameAthlete}
                      src={item?.photo}
                      sx={{ width: 32, height: 32 }}
                      style={{
                        border: '1px solid #000',
                      }}
                    />
                    <div>{item.nameAthlete || '--'}</div>
                  </StyledTableColumnLeft>
                  <StyledTableColumn>
                    {item?.eventAreaCategoryFight?.eventAreaCategory
                      ?.categoryName || '--'}
                  </StyledTableColumn>
                  <StyledTableColumn>{item.reason || '--'}</StyledTableColumn>
                </StyledTableRow>
              )
            )}

            {!data?.athletesDesclassifiedList?.length && (
              <StyledNotFound>Nenhuma informação encontrada</StyledNotFound>
            )}
          </StyledTableBody>
        </StyledTable>
      </div>
    </StyledRoot>
  );
};

export default StatisticsReports;

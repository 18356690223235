/* eslint-disable no-plusplus */
import React, { ReactElement } from 'react';

import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { StyledRoot, StyledTitle } from './styles';
import { maskPhone } from '../../../../utils/phone';
import InputSearch from './InputSearch';

interface Area {
  id: number;
  name: string;
  day: number;
}

interface ListUsersProps {
  data?: any;
  areas?: Area[];
  onSearch: (search: string) => void;
  handleDelete: (user: any) => void;
  handleEdit: (user: any) => void;
}

interface Column {
  id: 'name' | 'phone' | 'role' | 'areas' | 'options';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'name', label: 'Nome' },
  { id: 'phone', label: 'Telefone' },
  { id: 'role', label: 'Permissão' },
  { id: 'areas', label: 'Áreas' },
  { id: 'options', label: '' },
];

const ListUsers: React.FC<ListUsersProps> = ({
  data,
  areas,
  onSearch,
  handleDelete,
  handleEdit,
}): ReactElement => {
  const formatRole = (role: string): string => {
    const roles = {
      [role]: role,
      S: 'Staff',
      M: 'Mesário',
      C: 'Checkin',
      P: 'Premiação',
      E: 'Estatisticas',
      K: 'Coordenador de área',
    };

    return roles[role] || role;
  };

  const hasOnlyOneDay = React.useMemo(() => {
    if (!areas?.length) return true;

    const firstDay = areas[0].day;

    const allSame = areas.every((item) => item.day === firstDay);

    return allSame;
  }, [areas]);

  const formatAreaName = (area: Area): string => {
    if (hasOnlyOneDay) {
      return `${area.name}`;
    }
    return `Dia ${area.day + 1} - ${area.name}`;
  };

  const formatAreas = (userAreas: string): ReactElement[] => {
    const areasSplited = userAreas.split(',');

    areasSplited.sort((a, b) => Number(a) - Number(b));

    const test = areasSplited.map((item) => {
      const area = areas?.find((area) => area.id === Number(item));

      if (area) {
        return formatAreaName(area);
      }

      return '';
    });

    return test.map((item) => <div key={item}>{item}</div>);
  };

  return (
    <StyledRoot>
      <StyledTitle>Usuários</StyledTitle>

      <div>
        <InputSearch onChange={onSearch} />
      </div>

      <div>
        <Paper sx={{ width: '100%', marginTop: '16px' }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} style={{ fontWeight: 'bold' }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row: any) => {
                  return (
                    <TableRow hover key={row.userId}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{maskPhone(row.phone)}</TableCell>
                      <TableCell>{formatRole(row.role)}</TableCell>
                      <TableCell>
                        {row.areas ? formatAreas(row.areas) : '-'}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton onClick={() => handleEdit(row)}>
                          <EditOutlinedIcon color="primary" />
                        </IconButton>
                        <IconButton onClick={() => handleDelete(row)}>
                          <DeleteOutlineIcon color="error" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </StyledRoot>
  );
};

export default ListUsers;

import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import MonitorIcon from '@mui/icons-material/Monitor';

import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledRoot } from './styles';
import useAuthenticate from '../../../recoil/hooks/authenticate';

import {
  getEventAreasFights,
  getEventCategories,
  getEventFilters,
  getReasonDesclassifiedRequest,
  handleCheckinRequest,
} from '../../../api/events';

import Card from '../../../components/Card';
import DetailsInfo from '../../../components/DetailsInfo';

import ModalInvalid from './ModalInvalid';
import NoAreas from '../../../components/NoAreas';
import ConfirmModal from '../../../components/ConfirmModal';
import CheckinAreas from '../../../components/CheckinAreas';
import CheckinModal from '../../../components/CheckinModal';

const Checkin: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const { eventId } = useParams();

  const [filtersData, setFiltersData] = useState({
    category: '',
    track: '',
    weight: '',
    gender: '',
    typeCategory: '',
    daySelected: '',
    areasSelected: [],
  });

  const [isEditing, setEditing] = useState(false);

  const [modalConfirm, setModalConfirm] = useState<{
    open: boolean;
    title: string;
    message: string;
    onConfirm: () => void;
    onCancel?: () => void;
  }>({
    open: false,
    title: 'Atenção',
    message:
      'O evento já começou, ao editar as áreas os dados do placar podem sofrer alterações. Deseja continuar?',
    onConfirm: () => {
      setEditing(true);
      setModalConfirm((prev) => ({
        ...prev,
        open: false,
      }));
    },
  });

  const defaultModalCheckin = {
    open: false,
    athlete: null,
    onCancel: () => {
      setModalConfirm((prev) => ({
        ...prev,
        open: false,
      }));
    },
  };

  const [modalCheckin, setModalCheckin] = useState<{
    open: boolean;
    athlete: any;
    onConfirm?: () => void;
    onCancel?: () => void;
  }>(defaultModalCheckin);

  const [event, setEvent] = useState<any>(null);
  const [categories, setCategories] = useState<any>([]);

  const [eventAreas, setEventAreas] = useState<any>([]);

  const [reasonDesclassified, setReasonDesclassified] = useState<any>([]);

  const defaultReasonCheckin = useMemo(() => {
    if (reasonDesclassified?.length && modalCheckin?.athlete) {
      const reason = reasonDesclassified.find(
        (item: any) =>
          item.id === modalCheckin.athlete?.reasonDesclassificationCode
      );

      return reason?.id || '';
    }

    return '';
  }, [reasonDesclassified, modalCheckin]);

  const [filters, setFilters] = useState<any>({});

  const [isModalInvalid, setModalInvalid] = useState(false);

  const [isLoading, setLoading] = useState(true);
  const [isLoadingAreas, setLoadingAreas] = useState(true);
  const [isLoadingFilters, setLoadingFilters] = useState(true);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      if (authenticate.token && eventId) {
        const data = await getEventCategories(authenticate.token, eventId);

        setEvent(data?.event || null);
        setCategories(data?.categories || []);

        if (!data?.event?.numberAreas) {
          setModalInvalid(true);
        }
      }
    } catch (error) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }, [authenticate.token, eventId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const getReasonDesclassified = useCallback(async () => {
    try {
      if (authenticate.token) {
        const data = await getReasonDesclassifiedRequest(authenticate.token);

        setReasonDesclassified(data?.data || []);
      }
    } catch (error) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    }
  }, [authenticate.token]);

  useEffect(() => {
    getReasonDesclassified();
  }, [getReasonDesclassified]);

  const getEventAreasFightsData = useCallback(
    async (defaultLoading = true) => {
      try {
        setLoadingAreas(defaultLoading);
        if (authenticate.token && eventId) {
          const data = await getEventAreasFights(authenticate.token, eventId);

          setEventAreas(data?.areas || []);
        }
      } catch (error) {
        toast(
          'Não foi possível executar essa ação. Por favor, tente novamente!',
          { type: 'error' }
        );
      } finally {
        setLoadingAreas(false);
      }
    },
    [authenticate.token, eventId]
  );

  useEffect(() => {
    getEventAreasFightsData();

    const intervalId = setInterval(() => {
      getEventAreasFightsData(false);
    }, 20000);

    return () => clearInterval(intervalId);
  }, [getEventAreasFightsData]);

  const getFilters = useCallback(async () => {
    try {
      setLoadingFilters(true);
      if (eventId) {
        const data = await getEventFilters(eventId);
        setFilters(data?.filters || {});
      }
    } catch (error) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    } finally {
      setLoadingFilters(false);
    }
  }, [eventId]);

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  const isLoadingGeneral = useMemo(() => {
    return isLoading || isLoadingAreas || isLoadingFilters;
  }, [isLoading, isLoadingAreas, isLoadingFilters]);

  const handleCheckin = async (athlete: any): Promise<void> => {
    setModalCheckin({
      ...modalCheckin,
      open: true,
      athlete,
    });
  };

  const handleSubmitCheckin = async (values: any) => {
    try {
      await handleCheckinRequest(authenticate.token, values);

      toast('Checkin realizado com sucesso!', { type: 'success' });

      setModalCheckin(defaultModalCheckin);

      getEventAreasFightsData(false);
    } catch (error) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    }
  };

  const handleRedirectCategory = (area: any, category: any) => {
    window.open(
      `/#/dash/events/${eventId}/areas/${area.uuid}/brackets/${category.uuid}`,
      '_blank'
    );
  };

  return (
    <React.Fragment>
      <ModalInvalid
        open={isModalInvalid}
        cause={[]}
        handleClose={() => navigate(`/dash/events/${eventId}/home?edit=true`)}
        message="Alguns campos do evento não estão configurados. Por favor, finaliza a configuração primeiro!"
      />

      <StyledRoot>
        <Card>
          <div style={{ width: '100%' }}>
            <DetailsInfo
              isLoading={isLoading}
              sections={[
                {
                  rows: [
                    {
                      key: 'name',
                      label: 'Nome',
                    },
                    {
                      key: 'numberDaysFight',
                      label: 'Nº de dias',
                    },
                    {
                      key: 'numberAreas',
                      label: 'Nº de áreas',
                    },
                  ],
                  data: event,
                  title: 'Evento',
                },
              ]}
              actions={[
                {
                  tooltip: 'Espelhar tela em uma nova janela',
                  onClick: () =>
                    window.open(
                      `${
                        window.location.origin
                      }/#/events/${eventId}/checkin?day=${
                        filtersData?.daySelected || '0'
                      }&areas=${filtersData?.areasSelected?.join(',') || ''}`,
                      '_blank'
                    ),
                  icon: <MonitorIcon fontSize="large" />,
                },
              ]}
            />

            {!isLoadingGeneral &&
              !!event &&
              !eventAreas?.length &&
              !isEditing && (
                <NoAreas
                  title="Checkin"
                  message="Configure as áreas do evento para começar!"
                  handleStart={() =>
                    navigate(`/dash/events/${eventId}/areas?isEditing=true`)
                  }
                />
              )}

            {!isLoadingGeneral && !!event && !!eventAreas?.length && (
              <CheckinAreas
                categories={categories}
                eventAreas={eventAreas}
                filtersData={filters}
                areas={event?.numberAreas}
                numberDays={event?.numberDaysFight}
                startTime={event.startTimeFight}
                handleCheckin={handleCheckin}
                reasonDesclassified={reasonDesclassified}
                changeFilters={(filters: any) => setFiltersData(filters)}
                handleRedirectCategory={handleRedirectCategory}
              />
            )}
          </div>
        </Card>
      </StyledRoot>
      <ConfirmModal
        open={modalConfirm.open}
        handleClose={() => {
          setModalConfirm((prev) => {
            return {
              ...prev,
              open: false,
            };
          });
        }}
        handleCancel={modalConfirm.onCancel}
        handleConfirm={modalConfirm.onConfirm}
        title={modalConfirm.title}
        message={modalConfirm.message}
        confirmColor="#333"
      />

      <CheckinModal
        open={modalCheckin.open}
        reasonDesclassified={reasonDesclassified}
        athlete={modalCheckin.athlete}
        handleBack={() => setModalCheckin(defaultModalCheckin)}
        handleSubmit={handleSubmitCheckin}
        defaultReason={defaultReasonCheckin}
        defaultValue={
          modalCheckin?.athlete?.status === 'desclassified'
            ? 'desclassified'
            : 'ready'
        }
      />
    </React.Fragment>
  );
};

export default Checkin;

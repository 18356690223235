/* eslint-disable no-restricted-syntax */
import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import SettingsIcon from '@mui/icons-material/Settings';

import List from 'rc-virtual-list';
import FightItem from '../../ShowAreas/AreasFights/FightItem';
import { compareStrings } from '../../../utils/string';
import { getLatestStageFights } from '../../../pages/private/Checkin/utils';
import { mapAthleteStatus, sortArrayV2 } from '../../../utils/fight';

interface AreasFightsProps {
  categories: any[];
  startTime: string;
  eventAreas?: any[];
  areas: number;
  numberDays: number;
  filters: any;
  reasonDesclassified?: any[];
  searchAthlete?: string;
  statusFilter: {
    oneAthlete: boolean;
    pending: boolean;
    programed: boolean;
    completed: boolean;
    canceled: boolean;
  };
  redirect: (item: any) => void;
  handleCheckin: (athlete: any) => void;
  handleRedirectCategory: (area: any, category: any) => void;
}

const AreasFights: React.FC<AreasFightsProps> = ({
  categories,
  eventAreas,
  areas,
  numberDays,
  reasonDesclassified,
  filters,
  searchAthlete,
  statusFilter,
  handleCheckin,
  handleRedirectCategory,
}): ReactElement => {
  const [areasObject, setAreasObject] = useState<any[]>([]);

  useEffect(() => {
    if (areas > 0 && eventAreas && categories?.length) {
      const newDays: number[] = [];

      const newAreas = eventAreas.map((item) => {
        newDays.push(item.day);

        const fights: any[] = [];

        item.categories.forEach((category: any) => {
          getLatestStageFights(category.fights).forEach((fight: any) => {
            if (fight.athletes.length) {
              fights.push({
                ...fight,
                category,
              });
            }
          });
        });

        return {
          id: `${item.day}-${item.order}`,
          day: item.day,
          uuid: item.uuid,
          name: item.name,
          categories: [],
          fights,
        };
      });

      setAreasObject(newAreas);
    }
  }, [areas, eventAreas, categories, numberDays]);

  const applySearch = useCallback(
    (items: any[]) => {
      if (!searchAthlete) {
        return items;
      }

      return items.filter((item) => {
        let hasItem = false;

        for (const athlete of item.athletes) {
          if (compareStrings(athlete.nameAthlete, searchAthlete || '')) {
            hasItem = true;
            break;
          }
        }

        return hasItem;
      });
    },
    [searchAthlete]
  );

  const applyStatusFilters = useCallback(
    (items: any[]) => {
      const status: any[] = [];

      if (statusFilter.pending) {
        status.push('pending');
      }

      if (statusFilter.programed) {
        status.push('programed');
      }

      if (statusFilter.completed) {
        status.push('completed');
      }

      if (statusFilter.canceled) {
        status.push('canceled');
      }

      return items.filter((item) => {
        if (statusFilter.oneAthlete) {
          return (
            item.category?.numberFights === 0 && status.includes(item.status)
          );
        }
        return status.includes(item.status);
      });
    },
    [statusFilter]
  );

  const applyFilters = useCallback(
    (items: any) => {
      if (
        filters.category ||
        filters.gender ||
        filters.track ||
        filters.weight ||
        filters.typeCategory ||
        filters.typeCategoryMultiple?.length ||
        filters.categoryMultiple?.length ||
        filters.genderMultiple?.length ||
        filters.weightMultiple?.length ||
        filters.trackMultiple?.length
      ) {
        const itemsFiltered = items.filter((item: any) => {
          let avaliable = true;
          if (
            filters.category &&
            !item?.category?.categoryName?.includes(filters.category)
          ) {
            avaliable = false;
          }
          if (
            filters.gender &&
            !item?.category?.categoryName?.includes(filters.gender)
          ) {
            avaliable = false;
          }
          if (
            filters.weight &&
            !item?.category?.categoryName?.includes(filters.weight)
          ) {
            avaliable = false;
          }
          if (
            filters.track &&
            !item?.category?.categoryName?.includes(filters.track)
          ) {
            avaliable = false;
          }
          if (
            filters.typeCategory &&
            item?.category?.typeCategory !== filters.typeCategory
          ) {
            avaliable = false;
          }

          if (
            filters.typeCategoryMultiple?.length &&
            !filters.typeCategoryMultiple.includes(item?.category?.typeCategory)
          ) {
            avaliable = false;
          }

          if (
            filters.categoryMultiple?.length &&
            !filters.categoryMultiple.find((category: string) =>
              item?.category?.categoryName?.includes(category)
            )
          ) {
            avaliable = false;
          }

          if (
            filters.genderMultiple?.length &&
            !filters.genderMultiple.find((gender: string) =>
              item?.category?.categoryName?.includes(gender)
            )
          ) {
            avaliable = false;
          }

          if (
            filters.weightMultiple?.length &&
            !filters.weightMultiple.find((weight: string) =>
              item?.category?.categoryName?.includes(weight)
            )
          ) {
            avaliable = false;
          }

          if (
            filters.trackMultiple?.length &&
            !filters.trackMultiple.find((track: string) =>
              item?.category?.categoryName?.includes(track)
            )
          ) {
            avaliable = false;
          }

          return avaliable;
        });

        return itemsFiltered;
      }

      return items;
    },
    [filters]
  );

  const prepareData = (fights: any[]): any[] => {
    const data = applyFilters(fights);

    const dataSorted = sortArrayV2(data);

    const result = applySearch(dataSorted);

    return applyStatusFilters(result);
  };

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        gap: 12,
      }}
    >
      <div style={{ width: '100%' }}>
        <div
          style={{
            width: '100%',
            overflowX: 'auto',
            display: 'flex',
            gap: 18,
          }}
        >
          {areasObject.map((item, index) => (
            <div key={index} style={{ minWidth: 400, width: 400 }}>
              <div>{item.name}</div>
              <div
                style={{
                  border: '1px solid #ccc',
                  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                  borderRadius: 8,
                  width: 400,
                  minHeight: 100,
                  marginTop: 12,
                  boxSizing: 'border-box',
                  // padding: 16,
                  gap: 8,
                  display: 'flex',
                  flexDirection: 'column',

                  height: 500,
                  overflowY: 'auto',

                  marginBottom: 8,
                }}
              >
                <List
                  data={prepareData(item.fights)}
                  height={466}
                  itemHeight={225}
                  itemKey="id"
                  style={{ padding: 16, boxSizing: 'border-box' }}
                >
                  {(fight) => (
                    <FightItem
                      key={fight.id}
                      fight={fight}
                      showAge
                      area={item}
                      reasons={reasonDesclassified}
                      mapBackgroundByStatus={mapAthleteStatus}
                      action={
                        fight?.status !== 'completed' || !fight?.startAt
                          ? {
                              action: (athlete) => handleCheckin(athlete),
                              icon: <SettingsIcon />,
                              label: 'Checkin',
                              isDisabled: (athlete) => !athlete,
                            }
                          : undefined
                      }
                      handleRedirectCategory={handleRedirectCategory}
                    />
                  )}
                </List>

                {/* {prepareData(item.fights)?.map((fight: any) => (
                  <FightItem
                    key={fight.id}
                    fight={fight}
                    showAge
                    area={item}
                    reasons={reasonDesclassified}
                    action={
                      fight?.status !== 'completed' || !fight?.startAt
                        ? {
                            action: (athlete) => handleCheckin(athlete),
                            icon: <SettingsIcon />,
                            label: 'Checkin',
                            isDisabled: (athlete) => !athlete,
                          }
                        : undefined
                    }
                    handleRedirectCategory={handleRedirectCategory}
                  />
                ))} */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AreasFights;

import * as React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import useAuthenticate from './recoil/hooks/authenticate';

interface Props {
  redirectPath?: string;
  children: React.ReactElement;
  role?: string;
}

const PrivateRoute = ({ redirectPath = '/', children, role }: Props): any => {
  const navigate = useNavigate();

  const { authenticate, logout } = useAuthenticate();

  React.useEffect(() => {
    if (!authenticate?.isAuthenticate) {
      logout();
      navigate('/');
    }
  }, [authenticate, logout, navigate]);

  if (role && !role.split('.').includes(authenticate?.user?.role)) {
    return <Navigate to={redirectPath} replace />;
  }

  return <div>{children}</div>;
};

export default PrivateRoute;

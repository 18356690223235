/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const sortArrayV2 = (array: any[]): any[] => {
  if (!array?.length) return [];

  const stagePriority: Record<string, number> = {
    knockout: 1,
    'eighth-final': 2,
    'quarter-final': 3,
    semifinal: 4,
    bronze: 5,
    final: 6,
  };

  return array.sort((a, b) => {
    const timeA = new Date(a.date).getTime();
    const timeB = new Date(b.date).getTime();

    if (timeA !== timeB) {
      return timeA - timeB;
    }

    const stageA = stagePriority[a.stage] || 0;
    const stageB = stagePriority[b.stage] || 0;

    if (stageA !== stageB) {
      return stageA - stageB;
    }

    return a.order - b.order;
  });
};

export const mapStage = (stage: any): string => {
  const stageOrder: any = {
    knockout: 'Eliminatórias',
    'eighth-final': 'Oitavas de final',
    'quarter-final': 'Quartas de final',
    semifinal: 'Semifinal',
    bronze: 'Disputa de Terceiro',
    final: 'Final',
  };

  return stageOrder[stage] || stage;
};

export const mapStatusOld = (
  status: any
): { label: string; color: string; background: string } => {
  const options: any = {
    pending: { label: 'A confirmar', color: '#fff', background: '#ffa727' },
    completed: { label: 'Finalizada', color: '#000', background: '#66bb6b' },

    canceled: { label: 'Cancelada', color: '#fff', background: '#404243' },
    programed: {
      label: 'Aguardando luta',
      color: '#000',
      background: '#66bb6b',
    },
    stopped: {
      label: 'Aguardando resultado',
      color: '#000',
      background: '#ffa727',
    },
    in_progress: {
      label: 'Em andamento',
      color: '#000',
      background: '#29b6f6',
    },

    bye: {
      label: 'BYE',
      color: '#000',
      background: '#8bc34a',
    },
  };

  return options[status] || {};
};

export const mapStatus = (
  status: any
): { label: string; color: string; background: string } => {
  const options: any = {
    pending: {
      label: 'A confirmar',
      color: '#212529',
      background: '#FFE082',
    },
    in_progress: {
      label: 'Em andamento',
      color: '#ffffff',
      background: '#42A5F5',
    },
    completed: {
      label: 'Finalizada',
      color: '#ffffff',
      background: '#66BB6A',
    },
    canceled: {
      label: 'Cancelada',
      color: '#ffffff',
      background: '#757575',
    },
    programed: {
      label: 'Aguardando luta',
      color: '#1B5E20',
      background: '#C8E6C9',
    },
    stopped: {
      label: 'Aguardando resultado',
      color: '#4E342E',
      background: '#FFCCBC',
    },
    bye: {
      label: 'BYE',
      color: '#263238',
      background: '#AED581',
    },
  };
  return options[status] || {};
};

export const mapStatusFight = (
  status: any
): { label: string; color: string; background: string } => {
  const options: any = {
    pending: { label: 'Aguardando checkin', background: '#ffa727' },
    canceled: {
      label: 'Ambos desclassificados',
      color: '#fff',
      background: '#404243',
    },

    default: { label: 'Confirmada', color: '#000', background: '#66bb6b' },
  };

  return options[status] || options.default;
};

// export const mapBackgroundByStatus = (status: any): string => {
export const mapAthleteStatus = (
  status: any
): { color: string; label: string } => {
  const options: any = {
    ready: { color: '#66bb6b', label: 'Confirmado' },
    pending: { color: '#9c27b0', label: 'Aguardando checkin' },
    desclassified: { color: '#e74c3c', label: 'Desclassificado' },
    default: { color: '#999', label: '' },
  };

  return options[status] || options.default;
};

export const fightStatus = [
  {
    status: '',
    label: 'Todas as lutas',
  },
  {
    status: 'pending',
    label: 'A confirmar',
  },
  {
    status: 'in_progress',
    label: 'Em andamento',
  },
  {
    status: 'completed',
    label: 'Finalizadas',
  },
  {
    status: 'programed',
    label: 'Aguardando luta',
  },
  {
    status: 'canceled',
    label: 'Cancelada',
  },
];

import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { Box, MenuItem, Select } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import AreasFights from './AreasFights';
import CheckinLegend from '../CheckinLegend';
import FilterOrganizeAreaV2 from '../ShowAreas/FilterOrganizeAreaV2';
import InputSearch from './InputSearch';
import SelectArea from './SelectArea';
import StatusFilter from './StatusFilter';

interface CheckinAreasProps {
  categories: any[];
  startTime: string;
  eventAreas?: any[];
  areas: number;
  numberDays: number;
  filtersData?: {
    weight: string[];
    track: string[];
    category: string[];
  };
  handleCheckin: (athlete: any) => void;
  reasonDesclassified?: any[];
  changeFilters?: (filters: any) => void;
  handleRedirectCategory: (area: any, category: any) => void;
}

const CheckinAreas: React.FC<CheckinAreasProps> = ({
  categories,
  startTime,
  eventAreas,
  areas,
  filtersData,
  numberDays,
  reasonDesclassified,
  handleCheckin,
  changeFilters,
  handleRedirectCategory,
}): ReactElement => {
  const navigate = useNavigate();

  const [daySelected, setDaySelected] = useState('0');

  const [search, setSearch] = useState('');

  const [statusFilter, setStatusFilter] = useState({
    canceled: false,
    completed: false,
    in_progress: false,
    oneAthlete: false,
    pending: true,
    programed: true,
  });

  const [days, setDays] = useState<number[]>([]);

  const [areasSelected, setAreasSelected] = useState<number[]>([]);

  const [filters, setFilters] = useState({
    category: '',
    track: '',
    weight: '',
    gender: '',
    typeCategory: '',
    typeCategoryMultiple: [] as string[],
    categoryMultiple: [] as string[],
    trackMultiple: [] as string[],
    weightMultiple: [] as string[],
    genderMultiple: [] as string[],
  });

  useEffect(() => {
    if (changeFilters) {
      changeFilters({ ...filters, daySelected, areasSelected });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daySelected, filters, areasSelected]);

  useEffect(() => {
    const newDays: number[] = [];

    if (eventAreas?.length) {
      eventAreas.forEach((item) => {
        newDays.push(item.day);
      });

      setDays([...new Set(newDays)].sort());
    }
  }, [eventAreas]);

  const handleRedirectFight = (fight: any) => {
    navigate(`/scoreboard/${fight.uuid}`);
  };

  const filterByDay = (items: any[], day: number): any[] => {
    return items.filter((item) => item.day === day);
  };

  const filterByAreas = (items: any[], areasSelected: number[]): any[] => {
    if (!areasSelected.length) {
      return [];
    }
    const result = items.filter((item) => areasSelected.includes(item.id));

    return result;
  };

  const eventsAreasToSelect = useMemo(() => {
    return filterByDay(eventAreas || [], Number(daySelected));
  }, [eventAreas, daySelected]);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        padding: '0px 24px 24px 24px',
        boxSizing: 'border-box',
      }}
    >
      <div style={{ textAlign: 'center', fontSize: 18, paddingBottom: 12 }}>
        Visualizar áreas
      </div>

      {!!filtersData && (
        <div>
          <div>Filtros</div>
          <div>
            <FilterOrganizeAreaV2
              categories={categories}
              filtersData={filtersData}
              handleSubmit={setFilters}
              hasFiltersApplied={Boolean(
                filters.category ||
                  filters.gender ||
                  filters.track ||
                  filters.weight ||
                  filters.typeCategory ||
                  filters.typeCategoryMultiple?.length ||
                  filters.categoryMultiple?.length ||
                  filters.weightMultiple?.length ||
                  filters.trackMultiple?.length ||
                  filters.genderMultiple?.length
              )}
            />
          </div>
        </div>
      )}

      <CheckinLegend
        items={[
          {
            color: '#66bb6b',
            label: 'Checkin realizado',
          },
          {
            color: '#9c27b0',
            label: 'Aguardando checkin',
          },
          {
            color: '#e74c3c',
            label: 'Desclassificado',
          },
        ]}
      />

      <Box
        sx={{
          width: '100%',

          flexDirection: 'column',
        }}
      >
        <div style={{ display: 'flex', gap: 16, padding: 16 }}>
          <Select
            value={daySelected}
            onChange={(event) => setDaySelected(event.target.value)}
            style={{
              width: 120,
            }}
          >
            {days.map((item, index) => (
              <MenuItem key={index} value={index}>
                Dia {index + 1}
              </MenuItem>
            ))}
          </Select>

          <SelectArea
            eventAreas={eventsAreasToSelect}
            onChange={setAreasSelected}
          />

          <InputSearch onChange={setSearch} />
        </div>

        <div style={{ display: 'flex', gap: 16, padding: '0 16px 16px 16px' }}>
          <StatusFilter
            onChange={setStatusFilter}
            defaultValue={{
              canceled: false,
              completed: false,
              in_progress: false,
              oneAthlete: false,
              pending: true,
              programed: true,
            }}
          />
        </div>

        <AreasFights
          areas={areas}
          categories={categories}
          filters={filters}
          numberDays={numberDays}
          startTime={startTime}
          eventAreas={filterByAreas(eventAreas || [], areasSelected)}
          redirect={handleRedirectFight}
          handleCheckin={handleCheckin}
          reasonDesclassified={reasonDesclassified}
          searchAthlete={search}
          statusFilter={statusFilter}
          handleRedirectCategory={handleRedirectCategory}
        />
      </Box>
    </div>
  );
};

export default CheckinAreas;

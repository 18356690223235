/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import api from '../services/api';

export const createAreaBroadcastRequest = async (
  token: string,
  fightId: string,
  data: any
): Promise<void> => {
  await api.post(`/api/broadcast/${fightId}/create`, data, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });
};

export const startTimeAreaBroadcastRequest = async (
  token: string,
  fightId: string
): Promise<void> => {
  await api.post(
    `/api/broadcast/${fightId}/start-time`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );
};

export const pauseTimeAreaBroadcastRequest = async (
  token: string,
  fightId: string
): Promise<void> => {
  await api.post(
    `/api/broadcast/${fightId}/pause-time`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );
};

export const decreaseTimeAreaBroadcastRequest = async (
  token: string,
  fightId: string
): Promise<void> => {
  await api.post(
    `/api/broadcast/${fightId}/decrease-time`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );
};

export const addTimeAreaBroadcastRequest = async (
  token: string,
  fightId: string
): Promise<void> => {
  await api.post(
    `/api/broadcast/${fightId}/add-time`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );
};

export const resetTimeAreaBroadcastRequest = async (
  token: string,
  fightId: string
): Promise<void> => {
  await api.post(
    `/api/broadcast/${fightId}/reset-time`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateAreaBroadcastRequest = async (
  token: string,
  fightId: string,
  data: any
): Promise<void> => {
  await api.put(`/api/broadcast/${fightId}/update`, data, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });
};

export default {
  createAreaBroadcastRequest,
  startTimeAreaBroadcastRequest,
  pauseTimeAreaBroadcastRequest,
  resetTimeAreaBroadcastRequest,

  updateAreaBroadcastRequest,
};

import React, { useState } from 'react';
import {
  Bracket,
  Seed,
  SeedItem,
  SeedTeam,
  IRoundProps,
  IRenderSeedProps,
} from 'react-brackets';

import { QRCodeSVG } from 'qrcode.react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Badge, ClickAwayListener, Tooltip } from '@mui/material';
import {
  formatOnlyHour,
  formatSecondsToMinutesAndSeconds,
} from '../../../../utils/date';
import { mapStage, mapStatus } from '../../../../utils/fight';

interface LosingProps {
  rounds: IRoundProps[];
  reasonDesclassified: any[];
  handleClickFight?: (fight: any) => void;
}

interface ItemTeamProps {
  athlete: any;
  oponent: any;
  seed: any;
  reasonDesclassified: any[];
  backgroundColor?: string;
  nameColor?: string;
  teamColor?: string;

  desclassifiedIconColor?: string;

  handleClickFight?: (fight: any) => void;
}

const isDebug = localStorage.getItem('debug') === 'true';

const RenderItemTeam = ({
  athlete,
  seed,
  reasonDesclassified,
  backgroundColor = '#2B4755',
  nameColor = '#fff',
  teamColor = '#ccc',

  desclassifiedIconColor = '#fff',
  handleClickFight,
}: ItemTeamProps) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const toggleTooltip = () => {
    setIsTooltipVisible(true);
  };

  const hideTooltip = () => {
    setIsTooltipVisible(false);
  };

  const getBadgeIcon = (athleteOne: any) => {
    if (athleteOne?.win) {
      return (
        <CheckCircleIcon
          style={{
            width: 16,
            height: 16,
            position: 'absolute',
            right: '-5px',
            bottom: '-5px',
          }}
          htmlColor="lightgreen"
        />
      );
    }

    // if (athleteOne && athleteTwo?.win) {
    //   return <CancelIcon style={{ width: 16, height: 16 }} htmlColor="red" />;
    // }

    return undefined;
  };

  const getBadgeBorder = (athleteOne: any) => {
    if (athleteOne?.win) {
      return '2px solid lightgreen';
    }

    // if (athleteOne && athleteTwo?.win) {
    //   return '2px solid #FF0000';
    // }

    return '2px solid #000';
  };

  const formatName = (name?: string, byeMatch?: boolean) => {
    if (name) {
      return name;
    }

    if (byeMatch) {
      return '-----------';
    }
    return '';
  };

  const getReasonDesclassification = (code: number): React.ReactElement => {
    const reason = reasonDesclassified?.find((item: any) => item.id === code);

    const text = reason?.description || 'Desclassificado';

    return (
      <>
        <b>Atleta desclassificado</b>

        <p>Motivo: {text}</p>
      </>
    );
  };

  return (
    <div style={{ display: 'flex', position: 'relative' }}>
      {!!athlete?.qrcode && (
        <div
          style={{
            width: 56,
            height: 56,
            background: 'red',
            display: 'flex',
            flex: 1,
            position: 'absolute',
            left: -66,
            top: 0,
          }}
        >
          <QRCodeSVG value={athlete?.qrcode} size={56} />
        </div>
      )}
      <SeedTeam
        style={{
          justifyContent: 'flex-start',
          gap: 8,
          height: 60,
          background: backgroundColor,
          borderRadius: 4,
          display: 'flex',
          width: 350,
          boxSizing: 'border-box',
          border: '1px solid #ddd',
        }}
        onClick={() => handleClickFight?.(seed)}
      >
        <div style={{ position: 'relative', width: 40 }}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={getBadgeIcon(athlete)}
          >
            <div
              style={{
                width: 36,
                height: 36,
                borderRadius: 36,
                border: getBadgeBorder(athlete),
                background: '#ccc',
              }}
            ></div>
          </Badge>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            width: 'calc(350px - 96px)',
            flex: 1,
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <div
            style={{
              textAlign: 'left',
              width: '300px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              color: nameColor,
            }}
          >
            {formatName(athlete?.name, seed.bye_match)}
          </div>
          <div
            style={{
              textAlign: 'left',
              color: teamColor,
              fontSize: 12,

              width: '200px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {athlete?.team || '---'}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            width: 24,
            height: 20,
          }}
        >
          {athlete?.reasonDesclassificationCode && (
            <ClickAwayListener onClickAway={hideTooltip}>
              <Tooltip
                placement="left"
                title={getReasonDesclassification(
                  athlete?.reasonDesclassificationCode
                )}
                open={isTooltipVisible}
                arrow
                onClose={hideTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                slotProps={{
                  popper: {
                    disablePortal: true,
                  },
                }}
              >
                <div
                  style={{
                    display: 'flex',
                  }}
                  onMouseEnter={() => setIsTooltipVisible(true)} // Hover para desktop
                  onMouseLeave={() => setIsTooltipVisible(false)} // Sair do hover
                  onClick={toggleTooltip} // Clique para mobile
                >
                  <InfoOutlinedIcon htmlColor={desclassifiedIconColor} />
                </div>
              </Tooltip>
            </ClickAwayListener>
          )}
        </div>
      </SeedTeam>
    </div>
  );
};

const WiningBracket: React.FC<LosingProps> = ({
  rounds: wining,
  reasonDesclassified,
  handleClickFight,
}) => {
  const [seedTooltipVisible, setSeedTooltipVisible] = useState('');

  const toggleTooltip = (id: string) => {
    setSeedTooltipVisible(id);
  };

  const hideTooltip = () => {
    setSeedTooltipVisible('');
  };

  const RenderSeed = ({
    seed,
    breakpoint,
    handleClickFight,
  }: IRenderSeedProps & { handleClickFight?: (fight: any) => void }) => {
    const getTeam = (teams: any[], index: number) => {
      return teams?.find((item) => item.order === index);
    };

    const renderTooltip = () => {
      const winReason = seed?.teams?.[0]?.result?.typeVictoryDescription;
      const time = seed?.teams?.[0]?.result?.timeFight;

      const athleteOne = getTeam(seed?.teams, 0);
      const athleteTwo = getTeam(seed?.teams, 1);

      return (
        <div
          style={{
            minWidth: 200,
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            padding: 8,
            boxSizing: 'border-box',
            fontSize: 16,
          }}
        >
          <div style={{ fontSize: 18, fontWeight: 'bold' }}>
            {mapStage(seed.stage)}
          </div>
          {Boolean(winReason) && (
            <div style={{}}>
              Vitória por
              <br />
              <span style={{ fontWeight: 'bold' }}>{winReason}</span>
            </div>
          )}
          {Boolean(athleteOne) &&
            Boolean(athleteTwo) &&
            !athleteOne?.reasonDesclassificationCode &&
            !athleteTwo?.reasonDesclassificationCode && (
              <div>
                <div style={{ display: 'flex', gap: 8 }}>
                  <div>Pontos</div>
                  <div>
                    {athleteOne?.result?.points || '0'} -{' '}
                    {athleteTwo?.result?.points || '0'}{' '}
                  </div>
                </div>
                <div style={{ display: 'flex', gap: 8 }}>
                  <div>Vantagens</div>
                  <div>
                    {athleteOne?.result?.advantages || '0'} -{' '}
                    {athleteTwo?.result?.advantages || '0'}{' '}
                  </div>
                </div>
                <div style={{ display: 'flex', gap: 8 }}>
                  <div>Desvantagens</div>
                  <div>
                    {athleteOne?.result?.faults || '0'} -{' '}
                    {athleteTwo?.result?.faults || '0'}{' '}
                  </div>
                </div>
              </div>
            )}
          {Boolean(seed.finishAt) && (
            <div>Tempo {formatSecondsToMinutesAndSeconds(time || 0)}</div>
          )}
          <div>
            Finalizou às{' '}
            {seed.finishAt ? formatOnlyHour(seed.finishAt) : 'xx:xx'}
          </div>
        </div>
      );
    };

    return (
      <Seed
        style={{
          cursor: seed.status === 'completed' ? 'pointer' : '',
        }}
        className="custom-border"
        mobileBreakpoint={breakpoint}
      >
        <ClickAwayListener onClickAway={() => null}>
          <Tooltip
            title={
              seed.status === 'completed' && !handleClickFight
                ? renderTooltip()
                : undefined
            }
            arrow
            placement="right"
            open={seedTooltipVisible === seed.id}
            onClose={hideTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            slotProps={{
              popper: {
                disablePortal: true,
              },
            }}
          >
            <div
              onMouseEnter={() => setSeedTooltipVisible(seed.id as string)} // Hover para desktop
              onMouseLeave={() => setSeedTooltipVisible('')} // Sair do hover
              onClick={() => toggleTooltip(seed.id as string)} // Clique para mobile
            >
              {isDebug && (
                <>
                  <div style={{ color: 'red' }}>ID: {seed.fightId}</div>
                  <div style={{ color: 'red' }}>ID: {seed.id}</div>
                </>
              )}

              <SeedItem
                style={{
                  width: 350,
                  marginTop: 14,
                  background: '#fff',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    left: 0,
                    bottom: 48,
                    background: '#777',
                    border: '1px solid #ccc',
                    zIndex: 100,
                    fontSize: 10,
                    padding: 4,
                    boxSizing: 'border-box',
                    borderRadius: 12,
                  }}
                >
                  {seed.tag}
                </div>
                <div>
                  <RenderItemTeam
                    athlete={getTeam(seed?.teams, 0)}
                    oponent={getTeam(seed?.teams, 1)}
                    reasonDesclassified={reasonDesclassified}
                    seed={seed}
                    handleClickFight={handleClickFight}
                  />

                  <div style={{ height: 1, background: '#ccc' }}></div>
                  <RenderItemTeam
                    athlete={getTeam(seed?.teams, 1)}
                    oponent={getTeam(seed?.teams, 0)}
                    reasonDesclassified={reasonDesclassified}
                    seed={seed}
                    backgroundColor="#FFF"
                    nameColor="#000"
                    teamColor="#000"
                    desclassifiedIconColor="#2B4755"
                    handleClickFight={handleClickFight}
                  />
                </div>

                <div
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: -24,
                    background: mapStatus(seed.status)?.background,
                    color: mapStatus(seed.status)?.color,
                    zIndex: 100,
                    fontSize: 10,
                    padding: 4,
                    boxSizing: 'border-box',
                    borderRadius: 12,
                  }}
                >
                  {mapStatus(seed.status)?.label}
                </div>
              </SeedItem>
            </div>
          </Tooltip>
        </ClickAwayListener>
        <div>
          {seed?.area ? `${seed.area} - ` : ''}
          {!!seed.date && formatOnlyHour(new Date(seed.date))}
        </div>
      </Seed>
    );
  };

  return (
    <Bracket
      rounds={wining}
      bracketClassName="bracket-custom-style"
      renderSeedComponent={({
        breakpoint,
        roundIndex,
        seed,
        seedIndex,
        rounds,
      }) => (
        <RenderSeed
          breakpoint={breakpoint}
          roundIndex={roundIndex}
          seed={seed}
          seedIndex={seedIndex}
          rounds={rounds}
          handleClickFight={handleClickFight}
        />
      )}
      mobileBreakpoint={100}
      swipeableProps={{
        enableMouseEvents: true,
        animateHeight: true,
        style: {
          padding: '0 50px 0 0',
        },
      }}
    />
  );
};

export default WiningBracket;

import React from 'react';
import { useFormik } from 'formik';
import { Button, CircularProgress, TextField } from '@mui/material';

import { StyledForm, StyledLogo, StyledRoot, StyledContent } from './styles';
import {
  LoginFormProps,
  initialLoginFormValues,
  validationSchema,
} from './schema';
import { maskPhone } from '../../utils/phone';

interface Props {
  isLoading: boolean;
  onSubmit: (values: LoginFormProps) => void;
}

const LoginForm: React.FC<Props> = ({
  isLoading,
  onSubmit,
}): React.ReactElement => {
  const formik = useFormik({
    initialValues: initialLoginFormValues,
    validationSchema,
    validateOnBlur: false,
    onSubmit,
  });

  const handleChangePhone = (key: string, event: any): void => {
    const value = maskPhone(event.target.value);

    formik.setFieldValue(key, value);
  };

  return (
    <StyledRoot>
      <StyledLogo src="/images/ilutas.png" />

      <StyledContent>
        <StyledForm onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="phone"
            variant="outlined"
            name="phone"
            placeholder="Digite aqui!"
            autoFocus
            InputLabelProps={{
              style: {
                fontSize: 16,
                textTransform: 'uppercase',
                background: '#fff',
                paddingRight: 5,
                color: '#000',
              },
              shrink: true,
            }}
            inputProps={{ maxLength: 15 }}
            label="Celular"
            value={formik.values.phone}
            onChange={(event) => handleChangePhone('phone', event)}
            onBlur={formik.handleBlur}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />
          <Button
            color="primary"
            variant="contained"
            fullWidth
            disableRipple
            style={{
              height: 45,
            }}
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Entrar'}
          </Button>
        </StyledForm>
      </StyledContent>
    </StyledRoot>
  );
};

export default LoginForm;

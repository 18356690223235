import styled from 'styled-components';

const mapColor = (status: string, allowdropathlete?: boolean): string => {
  if (allowdropathlete) {
    return '#fff9c4';
  }

  if (['completed', 'bye'].includes(status)) {
    return '#dcedc8';
  }

  if (status === 'canceled') {
    return '#efefef';
  }

  return '';
};

export const StyledItem = styled('div')<{
  status: string;
  allowdropathlete?: boolean;
}>(({ status, onClick, allowdropathlete }) => ({
  transition: '0.4s',
  padding: 16,

  position: 'relative',

  boxSizing: 'border-box',
  borderRadius: 8,

  background: mapColor(status, allowdropathlete),
  '&:hover': onClick
    ? {
        cursor: 'pointer',
        background: !['completed', 'bye'].includes(status)
          ? '#f1f1f1'
          : undefined,
      }
    : undefined,
}));

/* eslint-disable camelcase */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import List from 'rc-virtual-list';

import LanIcon from '@mui/icons-material/Lan';

import PrintIcon from '@mui/icons-material/PrintOutlined';

import {
  Box,
  CircularProgress,
  CircularProgressProps,
  FormControlLabel,
  FormGroup,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { StyledItemCategory } from '../styles';
import {
  addMinutesToDate,
  differenceInMinutesCeil,
  formatDate,
  formatMinutesToTime,
  formatOnlyHour,
} from '../../../utils/date';
import { compareStrings } from '../../../utils/string';
import { sortArrayV2 } from '../../../utils/fight';
import { StyleNameArea } from './styles';
import { handleExportCategoryToPdfAllInOne } from '../../../pages/private/CategoryBrackets/utils';
import useLoading from '../../../recoil/hooks/loading';

interface AreasCategoryProps {
  eventAreas?: any[];
  numberDays: number;
  filters: any;
  daySelected: string;
  search?: string;
  showInfo?: boolean;
  hasFiltersApplied: boolean;
  statusFilter?: {
    oneAthlete: boolean;
    pending: boolean;
    programed: boolean;
    completed: boolean;
    canceled: boolean;
    in_progress: boolean;
  };
  redirect?: (area: any, item: any) => void;
  handleChangeBroadcast?: (area: string, active: boolean) => Promise<void>;
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number; label: string | number }
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        color="success"
        {...props}
        classes={{ circle: '' }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ fontSize: 16 }}
        >{`${props.label}`}</Typography>
      </Box>
    </Box>
  );
}

const AreasCategory: React.FC<AreasCategoryProps> = ({
  eventAreas,
  filters,
  numberDays,
  daySelected,
  search,
  showInfo = true,
  statusFilter,
  hasFiltersApplied,
  redirect,
  handleChangeBroadcast,
}): ReactElement => {
  const [areasObject, setAreasObject] = useState<any[]>([]);

  const [days, setDays] = useState<number[]>([]);

  const { setLoadingModal } = useLoading();

  const applySearch = useCallback(
    (items: any[]) => {
      if (!search) {
        return items;
      }

      return items.filter((item) => {
        let hasItem = false;

        const hasName = compareStrings(item?.categoryName, search);

        if (hasName) {
          return true;
        }

        for (const fight of item.fights) {
          for (const athlete of fight?.athletes) {
            if (compareStrings(athlete?.nameAthlete, search)) {
              hasItem = true;
              break;
            }
          }
        }

        return hasItem;
      });
    },
    [search]
  );

  useEffect(() => {
    const newDays: number[] = [];
    const newAreas = eventAreas?.map((item) => {
      newDays.push(item.day);
      return {
        id: `${item.day}-${item.order}`,
        uuid: item.uuid,
        day: item.day,
        name: item.name,
        broadcast: item.broadcast,
        categories: item.categories?.length
          ? item.categories
              .map((category: any) => {
                return {
                  ...category,
                  fights: category.fights,
                  order: category.order,
                };
              })
              .sort((a: any, b: any) => {
                return a.order - b.order;
              })
          : [],
      };
    });

    setDays([...new Set(newDays)].sort());

    setAreasObject(newAreas || []);
  }, [eventAreas, numberDays]);

  const applyFilters = useCallback(
    (items: any) => {
      if (
        filters.category ||
        filters.gender ||
        filters.track ||
        filters.weight ||
        filters.typeCategory ||
        filters.typeCategoryMultiple?.length ||
        filters.categoryMultiple?.length ||
        filters.genderMultiple?.length ||
        filters.weightMultiple?.length ||
        filters.trackMultiple?.length
      ) {
        const itemsFiltered = items.filter((item: any) => {
          let avaliable = true;
          if (filters.category && item.categoryName !== filters.category) {
            avaliable = false;
          }
          if (filters.gender && item.genderName !== filters.gender) {
            avaliable = false;
          }
          if (filters.weight && item.weightName !== filters.weight) {
            avaliable = false;
          }
          if (filters.track && item.trackName !== filters.track) {
            avaliable = false;
          }
          if (
            filters.typeCategory &&
            item.typeCategoryName !== filters.typeCategory
          ) {
            avaliable = false;
          }

          if (
            filters.typeCategoryMultiple?.length &&
            !filters.typeCategoryMultiple.includes(item?.typeCategory)
          ) {
            avaliable = false;
          }

          if (
            filters.categoryMultiple?.length &&
            !filters.categoryMultiple.find((category: string) =>
              item?.categoryName?.includes(category)
            )
          ) {
            avaliable = false;
          }

          if (
            filters.genderMultiple?.length &&
            !filters.genderMultiple.find((gender: string) =>
              item?.categoryName?.includes(gender)
            )
          ) {
            avaliable = false;
          }

          if (
            filters.weightMultiple?.length &&
            !filters.weightMultiple.find((weight: string) =>
              item?.categoryName?.includes(weight)
            )
          ) {
            avaliable = false;
          }

          if (
            filters.trackMultiple?.length &&
            !filters.trackMultiple.find((track: string) =>
              item?.categoryName?.includes(track)
            )
          ) {
            avaliable = false;
          }

          return avaliable;
        });

        return itemsFiltered;
      }

      return items;
    },
    [filters]
  );

  const formatName = (item: any): string => {
    return item.descriptionCategory || item.categoryName;
  };

  const countNumberFight = (categories: any[]): number => {
    return categories.reduce(
      (prev, current) => {
        return (
          Number(prev) +
          (current.generateKey === 'N' ? 0 : Number(current.numberFights))
        );
      },
      [0]
    );
  };

  const countNumberAthletes = (categories: any[]): number => {
    return categories.reduce(
      (prev, current) => {
        return (
          Number(prev) +
          (current.generateKey === 'S' ? Number(current.numberAthletes) : 0)
        );
      },
      [0]
    );
  };

  const filterByDay = (items: any[], day: number): any[] => {
    return items.filter((item) => item.day === day);
  };

  const getFistDate = (categories: any[]): string => {
    if (!categories.length) {
      return '--';
    }

    const fights = [];

    for (const category of categories) {
      if (category?.fights?.length) {
        fights.push(...category.fights);
      }
    }

    const fightsSorted = sortArrayV2(fights);

    const firstFight = fightsSorted?.[0];

    if (!firstFight) {
      return '';
    }

    return formatOnlyHour(firstFight.date);
  };

  const getFinalEta = (categories: any[]): string => {
    if (!categories.length) {
      return 'xx:xx';
    }

    const fights = [];

    for (const category of categories) {
      if (category?.fights?.length) {
        fights.push(...category.fights);
      }
    }

    const fightsSorted = sortArrayV2(fights);

    if (!fightsSorted.length) {
      return '';
    }

    return formatOnlyHour(fightsSorted[fightsSorted.length - 1].date);
  };

  const getTimeTotal = (categories: any[]): string => {
    if (!categories.length) {
      return '--';
    }
    const fights = [];

    for (const category of categories) {
      if (category?.fights?.length) {
        fights.push(...category.fights);
      }
    }

    const fightsSorted = sortArrayV2(fights);

    const firstFight = fightsSorted[0];
    const lastFight = fightsSorted[fightsSorted.length - 1];

    if (!firstFight || !lastFight) {
      return '';
    }

    const diff = differenceInMinutesCeil(lastFight.date, firstFight.date);

    return `${formatMinutesToTime(diff)}`;
  };

  const getFisrtFightDate = (fights: any[]): string => {
    const fightsSorted = sortArrayV2(fights);
    if (fightsSorted?.length) {
      return formatOnlyHour(fightsSorted[0].date);
    }

    return '-';
  };

  const getFisrtFightDateFull = (fights: any[]): string => {
    const fightsSorted = sortArrayV2(fights);
    if (fightsSorted?.length) {
      return formatDate(fightsSorted[0].date);
    }

    return '-';
  };

  const getEndFightDate = (category: any): string => {
    const fightsSorted = sortArrayV2(category?.fights);
    if (fightsSorted?.length && fightsSorted[fightsSorted.length - 1].date) {
      const date =
        category.numberFights > 0
          ? addMinutesToDate(
              fightsSorted[fightsSorted.length - 1].date,
              category.timeCategory
            )
          : fightsSorted[fightsSorted.length - 1].date;
      return formatOnlyHour(date);
    }

    return '-';
  };

  const renderObjectDay = useMemo(() => {
    if (typeof daySelected !== 'number') {
      return [days[0]];
    }

    return days.filter((item) => Number(item) === Number(daySelected));
  }, [days, daySelected]);

  const applyStatusFilters = useCallback(
    (items: any[]) => {
      const status: any[] = [];

      if (!statusFilter) {
        return items;
      }

      if (statusFilter?.pending) {
        status.push('pending');
      }

      if (statusFilter?.programed) {
        status.push('programed');
      }

      if (statusFilter?.completed) {
        status.push('completed');
      }

      if (statusFilter?.canceled) {
        status.push('canceled');
      }

      if (statusFilter?.in_progress) {
        status.push('in_progress');
      }

      return items.filter((item) => {
        const hasFight = item?.fights?.find((fight: any) =>
          status.includes(fight.status)
        );

        if (statusFilter?.oneAthlete) {
          return item?.numberFights === 0 && hasFight;
        }
        return hasFight;
      });
    },
    [statusFilter]
  );

  const renderItems = useCallback(
    (item: any) => {
      const itemsSearch = applySearch(item.categories);

      const itemsFiltered = applyFilters(itemsSearch);

      const result = applyStatusFilters(itemsFiltered);

      return result;
    },
    [applyFilters, applySearch, applyStatusFilters]
  );

  const handleChange = (event: any, item: any) => {
    const active = event.target.checked;

    if (handleChangeBroadcast) {
      handleChangeBroadcast?.(item.uuid, active);
    }
  };

  const countFinishFights = (category: any): number => {
    const total = category?.fights?.length || 0;

    const finishFights = category?.fights?.filter((item: any) =>
      ['completed', 'canceled'].includes(item.status)
    );

    const totalFinish = finishFights?.length || 0;
    return (totalFinish * 100) / total;
  };

  const { eventId } = useParams();

  const handleDownloadPdfAllInOne = async (item: any) => {
    setLoadingModal(true);

    await handleExportCategoryToPdfAllInOne(eventId, item);

    setLoadingModal(false);
  };

  return (
    <React.Fragment>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          gap: 12,
        }}
      >
        <div style={{ width: '100%' }}>
          {renderObjectDay.map((day, index) => (
            <React.Fragment key={`${index}-${day}`}>
              <div
                style={{
                  width: '100%',
                  overflowX: 'auto',
                  display: 'flex',
                  gap: 18,
                  paddingBottom: 16,
                  boxSizing: 'border-box',
                }}
              >
                {filterByDay(areasObject, day).map((item, index) => {
                  const items = renderItems(item);

                  if (!items.length && hasFiltersApplied) {
                    return <></>;
                  }
                  return (
                    <div key={index} style={{ minWidth: 416, width: 416 }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: 8,
                          alignItems: 'center',
                        }}
                      >
                        <StyleNameArea>
                          {item.name}

                          <div
                            style={{
                              cursor: 'pointer',
                              // opacity: 0,
                              transition: '0.3s',
                            }}
                            onClick={() => handleDownloadPdfAllInOne(item)}
                          >
                            <PrintIcon />
                          </div>
                        </StyleNameArea>
                        {handleChangeBroadcast && (
                          <div>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch defaultChecked={item.broadcast} />
                                }
                                label="Transmitir"
                                labelPlacement="start"
                                onChange={(event) => handleChange(event, item)}
                              />
                            </FormGroup>
                          </div>
                        )}
                      </div>
                      <div
                        style={{
                          border: '1px solid #ccc',
                          borderRadius: 8,
                          width: 416,
                          minHeight: 100,
                          marginTop: 12,
                          boxSizing: 'border-box',
                          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',

                          height: 400,
                          overflowY: 'auto',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            width: 380,
                            fontSize: 12,
                            padding: 10,
                            boxSizing: 'border-box',
                          }}
                        >
                          <div
                            style={{
                              width: 260,
                              boxSizing: 'border-box',
                            }}
                          >
                            Categoria
                          </div>

                          <div
                            style={{
                              width: 40,
                              textAlign: 'center',
                              boxSizing: 'border-box',
                            }}
                          >
                            HEI
                          </div>
                          <div
                            style={{
                              width: 40,
                              textAlign: 'center',
                              boxSizing: 'border-box',
                            }}
                          >
                            HEF
                          </div>
                          <div
                            style={{
                              width: 40,
                              textAlign: 'center',
                              boxSizing: 'border-box',
                            }}
                          >
                            Lutas
                          </div>
                          <div
                            style={{
                              width: 40,
                              textAlign: 'center',
                              boxSizing: 'border-box',
                            }}
                          ></div>
                        </div>
                        <List
                          data={items}
                          height={350}
                          itemHeight={70}
                          itemKey="id"
                        >
                          {(category) => (
                            <StyledItemCategory
                              style={{}}
                              // onClick={() => redirect?.(item, category)}
                            >
                              <div style={{ width: 300 }}>
                                <div>{formatName(category)}</div>
                                {category.generateKey === 'N' && (
                                  <div style={{ color: '#aaa', fontSize: 14 }}>
                                    Apresentação
                                  </div>
                                )}
                              </div>

                              <Tooltip
                                title={getFisrtFightDateFull(category.fights)}
                                arrow
                              >
                                <div style={{ width: 40, textAlign: 'center' }}>
                                  {getFisrtFightDate(category.fights)}
                                </div>
                              </Tooltip>
                              <div
                                style={{
                                  width: 40,
                                  textAlign: 'center',
                                  marginLeft: 8,
                                }}
                              >
                                {getEndFightDate(category)}
                              </div>

                              <div
                                style={{
                                  width: 40,
                                  textAlign: 'center',
                                  marginLeft: 8,
                                }}
                              >
                                {category.generateKey === 'N' ? (
                                  '-'
                                ) : (
                                  <CircularProgressWithLabel
                                    value={countFinishFights(category)}
                                    label={category.numberFights}
                                  />
                                )}
                              </div>
                              <div
                                style={{
                                  width: 40,
                                  textAlign: 'center',
                                  marginLeft: 8,
                                }}
                              >
                                {category.generateKey === 'N' ? (
                                  '-'
                                ) : (
                                  <Tooltip title="Visualizar categoria" arrow>
                                    <div
                                      onClick={() => redirect?.(item, category)}
                                      // onClick={() => console.log('area', fight, area)}
                                    >
                                      <LanIcon htmlColor="#777" />
                                    </div>
                                  </Tooltip>
                                )}
                              </div>
                            </StyledItemCategory>
                          )}
                        </List>
                      </div>
                      {showInfo && (
                        <div
                          style={{
                            paddingTop: 12,
                            boxSizing: 'border-box',
                          }}
                        >
                          <div>Nº de categorias: {item.categories.length}</div>

                          <div>
                            Nº de atletas:{' '}
                            {countNumberAthletes(item.categories)}
                          </div>

                          <div>
                            Nº de lutas: {countNumberFight(item.categories)}
                          </div>
                          <div>
                            Horário inicial: {getFistDate(item.categories)}
                          </div>
                          <div>
                            Tempo total: {getTimeTotal(item.categories)}
                          </div>
                          <div>
                            Estimativa final: {getFinalEta(item.categories)}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AreasCategory;

/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { Box, Tab, Tabs } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from '../../hooks/useDebounce';
import AreasCategory from '../ShowAreas/AreasCategory';
import { StyledCustomTabPanel, StyledRoot } from './styles';
import DrawerDetailsBracket from '../DrawerDetailsBracket';
import AreasFightsV2 from '../ShowAreas/AreasFightsV2';
import Filters from './Filters';

interface ListAreasV2Props {
  areas: any[];
  event: any;
  showInfo?: boolean;
  reasonDesclassified?: any[];
  filtersData: any;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <StyledCustomTabPanel
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </StyledCustomTabPanel>
  );
}

const ListAreasV2: React.FC<ListAreasV2Props> = ({
  areas,
  event,
  showInfo = true,
  reasonDesclassified,
  filtersData,
}): ReactElement => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [tabValue, setTabValue] = React.useState(1);

  const [daySelected, setDaySelected] = useState('0');

  const [search, setSearch] = useState(searchParams.get('search') || '');

  const searchDebounce = useDebounce(search, 500);

  const [drawerDetails, setDrawerDetails] = useState({
    open: false,
    category: '',
    area: '',
  });

  const [filters, setFilters] = useState();

  useEffect(() => {
    if (searchDebounce) {
      setSearchParams({ search: searchDebounce });
    } else {
      searchParams.delete('search');
      setSearchParams(searchParams);
    }
  }, [searchDebounce, searchParams, setSearchParams]);

  const days = useMemo(() => {
    const itemDays = areas.map((item) => item.day);

    const result = [...new Set(itemDays)];

    return result.sort();
  }, [areas]);

  const handleClick = (area: any, category: any) => {
    setDrawerDetails({ open: true, category: category.uuid, area: area.uuid });
  };

  const handleChangeTabValue = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabValue(newValue);
  };

  const handleRedirectArea = () => {
    window.open(
      `/#/events/${event.keyEvent}/brackets/${drawerDetails.category}`,
      '_self'
    );
  };

  return (
    <>
      <StyledRoot>
        <div style={{ textAlign: 'center', fontSize: 18, paddingBottom: 16 }}>
          Áreas
        </div>

        <Box
          sx={{
            borderColor: 'divider',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 12,
            // marginBottom: 3,
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleChangeTabValue}
            style={{ display: 'flex' }}
          >
            <Tab
              style={{
                width: 200,
                background: tabValue === 0 ? '#083061' : '#ddd',
                color: tabValue === 0 ? '#fff' : undefined,
                marginRight: 0,
                flex: 1,
              }}
              label="Categorias"
              {...a11yProps(0)}
            />
            <Tab
              style={{
                width: 200,
                background: tabValue === 1 ? '#083061' : '#ddd',
                color: tabValue === 1 ? '#fff' : undefined,
                marginLeft: 0,
                flex: 1,
              }}
              label="Lutas"
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>

        {/* <div
          style={{ display: 'flex', paddingTop: 8, boxSizing: 'border-box' }}
        >
          <StatusFilter
            defaultValue={{
              canceled: true,
              completed: true,
              in_progress: true,
              oneAthlete: false,
              pending: true,
              programed: true,
            }}
            onChange={console.log}
            inProgress
          />
        </div> */}

        <div style={{ display: 'flex', gap: 16 }}>
          <div
            style={{ width: 360, padding: '24px 0px', boxSizing: 'border-box' }}
          >
            <Filters
              areas={areas}
              filtersData={filtersData}
              handleSubmit={setFilters}
              hasFiltersApplied
            />
          </div>

          <div style={{ flex: 1 }}>
            <CustomTabPanel value={tabValue} index={0}>
              <AreasCategory
                filters={{}}
                numberDays={event?.numberDaysFight}
                eventAreas={areas}
                daySelected={daySelected}
                search={searchDebounce}
                redirect={handleClick}
                showInfo={showInfo}
                hasFiltersApplied={Boolean(search)}
              />
            </CustomTabPanel>

            <CustomTabPanel value={tabValue} index={1}>
              <AreasFightsV2
                filters={filters}
                numberDays={event?.numberDaysFight}
                eventAreas={areas}
                redirect={(area, category) => handleClick(area, category)}
                daySelected={daySelected}
                reasonDesclassified={reasonDesclassified}
                search={searchDebounce}
                hasFiltersApplied={Boolean(search)}
              />
            </CustomTabPanel>
          </div>
        </div>
      </StyledRoot>

      <DrawerDetailsBracket
        {...drawerDetails}
        handleViewBracket={handleRedirectArea}
        handleClose={() =>
          setDrawerDetails((prev) => ({ ...prev, open: false }))
        }
      />
    </>
  );
};

export default ListAreasV2;

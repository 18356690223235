import React, { ReactElement, useEffect, useState } from 'react';

import { IconButton, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useDebounce } from '../../../../hooks/useDebounce';
import { removeMask } from '../../../../utils/mask';
import { maskPhone } from '../../../../utils/phone';

interface InputSearchProps {
  onChange: (value: string) => void;
}

const InputSearch: React.FC<InputSearchProps> = ({
  onChange,
}): ReactElement => {
  const [search, setSearch] = useState('');

  const searchDebounce = useDebounce(search, 500);

  const isNumeric = (str: string): boolean => {
    return /^\d+$/.test(str);
  };

  useEffect(() => {
    const value = removeMask(searchDebounce);

    if (isNumeric(value)) {
      onChange(value);
    } else {
      onChange(searchDebounce);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDebounce]);

  const handleClickSearch = () => {
    if (search) {
      setSearch('');
    }
  };

  const handleChange = (event: any) => {
    const value = removeMask(event.target.value);

    if (isNumeric(value)) {
      setSearch(maskPhone(value));
    } else {
      setSearch(event.target.value);
    }
  };

  return (
    <TextField
      variant="outlined"
      placeholder="Nome ou celular"
      style={{ width: 420 }}
      InputLabelProps={{
        style: {
          fontSize: 16,
          textTransform: 'uppercase',
          background: '#fff',
          paddingRight: 5,
          color: '#000',
        },
        shrink: true,
      }}
      InputProps={{
        endAdornment: (
          <IconButton onClick={handleClickSearch} edge="end">
            {search ? <CloseIcon /> : <SearchIcon />}
          </IconButton>
        ),
      }}
      value={search}
      onChange={handleChange}
    />
  );
};

export default InputSearch;

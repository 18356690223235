import { Box } from '@mui/material';
import styled from 'styled-components';

export const StyleBox = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  maxWidth: '90%',
  boxShadow: '24px',
  outline: 'none',
  background: '#fff',
  borderRadius: 8,
  padding: 20,
  boxSizing: 'border-box',
  color: '#777',
  maxHeight: '98vh',
  display: 'flex',
  flexDirection: 'column',
});

export const StyleContent = styled('div')({
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

export const StyleContentButton = styled('div')({
  width: 300,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 16,
  marginTop: 16,
});

export const StyledContentCause = styled('div')({
  padding: '10px',
  marginTop: 20,
  borderRadius: 8,
  gap: 10,
  display: 'flex',
  flexDirection: 'column',
  background: '#eee',
});

export const StyledContentInput = styled('div')({
  marginTop: 20,
});

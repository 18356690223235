import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import {
  StyleBox,
  StyleContent,
  StyleContentButton,
  StyledContentAthleteData,
} from './styles';
import {
  initialScoreboardOfflineValues,
  ScoreboardOfflineProps,
  validationSchema,
} from './schema';

interface Props {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (values: ScoreboardOfflineProps) => void;
}

export default function ModalScoreboardOffline({
  open,
  handleClose,
  handleSubmit,
}: Props): React.ReactElement {
  const formik = useFormik({
    initialValues: initialScoreboardOfflineValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const handleChangeTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;

    value = value.replace(/[^\d:]/g, '');

    if (value.length === 2 && !value.includes(':')) {
      value += ':';
    }

    if (value.length > 5) {
      return;
    }

    const match = value.match(/^(\d{0,2}):?(\d{0,2})?$/);
    if (match) {
      const minutes = match[1] || '';
      const seconds = match[2] || '';

      if (parseInt(minutes, 10) > 59) return;
      if (seconds && parseInt(seconds, 10) > 59) return;

      formik.setFieldValue('time', value);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <StyleBox>
          <Typography variant="h6" component="h2">
            Iniciar placar offline
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <StyleContent>
              <div>Atleta Um</div>

              <StyledContentAthleteData>
                <TextField
                  fullWidth
                  id="athleteOneName"
                  variant="outlined"
                  name="athleteOneName"
                  label="Nome"
                  required
                  InputLabelProps={{
                    style: {
                      fontSize: 16,
                      textTransform: 'uppercase',
                      background: '#fff',
                      paddingRight: 5,
                      color: '#000',
                    },
                    shrink: true,
                  }}
                  value={formik.values.athleteOneName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.athleteOneName &&
                    Boolean(formik.errors.athleteOneName)
                  }
                  helperText={
                    formik.touched.athleteOneName &&
                    formik.errors.athleteOneName
                  }
                />
                <TextField
                  fullWidth
                  id="athleteOneTeam"
                  variant="outlined"
                  name="athleteOneTeam"
                  label="Equipe"
                  InputLabelProps={{
                    style: {
                      fontSize: 16,
                      textTransform: 'uppercase',
                      background: '#fff',
                      paddingRight: 5,
                      color: '#000',
                    },
                    shrink: true,
                  }}
                  value={formik.values.athleteOneTeam}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.athleteOneTeam &&
                    Boolean(formik.errors.athleteOneTeam)
                  }
                  helperText={
                    formik.touched.athleteOneTeam &&
                    formik.errors.athleteOneTeam
                  }
                />
              </StyledContentAthleteData>

              <div>Atleta Dois</div>

              <StyledContentAthleteData>
                <TextField
                  fullWidth
                  id="athleteTwoName"
                  variant="outlined"
                  name="athleteTwoName"
                  label="Nome"
                  required
                  InputLabelProps={{
                    style: {
                      fontSize: 16,
                      textTransform: 'uppercase',
                      background: '#fff',
                      paddingRight: 5,
                      color: '#000',
                    },
                    shrink: true,
                  }}
                  value={formik.values.athleteTwoName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.athleteTwoName &&
                    Boolean(formik.errors.athleteTwoName)
                  }
                  helperText={
                    formik.touched.athleteTwoName &&
                    formik.errors.athleteTwoName
                  }
                />
                <TextField
                  fullWidth
                  id="athleteTwoTeam"
                  variant="outlined"
                  name="athleteTwoTeam"
                  label="Equipe"
                  InputLabelProps={{
                    style: {
                      fontSize: 16,
                      textTransform: 'uppercase',
                      background: '#fff',
                      paddingRight: 5,
                      color: '#000',
                    },
                    shrink: true,
                  }}
                  value={formik.values.athleteTwoTeam}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.athleteTwoTeam &&
                    Boolean(formik.errors.athleteTwoTeam)
                  }
                  helperText={
                    formik.touched.athleteTwoTeam &&
                    formik.errors.athleteTwoTeam
                  }
                />
              </StyledContentAthleteData>

              <div>Configuração</div>

              <StyledContentAthleteData>
                <TextField
                  fullWidth
                  id="time"
                  variant="outlined"
                  name="time"
                  required
                  label="Tempo da luta"
                  InputLabelProps={{
                    style: {
                      fontSize: 16,
                      textTransform: 'uppercase',
                      background: '#fff',
                      paddingRight: 5,
                      color: '#000',
                    },
                    shrink: true,
                  }}
                  value={formik.values.time}
                  onChange={handleChangeTime}
                  onBlur={formik.handleBlur}
                  error={formik.touched.time && Boolean(formik.errors.time)}
                  helperText={formik.touched.time && formik.errors.time}
                />

                <TextField
                  fullWidth
                  id="category"
                  variant="outlined"
                  name="category"
                  label="Categoria"
                  InputLabelProps={{
                    style: {
                      fontSize: 16,
                      textTransform: 'uppercase',
                      background: '#fff',
                      paddingRight: 5,
                      color: '#000',
                    },
                    shrink: true,
                  }}
                  value={formik.values.category}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.category && Boolean(formik.errors.category)
                  }
                  helperText={formik.touched.category && formik.errors.category}
                />
              </StyledContentAthleteData>
            </StyleContent>
            <StyleContentButton>
              <Button
                variant="outlined"
                onClick={() => handleClose()}
                disableRipple
                style={{
                  letterSpacing: 1,
                  fontSize: 14,
                  width: 150,
                }}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                disableRipple
                style={{
                  letterSpacing: 1,
                  fontSize: 14,
                  width: 150,
                }}
              >
                Iniciar
              </Button>
            </StyleContentButton>
          </form>
        </StyleBox>
      </Fade>
    </Modal>
  );
}

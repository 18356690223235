import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledRoot } from './styles';

import {
  getPublicBracket,
  getReasonDesclassifiedRequest,
} from '../../../api/events';

import DetailsInfo from '../../../components/DetailsInfo';

import Stages from './Stages';

interface Props {
  hiddenHeader?: boolean;
  handleClickFight?: (fight: any) => void;
  refreshRequest?: boolean;
}

const EventAreasBrackets: React.FC<Props> = ({
  hiddenHeader,
  handleClickFight,
  refreshRequest,
}): ReactElement => {
  const { eventId, categoryId } = useParams();

  const navigate = useNavigate();

  const [event, setEvent] = useState<any>(null);
  const [category, setCategory] = useState<any>(null);

  const [eventAreasCategory, setEventAreasCategory] = useState<any>([]);

  const [isLoading, setLoading] = useState(true);

  const getBracketData = useCallback(async () => {
    try {
      setLoading(true);
      if (eventId && categoryId) {
        const data = await getPublicBracket(eventId, categoryId);

        setEvent(data?.event);

        setCategory(data?.category);

        setEventAreasCategory(data?.fights || []);
      }
    } catch (error) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }, [categoryId, eventId]);

  useEffect(() => {
    getBracketData();
  }, [getBracketData]);

  useEffect(() => {
    if (refreshRequest) {
      getBracketData();
    }
  }, [getBracketData, refreshRequest]);

  const [reasonDesclassified, setReasonDesclassified] = useState<any>([]);

  const getReasonDesclassified = useCallback(async () => {
    try {
      const data = await getReasonDesclassifiedRequest();

      setReasonDesclassified(data?.data || []);
    } catch (error) {
      // console.log('error');
    }
  }, []);

  useEffect(() => {
    getReasonDesclassified();
  }, [getReasonDesclassified]);

  const widthPage = useMemo(() => {
    if (eventAreasCategory?.length <= 4) {
      return 900;
    }

    if (eventAreasCategory?.length <= 7) {
      return 1350;
    }
    return undefined;
  }, [eventAreasCategory]);

  return (
    <StyledRoot style={{ width: widthPage }}>
      {/* <div style={{ width: '100%' }}> */}

      {!hiddenHeader && (
        <DetailsInfo
          isLoading={isLoading}
          sections={[
            {
              rows: [
                {
                  key: 'name',
                  label: 'Nome',
                },
                {
                  key: 'categoryName',
                  label: 'Categoria',
                },
                {
                  key: 'timeCategory',
                  label: 'Tempo categoria',
                  transform: (text) => `${text} min`,
                },
              ],
              data: {
                name: event?.name,
                categoryName: category?.categoryName,
                timeCategory: category?.timeCategory,
              },
              title: 'Evento',
              onBack: () => navigate(-1),
            },
          ]}
        />
      )}

      {hiddenHeader && category && (
        <div>
          {/* <div>{category?.eventArea?.name}</div> */}
          <div>
            {category?.categoryName} ({category?.timeCategory} min)
          </div>
        </div>
      )}

      <Stages
        stages={eventAreasCategory}
        reasonDesclassified={reasonDesclassified}
        handleClickFight={handleClickFight}
      />
    </StyledRoot>
  );
};

export default EventAreasBrackets;

import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { StyledRoot } from './styles';
import useAuthenticate from '../../../recoil/hooks/authenticate';

import { getEventCategories } from '../../../api/events';

import Card from '../../../components/Card';
import DetailsInfo from '../../../components/DetailsInfo';

import EventAreasBrackets from '../../public/EventAreasBrackets';
import useLoading from '../../../recoil/hooks/loading';
import ModalDetails from './ModalDetails';
import ModalFinishFight from '../Scoreboard/ModalFinishFight';
import {
  getFightById,
  getReasonVictoryRequest,
  resetResultRequest,
  updateResultRequest,
} from '../../../api/scoreboard';
import ConfirmModal from '../../../components/ConfirmModal';
import { handleExportCategoryToPdf } from './utils';

const CategoryBrackets: React.FC = (): ReactElement => {
  const componentToExportRef = useRef<HTMLDivElement>(null);

  const [modalDetails, setModalDetails] = useState({
    open: false,
    fight: null,
  });

  const [modalFinish, setModalFinish] = useState({
    open: false,
    athletes: [],
    fightId: '',
    defaultWinner: '',
    defaultReason: '',
  });

  const [modalConfirm, setModalConfirm] = useState<{
    open: boolean;
    title: string;
    confirmLabel?: string;
    message: string;
    onConfirm: () => void;
    onCancel?: () => void;
  }>({
    open: false,
    title: 'Atenção',
    message: 'Deseja inverter a ordem dos atletas?',
    onConfirm: () => null,
  });

  const { setLoadingModal } = useLoading();

  const { authenticate } = useAuthenticate();

  const [loadingFinish, setLoadingFinish] = useState(false);

  const [refreshRequest, setRefreshRequest] = useState(false);

  const [loadingDetails, setLoadingDetails] = useState(false);

  const { eventId, areaId, categoryId } = useParams();

  const [event, setEvent] = useState<any>(null);

  const [isLoading, setLoading] = useState(true);

  const [reasonVictory, setReasonVictory] = useState<any[]>([]);

  const getReasonVictory = useCallback(async () => {
    try {
      if (authenticate.token) {
        const data = await getReasonVictoryRequest(authenticate.token);

        setReasonVictory(data?.data || null);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticate.token]);

  useEffect(() => {
    getReasonVictory();
  }, [getReasonVictory]);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      if (authenticate.token && eventId) {
        const data = await getEventCategories(authenticate.token, eventId);

        setEvent(data?.event || null);
      }
    } catch (error) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }, [authenticate.token, eventId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleClickFight = (fight: any) => {
    if (fight.status === 'completed') {
      setModalDetails({ ...modalDetails, open: true, fight });
    }
  };

  const handleOpenModalFinish = async (fightId: string): Promise<void> => {
    setLoadingDetails(true);

    if (eventId && areaId && fightId) {
      const { fight } = await getFightById(
        authenticate.token,
        eventId,
        areaId,
        fightId
      );

      const athlete = fight?.athletes?.find((item: any) => item.result?.win);

      setModalDetails({ ...modalDetails, open: false });

      setModalFinish({
        open: true,
        athletes: fight.athletes || [],
        defaultWinner: athlete?.uuid || '',
        fightId: fight.uuid,
        defaultReason: athlete?.result?.typeVictory || '',
      });
    }

    setLoadingDetails(false);
  };

  const handleResetFight = async (fightId: string): Promise<void> => {
    setLoadingModal(true);

    try {
      await resetResultRequest(authenticate.token, fightId);

      toast('Alteração realizada com sucesso!', { type: 'success' });

      setRefreshRequest(true);
      setTimeout(() => {
        setRefreshRequest(false);
      }, 200);
    } catch {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    } finally {
      setLoadingModal(false);
    }
  };

  const handleResetFightConfirm = async (fightId: string): Promise<void> => {
    if (eventId && areaId && fightId) {
      setModalDetails({ ...modalDetails, open: false });

      setModalConfirm({
        open: true,
        message:
          'Essa ação retornará a luta para o status "Aguardando luta" e removerá os resultados existentes. Após a confirmação, essa ação não poderá ser revertida. Deseja continuar?',
        confirmLabel: 'Confirmar',
        onConfirm: () => {
          setModalConfirm((prev) => ({ ...prev, open: false }));
          handleResetFight(fightId);
        },
        onCancel: () => {
          setModalConfirm((prev) => ({ ...prev, open: false }));
        },
        title: 'Resetar resultado da luta',
      });
    }
  };

  const handleSubmitFinish = async (values: any) => {
    try {
      setLoadingFinish(true);
      if (authenticate.token && modalFinish.fightId) {
        const reason = reasonVictory.find(
          (item) => item.id === Number(values.reason)
        );

        const data = {
          ...values,
          reasonDescription: reason.description,
          timeFight: 0,
        };

        await updateResultRequest(
          authenticate.token,
          modalFinish.fightId,
          data
        );

        toast('Atleta avançado com sucesso!', { type: 'success' });
        setModalFinish({ ...modalFinish, open: false });

        setRefreshRequest(true);

        setTimeout(() => {
          setRefreshRequest(false);
        }, 200);
      }
    } catch (erro) {
      // eslint-disable-next-line no-console
      console.log('erro', erro);
    } finally {
      setLoadingFinish(false);
    }
  };

  const handleExportPdf = async () => {
    setLoadingModal(true);

    await handleExportCategoryToPdf(eventId, categoryId);

    setLoadingModal(false);
  };

  return (
    <React.Fragment>
      <StyledRoot>
        <Card>
          <div style={{ width: '100%' }}>
            <DetailsInfo
              isLoading={isLoading}
              actions={[
                {
                  icon: <PictureAsPdfIcon color="primary" />,
                  onClick: () => handleExportPdf(),
                  tooltip: 'Download PDF',
                },
              ]}
              sections={[
                {
                  rows: [
                    {
                      key: 'name',
                      label: 'Nome',
                    },
                  ],
                  data: event,
                  title: 'Evento',
                },
              ]}
            />

            <div
              ref={componentToExportRef}
              style={{
                // display: 'flex',
                minWidth: 900,
                width: 'fit-content',
                maxWidth: '100%',
              }}
            >
              <EventAreasBrackets
                hiddenHeader
                handleClickFight={handleClickFight}
                refreshRequest={refreshRequest}
              />
            </div>
          </div>
        </Card>
      </StyledRoot>

      {/* <TournamentPDF /> */}

      <ModalDetails
        open={modalDetails.open}
        fight={modalDetails.fight}
        handleClose={() => setModalDetails({ ...modalDetails, open: false })}
        handleChangeWinner={handleOpenModalFinish}
        handleResetFight={handleResetFightConfirm}
        isLoading={loadingDetails}
      />

      <ModalFinishFight
        open={modalFinish.open}
        title="Alterar resultado"
        defaultWinner={modalFinish.defaultWinner}
        handleBack={() => setModalFinish({ ...modalFinish, open: false })}
        reasonVictory={reasonVictory}
        backButtonText="Cancelar"
        hiddenWithoutWinner
        isLoading={loadingFinish}
        handleSubmit={handleSubmitFinish}
        athletes={modalFinish?.athletes?.sort(
          (a: any, b: any) => a.order - b.order
        )}
        defaultReason={modalFinish.defaultReason?.toString()}
      />

      <ConfirmModal
        open={modalConfirm.open}
        handleClose={() => {
          setModalConfirm((prev) => {
            return {
              ...prev,
              open: false,
            };
          });
        }}
        handleCancel={modalConfirm.onCancel}
        handleConfirm={modalConfirm.onConfirm}
        title={modalConfirm.title}
        message={modalConfirm.message}
        confirmLabel={modalConfirm.confirmLabel}
        confirmColor="#07bc0c"
      />
    </React.Fragment>
  );
};

export default CategoryBrackets;

import React, { ReactElement, useEffect, useState } from 'react';

import { Avatar } from '@mui/material';
import {
  StyledContainerScore,
  StyledContentAvatar,
  StyledContentName,
  StyledContentNumber,
  StyledContentNumberPoints,
  StyledContentScore,
  StyledContentScorePoints,
  StyledContentTime,
  StyledFightOne,
  StyledLeft,
  StyledName,
  StyledReasonVictory,
  StyledRight,
  StyledRoot,
  StyledTeam,
} from './styles';
import useAuthenticate from '../../../recoil/hooks/authenticate';
import useEvent from '../../../recoil/hooks/event';
import Presentation from './Presentation';
import { mapStage } from '../../../utils/fight';

const defaultScore = {
  athleteOne: {
    id: '',
    points: 0,
    advantages: 0,
    faults: 0,
  },
  athleteTwo: {
    id: '',
    points: 0,
    advantages: 0,
    faults: 0,
  },
};

const AthleteItem: React.FC<{
  athlete: any;
  scoreAthlete: any;
  ligth?: boolean;
}> = ({ athlete, scoreAthlete, ligth = false }) => {
  return (
    <StyledFightOne ligth={ligth}>
      <StyledLeft>
        <StyledContentAvatar>
          <Avatar
            alt={athlete?.nameAthlete}
            src={athlete?.photo}
            sx={{
              width: '9vw',
              height: '9vw',
            }}
          />
        </StyledContentAvatar>
        <StyledContentName>
          <StyledName>{athlete?.nameAthlete}</StyledName>
        </StyledContentName>
      </StyledLeft>
      <StyledRight>
        <StyledContentScorePoints>
          <StyledContentNumberPoints>
            {scoreAthlete?.points || '0'}
          </StyledContentNumberPoints>
        </StyledContentScorePoints>

        <StyledContainerScore>
          <StyledContentScore>
            <StyledContentNumber color="#f1c40f">
              {scoreAthlete?.advantages || '0'}
            </StyledContentNumber>
          </StyledContentScore>

          <StyledContentScore>
            <StyledContentNumber color="#b71c1c">
              {scoreAthlete?.faults || '0'}
            </StyledContentNumber>
          </StyledContentScore>
        </StyledContainerScore>
      </StyledRight>
    </StyledFightOne>
  );
};

const ScoreboardShare: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();

  const { event } = useEvent();

  const [time, setTime] = useState(0);
  const [scoreDetails, setScoreDetails] = useState<any>(null);
  const [boardId, setBoardId] = useState('');
  const [withoutWinner, setWithoutWinner] = useState('');
  const [reason, setReason] = useState('');
  const [winner, setWinner] = useState<any>(null);
  const [score, setScore] = useState(defaultScore);

  useEffect(() => {
    const updateTime = () => {
      const boardId = localStorage.getItem(`@ilutas:board`);
      const newTime = localStorage.getItem(`@ilutas:${boardId}-time`);
      const score = localStorage.getItem(`@ilutas:${boardId}-score`);
      const winner = localStorage.getItem(`@ilutas:${boardId}-winner`);
      const reasonVictory = localStorage.getItem(`@ilutas:${boardId}-reason`);

      const withoutWinner = localStorage.getItem(
        `@ilutas:${boardId}-withoutWinner`
      );
      const scoreDetails = localStorage.getItem(
        `@ilutas:${boardId}-scoreDetails`
      );

      setWithoutWinner(withoutWinner || '');

      setWinner(winner ? JSON.parse(winner) : null);
      setTime(Number(newTime));
      setScore(score ? JSON.parse(score) : defaultScore);
      setBoardId(boardId || '');
      setReason(reasonVictory || '');
      setScoreDetails(scoreDetails ? JSON.parse(scoreDetails) : null);
    };

    updateTime();

    const interval = setInterval(() => {
      updateTime();
    }, 900);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(
      remainingSeconds
    ).padStart(2, '0')}`;
  };

  const getAthlete = (athletes: any[], order: number): any => {
    return athletes?.find((item) => item.order === order);
  };

  if (!boardId) {
    return (
      <div
        style={{
          background: '#000',
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 32,
        }}
      >
        <div
          style={{
            fontSize: 64,
            fontWeight: '600',
            color: '#fff',
            maxWidth: '90%',
            textAlign: 'center',
          }}
        >
          {event?.name}
        </div>
        <img
          src={authenticate?.user?.imageEvent || '/images/ilutas.png'}
          style={{
            maxWidth: '50%',
            maxHeight: '50%',
            borderRadius: '50%',
            border: '2px solid #fff',
          }}
        />
      </div>
    );
  }

  if (winner) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: winner.order === 0 ? '#2B4755' : '#fff',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 24,
          }}
        >
          <Avatar
            alt={winner?.nameAthlete}
            src={winner?.photo}
            sx={{ width: 300, height: 300 }}
          />
          <div
            style={{
              display: 'flex',
              fontSize: 64,
              fontWeight: 600,
              color: winner.order === 0 ? '#fff' : '#2B4755',
            }}
          >
            {scoreDetails?.eventAreaCategory?.gender === 'F'
              ? 'Ganhadora'
              : 'Ganhador'}{' '}
            🏆
          </div>
          <div
            style={{
              padding: '0px 24px 24px 24px',
              boxSizing: 'border-box',
              color: winner.order === 0 ? '#fff' : '#2B4755',
            }}
          >
            {Boolean(reason) && (
              <StyledReasonVictory>{reason}</StyledReasonVictory>
            )}
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                gap: 24,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <StyledContentName>
                <StyledName>{winner?.nameAthlete}</StyledName>
                <StyledTeam>{winner?.gym}</StyledTeam>
              </StyledContentName>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (withoutWinner) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: '#2B4755',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 24,
          }}
        >
          <div
            style={{
              display: 'flex',
              fontSize: 64,
              fontWeight: 600,
              color: '#fff',
            }}
          >
            Resultado
          </div>
          <div
            style={{
              padding: 24,
              boxSizing: 'border-box',
              color: '#fff',
            }}
          >
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                gap: 24,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <StyledContentName>
                <StyledName>Não houve ganhador</StyledName>
              </StyledContentName>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (scoreDetails?.type === 'presentation') {
    return <Presentation scoreDetails={scoreDetails} />;
  }

  return (
    <React.Fragment>
      <StyledRoot>
        <AthleteItem
          athlete={getAthlete(scoreDetails?.athletes, 0)}
          scoreAthlete={score?.athleteOne}
        />

        <AthleteItem
          athlete={getAthlete(scoreDetails?.athletes, 1)}
          scoreAthlete={score?.athleteTwo}
          ligth
        />

        <StyledContentTime>
          <div
            style={{
              flex: 1,
              padding: 12,
              display: 'flex',
              flexDirection: 'column',
              gap: 24,
              width: '50%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                fontSize: '16vh',
                fontWeight: 600,
                color: '#FFF',
              }}
            >
              {formatTime(time)}
            </div>
          </div>
          <div
            style={{
              width: '50%',
              flex: 1,
              color: '#FFF',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 24,
            }}
          >
            <div style={{ fontSize: '8vh' }}>
              {mapStage(scoreDetails?.stage || '')}
            </div>
            <div
              style={{
                fontSize: '4vh',
                width: '100%',
                maxWidth: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {scoreDetails?.eventAreaCategory?.categoryName || ''}
            </div>
          </div>
        </StyledContentTime>
      </StyledRoot>
    </React.Fragment>
  );
};

export default ScoreboardShare;

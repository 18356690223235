/* eslint-disable no-plusplus */
import React, { ReactElement } from 'react';
import { IRoundProps } from 'react-brackets';
import WiningBracket from './wining-bracket';
import { StyledRoot } from './styles';

interface Props {
  stages: any[];
  reasonDesclassified: any[];
  handleClickFight?: (fight: any) => void;
}

const StagesPdf: React.FC<Props> = ({
  stages,
  reasonDesclassified,
  handleClickFight,
}): ReactElement => {
  const isWinner = (athlete: any): boolean => {
    return athlete?.result?.win;
  };

  const formatTagText = (prefix: string, order: number): string => {
    return `${prefix}-${(order + 1).toString().padStart(2, '0')}`;
  };

  const prepareData = (data: any): IRoundProps[] => {
    const fights: IRoundProps[] = [];

    const knockout = data.filter((item: any) => item.stage === 'knockout');

    const eighth = data.filter((item: any) => item.stage === 'eighth-final');

    const quarter = data.filter((item: any) => item.stage === 'quarter-final');

    const semifinal = data.filter((item: any) => item.stage === 'semifinal');

    const final = data.find((item: any) => item.stage === 'final');

    const bronze = data.find((item: any) => item.stage === 'bronze');

    if (knockout.length) {
      fights.push({
        title: 'Eliminatórias',
        seeds: knockout.map((item: any) => {
          return {
            id: item.uuid,
            fightId: item.id,
            date: item.date,
            stage: item.stage,
            finishAt: item.finishAt,
            status: item.status,
            area: item?.eventAreaCategory?.eventArea?.name,
            bye_match: item.athletes.length < 2,
            tag: formatTagText('EL', item.order),
            teams: item.athletes.map((athlete: any) => {
              return {
                id: athlete.uuid,
                order: athlete.order,
                team: athlete.gym,
                image: athlete.photo,
                reasonDesclassificationCode:
                  athlete.reasonDesclassificationCode,
                name: athlete.nameAthlete,
                result: athlete.result,
                win: isWinner(athlete),

                qrcode: athlete.qrcode,
              };
            }),
          };
        }),
      });
    }

    if (eighth.length) {
      fights.push({
        title: 'Oitavas de final',
        seeds: eighth.map((item: any) => {
          return {
            id: item.uuid,
            fightId: item.id,
            date: item.date,
            stage: item.stage,
            finishAt: item.finishAt,
            status: item.status,
            area: item?.eventAreaCategory?.eventArea?.name,
            bye_match: item.athletes.length < 2,
            tag: formatTagText('OF', item.order),
            teams: item.athletes.map((athlete: any) => {
              return {
                id: athlete.uuid,
                order: athlete.order,
                team: athlete.gym,
                image: athlete.photo,
                reasonDesclassificationCode:
                  athlete.reasonDesclassificationCode,
                name: athlete.nameAthlete,
                result: athlete.result,
                win: isWinner(athlete),

                qrcode: knockout?.length ? undefined : athlete.qrcode,
              };
            }),
          };
        }),
      });
    }

    if (quarter.length) {
      fights.push({
        title: 'Quartas de final',
        seeds: quarter.map((item: any) => {
          return {
            id: item.uuid,
            fightId: item.id,
            date: item.date,
            stage: item.stage,
            finishAt: item.finishAt,
            status: item.status,
            area: item?.eventAreaCategory?.eventArea?.name,
            bye_match: !eighth.length && item.athletes.length < 2,
            tag: formatTagText('QF', item.order),
            teams: item.athletes.map((athlete: any) => {
              return {
                id: athlete.uuid,
                order: athlete.order,
                team: athlete.gym,
                image: athlete.photo,
                reasonDesclassificationCode:
                  athlete.reasonDesclassificationCode,
                name: athlete.nameAthlete,
                result: athlete.result,
                qrcode: eighth?.length ? undefined : athlete.qrcode,
                win: isWinner(athlete),
              };
            }),
          };
        }),
      });
    }

    if (semifinal.length) {
      fights.push({
        title: 'Semifinal',
        seeds: semifinal.map((item: any) => {
          return {
            id: item.uuid,
            fightId: item.id,
            tag: formatTagText('SF', item.order),
            date: item.date,
            stage: item.stage,
            finishAt: item.finishAt,
            status: item.status,
            area: item?.eventAreaCategory?.eventArea?.name,
            teams: item.athletes.map((athlete: any) => {
              return {
                id: athlete.uuid,
                order: athlete.order,
                team: athlete.gym,
                image: athlete.photo,
                reasonDesclassificationCode:
                  athlete.reasonDesclassificationCode,
                name: athlete.nameAthlete,
                result: athlete.result,
                qrcode: quarter?.length ? undefined : athlete.qrcode,
                win: isWinner(athlete),
              };
            }),
          };
        }),
      });
    }

    if (final) {
      fights.push({
        title: 'Final',
        seeds: [
          {
            id: final.uuid,
            fightId: final.id,
            tag: 'Final',
            date: final.date,
            stage: final.stage,
            finishAt: final.finishAt,
            status: final.status,
            area: final?.eventAreaCategory?.eventArea?.name,
            teams: final.athletes.map((athlete: any) => {
              return {
                id: athlete.uuid,
                order: athlete.order,
                team: athlete.gym,
                image: athlete.photo,
                reasonDesclassificationCode:
                  athlete.reasonDesclassificationCode,
                name: athlete.nameAthlete,
                result: athlete.result,
                qrcode: semifinal?.length ? undefined : athlete.qrcode,
                win: isWinner(athlete),
              };
            }),
          },
        ],
      });
    }

    if (bronze) {
      fights.push({
        title: 'Bronze',
        seeds: [
          {
            id: bronze.uuid,
            fightId: bronze.id,
            tag: 'Bronze',
            stage: bronze.stage,
            finishAt: bronze.finishAt,
            status: bronze.status,
            date: bronze.date,
            area: bronze?.eventAreaCategory?.eventArea?.name,
            teams: bronze.athletes.map((athlete: any) => {
              return {
                id: athlete.uuid,
                order: athlete.order,
                team: athlete.gym,
                image: athlete.photo,
                reasonDesclassificationCode:
                  athlete.reasonDesclassificationCode,
                name: athlete.nameAthlete,
                result: athlete.result,
                win: isWinner(athlete),
              };
            }),
          },
        ],
      });
    }

    return fights;
  };
  return (
    <StyledRoot>
      <WiningBracket
        rounds={prepareData(stages)}
        reasonDesclassified={reasonDesclassified}
        handleClickFight={handleClickFight}
      />
    </StyledRoot>
  );
};

export default StagesPdf;

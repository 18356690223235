import * as yup from 'yup';

export const initialScoreboardOfflineValues = {
  athleteOneName: '',
  athleteOneTeam: '',
  athleteTwoName: '',
  athleteTwoTeam: '',
  time: '03:00',
  category: '',
};

export interface ScoreboardOfflineProps {
  athleteOneName: string;
  athleteOneTeam: string;
  athleteTwoName: string;
  athleteTwoTeam: string;
  time: string;
  category: string;
}

const timeRegExp = /^(?:[0-5]?[0-9]):[0-5][0-9]$/;

export const validationSchema = yup.object({
  athleteOneName: yup.string().required('Campo obrigatório'),
  athleteOneTeam: yup.string(),
  athleteTwoName: yup.string().required('Campo obrigatório'),
  athleteTwoTeam: yup.string(),
  time: yup
    .string()
    .required('Campo obrigatório')
    .matches(timeRegExp, 'Valor inválido'),

  category: yup.string(),
});

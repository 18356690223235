import React from 'react';
import { useFormik } from 'formik';
import { Button, CircularProgress, TextField } from '@mui/material';

import { StyledForm, StyledLogo, StyledRoot, StyledContent } from './styles';
import {
  VerifyTokenFormProps,
  initialVerifyTokenFormValues,
  validationSchema,
} from './schema';
import { tokenMask } from '../../utils/token';

interface Props {
  isLoading: boolean;
  phone: string;
  onSubmit: (values: VerifyTokenFormProps) => void;
}

const VerifyTokenForm: React.FC<Props> = ({
  isLoading,
  phone,
  onSubmit,
}): React.ReactElement => {
  const formik = useFormik({
    initialValues: initialVerifyTokenFormValues,
    validationSchema,
    validateOnBlur: false,
    onSubmit,
  });

  const handleChangeToken = (key: string, event: any): void => {
    const value = tokenMask(event.target.value);

    formik.setFieldValue(key, value);
  };

  return (
    <StyledRoot>
      <StyledLogo src="/images/ilutas.png" />

      <StyledContent>
        <StyledForm onSubmit={formik.handleSubmit}>
          <div style={{ width: '100%' }}>
            Enviamos um código via whats para <b>{phone}</b>
          </div>
          <TextField
            fullWidth
            id="token"
            variant="outlined"
            name="token"
            placeholder="Digite aqui!"
            autoFocus
            InputLabelProps={{
              style: {
                fontSize: 16,
                textTransform: 'uppercase',
                background: '#fff',
                paddingRight: 5,
                color: '#000',
              },
              shrink: true,
            }}
            label="Código de validação"
            value={formik.values.token}
            onChange={(event) => handleChangeToken('token', event)}
            onBlur={formik.handleBlur}
            error={formik.touched.token && Boolean(formik.errors.token)}
            helperText={formik.touched.token && formik.errors.token}
          />
          <Button
            color="primary"
            variant="contained"
            fullWidth
            disableRipple
            style={{
              height: 45,
            }}
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Verificar'}
          </Button>
        </StyledForm>
      </StyledContent>
    </StyledRoot>
  );
};

export default VerifyTokenForm;

import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {
  StyleContentCard,
  StyledButtonExit,
  StyledContainer,
  StyledHeader,
  StyledLogo,
  StyledMessage,
  StyledOutlet,
  StyledRoot,
} from './styles';
import useAuthenticate from '../../../recoil/hooks/authenticate';
import { getEvents, selectEvent } from '../../../api/events';
import SelectEvent from '../../../components/SelectEvent';
import Card from '../../../components/Card';
import { EventFormProps } from '../../../components/SelectEvent/schema';

const Events: React.FC = (): ReactElement => {
  const { authenticate, signin, decodeToken, logout } = useAuthenticate();
  const navigate = useNavigate();

  const [events, setEvents] = useState([]);

  const getData = useCallback(async () => {
    try {
      if (authenticate.token) {
        const data = await getEvents(authenticate.token);

        setEvents(data?.events || []);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  }, [authenticate.token]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleSubmit = async (values: EventFormProps) => {
    try {
      const data = await selectEvent(authenticate.token, values.event);

      signin(data.token);

      const tokenDecoded = decodeToken(data.token);

      const roleRoutes: any = {
        S: 'home',
        M: 'scoreboard',
        C: 'checkin',
        P: 'awards',
        E: 'statistics',
        K: 'areas',
      };

      const route = roleRoutes[tokenDecoded.role];

      if (route) {
        navigate(`/dash/events/${values.event}/${route}`);
      }
    } catch (erro) {
      toast('Não foí possível executar essa ação, tente novamente!');
    }
  };

  return (
    <StyledRoot>
      <StyledContainer>
        <StyledHeader>
          <StyledMessage>
            Bem vindo, {authenticate?.user?.name || 'amigo'}
          </StyledMessage>
          <StyledLogo src="/images/ilutas.png" />
          <StyledButtonExit onClick={logout}>
            <ExitToAppIcon />
            Sair
          </StyledButtonExit>
        </StyledHeader>
        <StyledOutlet>
          <StyleContentCard>
            <Card>
              <SelectEvent events={events} handleSubmit={handleSubmit} />
            </Card>
          </StyleContentCard>
        </StyledOutlet>
      </StyledContainer>
    </StyledRoot>
  );
};

export default Events;

import styled from 'styled-components';

const mapColor = (status: string): string => {
  if (['completed', 'bye'].includes(status)) {
    return '#dcedc8';
  }

  if (status === 'canceled') {
    return '#efefef';
  }

  return '';
};

export const StyledItem = styled('div')<{ status: string }>(
  ({ status, onClick }) => ({
    transition: '0.4s',
    padding: 16,

    boxSizing: 'border-box',
    borderRadius: 8,

    background: mapColor(status),
    '&:hover':
      !['completed', 'bye'].includes(status) && onClick
        ? {
            cursor: 'pointer',
            background: '#f1f1f1',
          }
        : undefined,
  })
);

export const StyledBroadcastInfo = styled('div')<{ broadcast?: boolean }>(
  ({ broadcast }) => ({
    display: 'flex',
    width: 270,
    justifyContent: 'center',
    borderRadius: 16,
    boxSizing: 'border-box',
    alignItems: 'center',
    gap: 8,
    color: broadcast ? '#2196f3' : '#777',
    padding: '16px',
    cursor: broadcast ? 'pointer' : undefined,
    boxShadow: broadcast
      ? '0 0 8px 0px rgba(21, 96, 250, 0.25)'
      : '0 0 8px 0px rgba(0, 0, 0, 0.25)',

    transition: '0.3s',

    '&:hover': {
      backgroundColor: broadcast ? 'rgba(21, 96, 250, 0.25)' : undefined,
    },
  })
);

export const StyledContentCheckbox = styled('div')(() => ({
  transition: '0.3s',
  marginTop: 8,
  padding: '4px 16px',
  boxSizing: 'border-box',
  borderRadius: 8,

  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
}));

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import { jsPDF as JsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

import ReactDOMServer from 'react-dom/server';
import StagesPdf from './StagesPdf';
import { getPublicBracket } from '../../../api/events';

const getRatio = (qtdFights: number): number => {
  if (qtdFights <= 8) {
    return 0.4;
  }

  return 0.7;
};

const generatePdf = async (pages: any[], filename: string) => {
  const pdf = new JsPDF({
    orientation: 'landscape',
    unit: 'px',
    format: 'a4',
  });

  const width = 3508;
  const height = 2480;

  for (let i = 0; i < pages?.length; i++) {
    const { fights, categoryName } = pages[i];
    if (i > 0) {
      pdf.addPage();
    }

    const ratio = getRatio(fights?.length);

    const htmlString = ReactDOMServer.renderToString(
      <div>
        <div
          style={{
            display: 'flex',
            padding: '32px 8px 8px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {categoryName || ''}
        </div>
        <StagesPdf
          stages={fights || []}
          reasonDesclassified={[]}
          handleClickFight={() => null}
        />
      </div>
    );

    const container = document.createElement('div');
    container.innerHTML = htmlString;
    container.style.position = 'absolute';
    container.style.top = '-9999px';
    container.style.zIndex = '1000';
    container.style.width = `${width * ratio}px`;
    container.style.height = `${height * ratio}px`;
    container.style.display = 'flex';
    container.style.justifyContent = 'center';
    document.body.appendChild(container);

    const canvas = await html2canvas(container, { scale: 2 });
    const imgData = canvas.toDataURL('image/jpeg', 0.8);

    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    pdf.addImage(imgData, 'JPEG', 0, 0, pageWidth, pageHeight);

    document.body.removeChild(container);
  }

  pdf.save(`${filename || 'bracket'}.pdf`);
};

export const handleExportCategoryToPdf = async (
  eventId?: string,
  categoryId?: string
): Promise<void> => {
  if (eventId && categoryId) {
    const data = await getPublicBracket(eventId, categoryId);

    const fights = data?.fights || [];

    if (fights?.length <= 16) {
      await generatePdf(
        [{ categoryName: data?.category?.categoryName, fights }],
        data?.category?.categoryName
      );
      return;
    }

    const knockout =
      fights.filter((item: any) => item.stage === 'knockout') || [];

    const eighth =
      fights.filter((item: any) => item.stage === 'eighth-final') || [];

    const quarter =
      fights.filter((item: any) => item.stage === 'quarter-final') || [];

    const semifinal =
      fights.filter((item: any) => item.stage === 'semifinal') || [];

    const final = fights.find((item: any) => item.stage === 'final');

    const bronze = fights.find((item: any) => item.stage === 'bronze');

    const pageOne = [];
    const pageTwo = [];
    const pageThree = [];

    pageOne.push(...knockout.filter((item: any) => item.order < 8));
    pageOne.push(...eighth.filter((item: any) => item.order < 4));

    pageTwo.push(...knockout.filter((item: any) => item.order >= 8));
    pageTwo.push(...eighth.filter((item: any) => item.order >= 4));

    pageThree.push(...quarter);
    pageThree.push(...semifinal);

    if (final) {
      pageThree.push(final);
    }

    if (bronze) {
      pageThree.push(bronze);
    }

    await generatePdf(
      [
        { categoryName: data?.category?.categoryName, fights: pageOne },
        { categoryName: data?.category?.categoryName, fights: pageTwo },
        { categoryName: data?.category?.categoryName, fights: pageThree },
      ],
      data?.category?.categoryName
    );
  }
};

export const handleExportCategoryToPdfAllInOne = async (
  eventId?: string,
  item?: any
): Promise<void> => {
  if (eventId && item) {
    const pages = [];
    for (const category of item.categories) {
      const data = await getPublicBracket(eventId, category.uuid);

      const fights = data?.fights || [];

      const { categoryName } = category;

      if (fights?.length <= 16) {
        pages.push({ categoryName, fights });
      } else {
        const knockout =
          fights.filter((item: any) => item.stage === 'knockout') || [];

        const eighth =
          fights.filter((item: any) => item.stage === 'eighth-final') || [];

        const quarter =
          fights.filter((item: any) => item.stage === 'quarter-final') || [];

        const semifinal =
          fights.filter((item: any) => item.stage === 'semifinal') || [];

        const final = fights.find((item: any) => item.stage === 'final');

        const bronze = fights.find((item: any) => item.stage === 'bronze');

        const pageOne = [];
        const pageTwo = [];
        const pageThree = [];

        pageOne.push(...knockout.filter((item: any) => item.order < 8));
        pageOne.push(...eighth.filter((item: any) => item.order < 4));

        pageTwo.push(...knockout.filter((item: any) => item.order >= 8));
        pageTwo.push(...eighth.filter((item: any) => item.order >= 4));

        pageThree.push(...quarter);
        pageThree.push(...semifinal);

        if (final) {
          pageThree.push(final);
        }

        if (bronze) {
          pageThree.push(bronze);
        }

        pages.push({ categoryName, fights: pageOne });
        pages.push({ categoryName, fights: pageTwo });
        pages.push({ categoryName, fights: pageThree });
      }
    }

    await generatePdf(pages, item.name);
  }
};

/* eslint-disable no-plusplus */
import React, { ReactElement } from 'react';

import { Divider, Tooltip } from '@mui/material';
import LanIcon from '@mui/icons-material/Lan';

import { StyledItem } from './styles';
import {
  formatOnlyHour,
  formatSecondsToMinutesAndSeconds,
} from '../../../../utils/date';
import AthleteItem from '../AthleteItem';
import { mapStage, mapStatus, mapStatusFight } from '../../../../utils/fight';

interface FightItemProps {
  fight: any;
  action?: {
    icon: React.ReactElement;
    action: (athlete: any) => void;
    label: string;
    isDisabled?: (athlete: any) => boolean;
  };
  mapBackgroundByStatus?: (status: string) => { color: string };
  reasons?: any[];
  handleChangeAthletes?: (athlete: any, origin: any) => void;
  handleChangeFight?: (fight: any, origin: any, fightOver?: any) => void;
  handleChangeFightArea?: (fight: any, area: any, fightOver?: any) => void;
  athleteDrag?: any;
  setAthleteDrag?: (athlete: any) => void;
  fightDrag?: any;
  setFightDrag?: (fight: any) => void;
  fightOver?: any;
  setFightOver?: (fight: any) => void;
  handleClick?: (fight: any) => void;
  area?: any;
  showAge?: boolean;
  handleRedirectCategory?: (area: any, category: any) => void;
}

const FightItem: React.FC<FightItemProps> = ({
  fight,
  area,
  action,
  reasons,
  handleChangeFightArea,
  mapBackgroundByStatus,
  handleChangeAthletes,
  handleChangeFight,
  athleteDrag,
  handleClick,
  setAthleteDrag,
  fightDrag,
  setFightDrag,
  fightOver,
  setFightOver,
  showAge,
  handleRedirectCategory,
}): ReactElement => {
  const getAthlete = (athletes: any[], order: number): any => {
    return athletes.find((item) => item.order === order);
  };

  const onDragStart =
    (fight: any, athlete: number) => (event: React.DragEvent) => {
      if (setFightDrag) {
        setFightDrag(null);
      }
      if (setAthleteDrag) {
        setAthleteDrag(athlete);
      }
      event.dataTransfer.setData('athlete', JSON.stringify(athlete));
    };

  const onDragFightStart = (fight: any) => (event: React.DragEvent) => {
    if (setFightDrag) {
      setFightDrag(fight);
    }
    event.dataTransfer.setData('fight', JSON.stringify(fight));
  };

  const allowDrop = (fight: any) => (event: React.DragEvent) => {
    event.preventDefault();

    if (setFightOver) {
      setFightOver(fight);
    }
  };

  const onDragEnd = (event: React.DragEvent) => {
    if (setAthleteDrag) {
      setAthleteDrag(null);
    }

    if (setFightDrag) {
      setFightDrag(null);
    }

    if (setFightOver) {
      setFightOver(null);
    }

    event.preventDefault();
  };

  const onDrop = (athlete: any) => (event: React.DragEvent) => {
    if (!athlete) {
      return;
    }

    if (['completed', 'canceled'].includes(fight?.status)) {
      return;
    }

    const data = event.dataTransfer.getData('athlete');

    const athleteOrigin = JSON.parse(data);

    if (athlete.id === athleteOrigin.id) {
      return;
    }

    if (athlete?.category !== athleteOrigin.category) {
      return;
    }

    if (handleChangeAthletes) {
      handleChangeAthletes(athleteOrigin, athlete);
    }

    event.preventDefault();
    event.stopPropagation();
  };

  const onDropFight = (fight: any) => (event: React.DragEvent) => {
    event.stopPropagation();
    event.preventDefault();

    const data = event.dataTransfer.getData('fight');
    const fightOrigin = JSON.parse(data);

    if (!fight || fight?.status === 'completed') {
      return;
    }

    if (fight.id === fightOrigin.id) {
      return;
    }

    // if (
    //   fight?.category?.category !== fightOrigin?.category?.category ||
    //   fight?.category?.eventAreaId === fightOrigin?.category?.eventAreaId
    // ) {
    //   return;
    // }

    if (
      fight?.category?.category === fightOrigin?.category?.category &&
      handleChangeFight
    ) {
      handleChangeFight(fightOrigin, fight);
      return;
    }

    if (
      fight?.category?.eventAreaId !== fightOrigin?.category?.eventAreaId &&
      handleChangeFightArea &&
      area
    ) {
      handleChangeFightArea(fightOrigin, area, fightOver);
    }
  };

  const renderTooltip = () => {
    const winReason = fight?.athletes?.[0]?.result?.typeVictoryDescription;
    const time = fight?.athletes?.[0]?.result?.timeFight;

    const athleteOne = getAthlete(fight.athletes, 0);
    const athleteTwo = getAthlete(fight.athletes, 1);

    return (
      <div
        style={{
          minWidth: 200,
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
          padding: 8,
          boxSizing: 'border-box',
          fontSize: 16,
        }}
      >
        <div style={{ fontSize: 18, fontWeight: 'bold' }}>
          {mapStage(fight.stage)}
        </div>

        {Boolean(winReason) && (
          <div style={{}}>
            Vitória por
            <br />
            <span style={{ fontWeight: 'bold' }}>{winReason}</span>
          </div>
        )}

        {Boolean(athleteOne) &&
          Boolean(athleteTwo) &&
          !athleteOne?.reasonDesclassificationCode &&
          !athleteTwo?.reasonDesclassificationCode && (
            <div>
              <div style={{ display: 'flex', gap: 8 }}>
                <div>Pontos</div>
                <div>
                  {athleteOne?.result?.points || '0'} -{' '}
                  {athleteTwo?.result?.points || '0'}{' '}
                </div>
              </div>
              <div style={{ display: 'flex', gap: 8 }}>
                <div>Vantagens</div>
                <div>
                  {athleteOne?.result?.advantages || '0'} -{' '}
                  {athleteTwo?.result?.advantages || '0'}{' '}
                </div>
              </div>
              <div style={{ display: 'flex', gap: 8 }}>
                <div>Desvantagens</div>
                <div>
                  {athleteOne?.result?.faults || '0'} -{' '}
                  {athleteTwo?.result?.faults || '0'}{' '}
                </div>
              </div>
            </div>
          )}

        {Boolean(fight.finishAt) && (
          <div>Tempo {formatSecondsToMinutesAndSeconds(time || 0)}</div>
        )}

        {Boolean(fight.finishAt) && (
          <div>{`Finalizou às ${formatOnlyHour(fight.finishAt)}`}</div>
        )}
      </div>
    );
  };

  const formatAge = (category: any) => {
    if (!category) {
      return '';
    }

    const startAge = category.startAge || 0;
    const endAge = category.endAge || 0;

    if (!startAge && !endAge) {
      return '';
    }

    if (startAge === endAge) {
      return `${startAge} anos`;
    }

    return `${startAge} - ${endAge} anos`;
  };

  const formatWeight = (category: any) => {
    if (!category) {
      return '-';
    }

    const endWeight = category.endWeight || 0;

    if (!endWeight) {
      return '-';
    }

    return `até ${endWeight} KG`;
  };

  return (
    <Tooltip
      title={fight?.status === 'completed' ? renderTooltip() : ''}
      arrow
      placement="right"
    >
      <StyledItem
        key={fight.id}
        status={fight.status}
        draggable={
          Boolean(handleChangeFight) &&
          !['canceled', 'completed'].includes(fight.status)
        }
        allowdropathlete={
          (athleteDrag?.category === fight?.category?.category &&
            athleteDrag?.stage === fight?.stage &&
            !['canceled', 'completed'].includes(fight.status)) ||
          (fightDrag?.category?.category === fight?.category?.category &&
            fightDrag?.stage === fight?.stage &&
            !['canceled', 'completed'].includes(fight.status))
            ? true
            : undefined
        }
        onDragStart={onDragFightStart(fight)}
        onDragEnd={onDragEnd}
        onDragOver={fightDrag ? allowDrop(fight) : undefined}
        onDrop={onDropFight(fight)}
        onClick={() => handleClick?.(fight)}
      >
        {handleRedirectCategory && (
          <Tooltip title="Visualizar categoria" arrow>
            <div
              style={{ position: 'absolute', top: 0, right: 0 }}
              onClick={() => handleRedirectCategory(area, fight?.category)}
              // onClick={() => console.log('area', fight, area)}
            >
              <LanIcon htmlColor="#777" />
            </div>
          </Tooltip>
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              flex: 1,
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 8,
              }}
            >
              <Tooltip title={mapStatusFight(fight.status).label} arrow>
                <div
                  style={{
                    width: 12,
                    height: 12,
                    background: mapStatusFight(fight.status).background,
                    borderRadius: 12,
                  }}
                />
              </Tooltip>
              <div style={{ fontWeight: 'bold' }}>
                {formatOnlyHour(fight.date)}
              </div>
              <div>
                {mapStage(fight.stage)}{' '}
                {!['final', 'bronze'].includes(fight.stage)
                  ? fight.order + 1
                  : ''}
                {fight.area ? ` - ${fight.area?.name}` : ''}
              </div>
            </div>
            <div
              style={{
                fontSize: 12,
              }}
            >
              {fight.category?.categoryName}
            </div>
            {showAge && (
              <div
                style={{
                  fontSize: 12,
                  display: 'flex',
                  gap: 5,
                }}
              >
                <div>Idade: {formatAge(fight.category)}</div>
                <div>Peso {formatWeight(fight.category)}</div>
              </div>
            )}
            {fight?.result?.typeVictoryDescription && (
              <div
                style={{
                  fontSize: 12,
                  color: '#4caf50',
                }}
              >
                {fight?.result?.typeVictoryDescription}
              </div>
            )}
          </div>
          <div
            style={{
              padding: '4px 8px',
              boxSizing: 'border-box',
              borderRadius: 8,
              fontSize: 12,
              background: mapStatus(fight?.status)?.background,
              color: mapStatus(fight?.status)?.color,
            }}
          >
            {mapStatus(fight?.status)?.label}
          </div>
        </div>

        <div
          style={{
            margin: '4px 0px',
            borderRadius: 4,
            overflow: 'hidden',
          }}
        >
          <AthleteItem
            athlete={getAthlete(fight.athletes, 0)}
            color="#fff"
            background={
              mapBackgroundByStatus
                ? mapBackgroundByStatus(getAthlete(fight.athletes, 0)?.status)
                    .color
                : '#2B4755'
            }
            action={action}
            reasons={reasons}
            draggable={
              Boolean(handleChangeAthletes) &&
              fight.status !== 'completed' &&
              Boolean(getAthlete(fight.athletes, 0))
            }
            onDragStart={onDragStart(fight, getAthlete(fight.athletes, 0))}
            onDragOver={athleteDrag ? allowDrop : undefined}
            onDragEnd={onDragEnd}
            onDragLeave={() => null}
            onDrop={onDrop(getAthlete(fight.athletes, 0))}
          />

          {mapBackgroundByStatus && <Divider />}

          <AthleteItem
            athlete={getAthlete(fight.athletes, 1)}
            color={mapBackgroundByStatus ? '#fff' : undefined}
            background={
              mapBackgroundByStatus
                ? mapBackgroundByStatus(getAthlete(fight.athletes, 1)?.status)
                    .color
                : undefined
            }
            action={action}
            reasons={reasons}
            draggable={
              Boolean(handleChangeAthletes) &&
              fight.status !== 'completed' &&
              Boolean(getAthlete(fight.athletes, 1))
            }
            onDragStart={onDragStart(fight, getAthlete(fight.athletes, 1))}
            onDragOver={athleteDrag ? allowDrop : undefined}
            onDragEnd={onDragEnd}
            onDragLeave={() => null}
            onDrop={onDrop(getAthlete(fight.athletes, 1))}
          />
        </div>
      </StyledItem>
    </Tooltip>
  );
};

export default FightItem;

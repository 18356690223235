import React, { ReactElement, useEffect, useState } from 'react';

import { Checkbox, ListItemText, MenuItem, Select } from '@mui/material';

interface SelectAreaProps {
  eventAreas?: any[];
  defaultSelected?: number[];
  onChange: (values: number[]) => void;
}

const SelectArea: React.FC<SelectAreaProps> = ({
  eventAreas,
  defaultSelected,
  onChange,
}): ReactElement => {
  const [areasSelected, setAreasSelected] = useState<number[]>(
    defaultSelected || []
  );

  useEffect(() => {
    if (
      eventAreas?.length &&
      !defaultSelected?.length &&
      !areasSelected.length
    ) {
      setAreasSelected(eventAreas?.map((item) => item.id) || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventAreas]);

  useEffect(() => {
    if (!areasSelected.length) {
      setAreasSelected(eventAreas?.map((item) => item.id) || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onChange(areasSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areasSelected]);

  return (
    <Select
      multiple
      value={areasSelected}
      onChange={(e) => setAreasSelected(e.target.value as number[])}
      renderValue={(selected) => {
        if (!selected.length) {
          return 'Nenhuma área';
        }
        if (selected.length === eventAreas?.length) {
          return 'Todas áreas';
        }
        return selected
          .map((item) => {
            const area = eventAreas?.find((area) => area.id === item);
            return area?.name || '';
          })
          .join(', ');
      }}
      style={{
        width: 180,
      }}
    >
      {eventAreas?.map((area: any) => (
        <MenuItem key={area.id} value={area.id}>
          <Checkbox checked={Boolean(areasSelected.includes(area.id))} />
          <ListItemText primary={area.name} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectArea;

import * as yup from 'yup';

export const initialFilterFormValues = {
  category: '',
  track: '',
  weight: '',
  gender: '',

  name: '',
  gym: '',
  area: '',
  day: '0',
  status: '',
};

export interface FilterFormProps {
  category: string;
  track: string;
  weight: string;
  gender: string;

  name: string;
  gym: string;
  area: string;
  day: string;
  status: string;
}

export const validationSchema = yup.object({});

import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Fight from './Fight';
import useEvent from '../../../recoil/hooks/event';
import { EventTypeDrawFight } from '../../../interfaces/event';
import useLoading from '../../../recoil/hooks/loading';

const ScoreboardOffline: React.FC = (): ReactElement => {
  const { setLoadingModal } = useLoading();

  const { event } = useEvent();

  const isGoldenScore = useMemo(() => {
    return event?.typeDrawFight === EventTypeDrawFight.GOLDEN_SCORE;
  }, [event]);

  const [scoreDetails, setScoreDetails] = useState<any>(null);

  const formatTime = (time: string): number => {
    const regex = /^([0-5]?[0-9]):([0-5][0-9])$/;
    const match = time.match(regex);

    if (!match) {
      return 0;
    }

    const minutes = parseInt(match[1], 10);
    const seconds = parseInt(match[2], 10);

    return minutes * 60 + seconds;
  };

  const getData = useCallback(async () => {
    try {
      setLoadingModal(true);
      const data = localStorage.getItem('@ilutas:scoreboard-offline-data');
      if (data) {
        const dataParsed = JSON.parse(data);

        setScoreDetails({
          ...dataParsed,
          athletes: [
            {
              nameAthlete: dataParsed.athleteOneName,
              gym: dataParsed.athleteOneTeam,
              photo: undefined,
              uuid: '1',
              order: 0,
            },
            {
              nameAthlete: dataParsed.athleteTwoName,
              gym: dataParsed.athleteTwoTeam,
              photo: undefined,
              uuid: '2',
              order: 1,
            },
          ],
          time: formatTime(dataParsed.time),
          eventAreaCategory: { categoryName: dataParsed.category || '' },
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    } finally {
      setLoadingModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleChangeOrderAthletes = () => {
    const data = localStorage.getItem('@ilutas:scoreboard-offline-data');
    if (data) {
      const dataParsed = JSON.parse(data);

      localStorage.setItem(
        '@ilutas:scoreboard-offline-data',
        JSON.stringify({
          athleteOneName: dataParsed.athleteTwoName,
          athleteOneTeam: dataParsed.athleteTwoTeam,
          athleteTwoName: dataParsed.athleteOneName,
          athleteTwoTeam: dataParsed.athleteOneTeam,
          time: dataParsed.time,
        })
      );
    }

    getData();
  };

  return (
    <Fight
      scoreDetails={scoreDetails}
      isGoldenScore={isGoldenScore}
      handleChangeOrderAthletes={handleChangeOrderAthletes}
    />
  );
};

export default ScoreboardOffline;

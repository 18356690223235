import * as yup from 'yup';

export const initialEventFormValues = {
  startDate: null,
  endDate: null,
  name: '',
  startTimeFight: '',
  numberDaysFight: '',
  timeBetweenFight: '',
  timeBetweenCategory: '',
  timeCategoryOneAthlete: '',
  numberAreas: '',
  numberThird: '',
  typeKey3: '',
  hasThirdPlaceFight: 'N',
  athletesByeFowardAuto: 1,
  typeDrawFight: '',
  verifyAthleteDataAward: '',
  eventHasCheckin: 'S',
};

export interface EventFormProps {
  startDate: Date | null;
  endDate: Date | null;
  name: string;
  startTimeFight: string;
  numberDaysFight: string;
  timeBetweenFight: string;
  timeBetweenCategory: string;
  timeCategoryOneAthlete: string;
  numberAreas: string;
  numberThird: string;
  typeKey3: string;
  hasThirdPlaceFight: string;
  athletesByeFowardAuto: string;
  typeDrawFight: string;
  verifyAthleteDataAward: string;
  eventHasCheckin: string;
}

export const validationSchema = yup.object({
  startTimeFight: yup.string().required('Campo obrigatório').nullable(),
  numberDaysFight: yup.string().required('Campo obrigatório').nullable(),
  timeBetweenFight: yup.string().required('Campo obrigatório').nullable(),
  timeBetweenCategory: yup.string().required('Campo obrigatório').nullable(),
  timeCategoryOneAthlete: yup.string().required('Campo obrigatório').nullable(),
  numberAreas: yup.string().required('Campo obrigatório').nullable(),
  numberThird: yup.string().required('Campo obrigatório').nullable(),
  typeKey3: yup.string().required('Campo obrigatório').nullable(),
  hasThirdPlaceFight: yup.string().required('Campo obrigatório').nullable(),
  athletesByeFowardAuto: yup.string().required('Campo obrigatório').nullable(),
  typeDrawFight: yup.string().required('Campo obrigatório').nullable(),
  verifyAthleteDataAward: yup.string().required('Campo obrigatório').nullable(),
  eventHasCheckin: yup.string().required('Campo obrigatório').nullable(),
});

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Avatar,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/CheckCircle';
import { StyledItem } from './styles';

interface Props {
  open: boolean;
  athletes: any[];
  title?: string;
  defaultWinner?: string;
  reasonVictory: { id: string; description: string }[];
  backButtonText?: string;
  hiddenWithoutWinner?: boolean;
  isLoading?: boolean;
  handleBack: () => void;
  getDefaultWinnerByTime?: () => string;
  handleSubmit: (values: {
    winner: string | null;
    loser: string | null;
    withoutWinner: boolean;
    reason: string;
  }) => void;
  startGoldenScore?: boolean;
}

const ModalFinishFight: React.FC<Props> = ({
  open,
  reasonVictory,
  isLoading,
  athletes,
  defaultWinner,
  hiddenWithoutWinner,
  title = 'Concluir luta',
  backButtonText = 'Voltar para a luta',
  handleSubmit,
  getDefaultWinnerByTime,
  handleBack,
  startGoldenScore,
}): React.ReactElement => {
  const [withoutWinner, setWithoutWinner] = React.useState(false);
  const [winner, setWinner] = React.useState<string | null>(null);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [reason, setReason] = React.useState('');

  const onSubmit = () => {
    if (typeof reason !== 'number') {
      setErrorMessage('Por favor, selecione o motivo!');
      return;
    }

    if (!withoutWinner && !winner) {
      setErrorMessage('Por favor, selecione o vencedor!');
      return;
    }

    const defaultWinner = athletes?.[0]?.uuid;
    const defaultLoser = athletes?.[1]?.uuid;

    const loser = athletes?.find((item) => item.uuid !== winner)?.uuid;

    handleSubmit({
      withoutWinner,
      winner: withoutWinner ? defaultWinner : winner,
      loser: withoutWinner ? defaultLoser : loser,
      reason,
    });
  };

  const handleSelect = (id: string) => {
    setErrorMessage('');
    setWinner(id);
  };

  const handleSelectReason = (id: string) => {
    setErrorMessage('');
    setReason(id);
  };

  React.useEffect(() => {
    if (open && athletes?.length === 1) {
      setWinner(athletes[0].uuid);
    }
  }, [open, athletes]);

  React.useEffect(() => {
    if (open) {
      const defaultWinner = getDefaultWinnerByTime
        ? getDefaultWinnerByTime()
        : null;

      if (defaultWinner) {
        setWinner(defaultWinner);

        const reason = startGoldenScore ? 'Pontos no Golden Score' : 'Pontos';

        const defaultReason = reasonVictory.find(
          (item) => item.description === reason
        );
        if (defaultReason) {
          setReason(defaultReason.id);
        }
      }
    }
  }, [getDefaultWinnerByTime, open, reasonVictory, startGoldenScore]);

  React.useEffect(() => {
    if (open) {
      if (athletes?.length === 1) {
        const reason = 'Bye';

        const defaultReason = reasonVictory.find(
          (item) => item.description === reason
        );
        if (defaultReason) {
          setReason(defaultReason.id);
        }
      }
    }
  }, [open, athletes, reasonVictory]);

  React.useEffect(() => {
    if (open && defaultWinner) {
      setWinner(defaultWinner);

      const defaultReasonText =
        athletes?.length === 1 ? 'Bye' : 'Campeão por WO';

      const defaultReason = reasonVictory.find(
        (item) => item.description === defaultReasonText
      );
      if (defaultReason) {
        setReason(defaultReason.id);
      }
    }
  }, [open, defaultWinner, athletes?.length, reasonVictory]);

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 12,
              background: withoutWinner ? '#f1f1f1' : '',
              padding: '12px',
              borderRadius: 8,
            }}
          >
            <div>Selecione o vencedor</div>
            <div
              style={{
                display: 'flex',
                gap: 16,
              }}
            >
              {athletes?.map((item, index) => (
                <StyledItem
                  key={index}
                  disabled={
                    withoutWinner || Boolean(item.reasonDesclassificationCode)
                  }
                  selected={!withoutWinner && item.uuid === winner}
                  onClick={() => handleSelect(item.uuid)}
                >
                  <div>
                    <Avatar
                      alt={item.nameAthlete}
                      src={item.photo}
                      sx={{ width: 42, height: 42 }}
                      style={{
                        border: '1px solid #000',
                      }}
                    />
                  </div>
                  <div>
                    <div>{item.nameAthlete}</div>
                    {Boolean(item.reasonDesclassificationCode) && (
                      <div style={{ color: '#e57373' }}>Desclassificado</div>
                    )}
                  </div>

                  {!withoutWinner && item.uuid === winner && (
                    <div style={{ position: 'absolute', top: 0, left: 0 }}>
                      <CheckIcon htmlColor="green" fontSize="small" />
                    </div>
                  )}
                </StyledItem>
              ))}
            </div>
          </div>

          {!hiddenWithoutWinner && (
            <div style={{ padding: '0px 16px', boxSizing: 'border-box' }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={withoutWinner}
                      checked={withoutWinner}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setWithoutWinner(e.target.checked);
                        setErrorMessage('');
                      }}
                    />
                  }
                  label="Não houve vencedor"
                />
              </FormGroup>
            </div>
          )}

          <div
            style={{
              padding: '0px 16px',
              boxSizing: 'border-box',
              gap: 12,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div>Motivo</div>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Selecione</InputLabel>
              <Select
                value={reason}
                label="Selecione"
                onChange={(e) => handleSelectReason(e.target.value)}
              >
                {reasonVictory
                  ?.filter((item) => {
                    if (!startGoldenScore) {
                      return item.description !== 'Pontos no Golden Score';
                    }
                    return item;
                  })
                  ?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.description}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div
          style={{
            textAlign: 'center',
            fontSize: 14,
            color: '#f00',
            paddingTop: 24,
          }}
        >
          {errorMessage}
        </div>
      </DialogContent>
      <DialogActions style={{ padding: '24px 40px', boxSizing: 'border-box' }}>
        <Button
          style={{ color: '#222' }}
          onClick={handleBack}
          disabled={isLoading}
          disableRipple
          fullWidth
          variant="outlined"
        >
          {isLoading ? <CircularProgress size={24} /> : backButtonText}
        </Button>
        <Button
          onClick={onSubmit}
          autoFocus
          disableRipple
          variant="contained"
          disabled={isLoading}
          fullWidth
        >
          {isLoading ? <CircularProgress size={24} /> : 'Confirmar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalFinishFight;

import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledRoot } from './styles';

import {
  getEventFilters,
  getPublicEventAreas,
  getReasonDesclassifiedRequest,
} from '../../../api/events';

import DetailsInfo from '../../../components/DetailsInfo';

import ListAreasV2 from '../../../components/ListAreasV2';

const EventAreas: React.FC = (): ReactElement => {
  const { eventId } = useParams();

  const [event, setEvent] = useState<any>(null);

  const [eventAreas, setEventAreas] = useState<any>([]);

  const [reasonDesclassified, setReasonDesclassified] = useState<any>([]);

  const [isLoading, setLoading] = useState(true);

  const [filters, setFilters] = useState<any>({});

  const getFilters = useCallback(async () => {
    try {
      if (eventId) {
        // setLoadingFilters(true);
        const data = await getEventFilters(eventId);
        setFilters(data?.filters || {});
      }
    } catch (error) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    } finally {
      // setLoadingFilters(false);
    }
  }, [eventId]);

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  const getReasonDesclassified = useCallback(async () => {
    try {
      const data = await getReasonDesclassifiedRequest();

      setReasonDesclassified(data?.data || []);
    } catch (error) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    }
  }, []);

  useEffect(() => {
    getReasonDesclassified();
  }, [getReasonDesclassified]);

  const getEventAreasData = useCallback(async () => {
    try {
      setLoading(true);
      if (eventId) {
        const data = await getPublicEventAreas(eventId);

        setEventAreas(data?.areas || []);
        setEvent(data?.event || null);
      }
    } catch (error) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }, [eventId]);

  useEffect(() => {
    getEventAreasData();
  }, [getEventAreasData]);

  return (
    <StyledRoot>
      <div style={{ width: '100%' }}>
        <DetailsInfo
          isLoading={isLoading}
          noPadding
          sections={[
            {
              rows: [
                {
                  key: 'name',
                  label: 'Nome',
                },

                {
                  key: 'numberAreas',
                  label: 'Nº de áreas',
                },
              ],
              data: event,
              title: 'Evento',
            },
          ]}
        />

        {!isLoading && (
          <ListAreasV2
            filtersData={filters}
            areas={eventAreas}
            event={event}
            showInfo={false}
            reasonDesclassified={reasonDesclassified}
          />
        )}
      </div>
    </StyledRoot>
  );
};

export default EventAreas;

export const normalize = (str: string): string => {
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/-/g, '')
    .replace(/\//g, '')
    .trim();
};

export const compareStrings = (str1: string, str2: string): boolean => {
  return normalize(str1).includes(normalize(str2));
};

export const stringMatches = (first: string, second: string): boolean => {
  const normalizedName = normalize(first);
  const normalizedSearch = normalize(second);

  const searchWords = normalizedSearch.split(/\s+/);

  return searchWords.every((word) => normalizedName.includes(word));
};

export const matchCategoryName = (
  categoryName: string,
  search: string
): boolean => {
  const normalizedCategory = normalize(categoryName);
  const normalizedSearch = normalize(search);

  // Faz a busca por índice exato dentro da string normalizada
  return normalizedCategory.includes(normalizedSearch);
};

export const matchCategoryExactPhrase = (
  categoryName: string,
  search: string
): boolean => {
  const normalizedCategory = normalize(categoryName);
  const normalizedSearch = normalize(search);

  // Usa regex para evitar corresponder dentro de outras palavras
  const regex = new RegExp(`\\b${normalizedSearch}\\b`, 'i');
  return regex.test(normalizedCategory);
};

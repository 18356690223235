import styled from 'styled-components';

export const StyledRoot = styled('div')<{ nopadding?: boolean }>(
  ({ nopadding }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    padding: nopadding ? 0 : 24,
    boxSizing: 'border-box',
  })
);

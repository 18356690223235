/* eslint-disable no-plusplus */
import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { Box, Button, MenuItem, Select, Tab, Tabs } from '@mui/material';
import AreasCategory from './AreasCategory';
import AreasFights from './AreasFights';
import FilterOrganizeAreaV2 from './FilterOrganizeAreaV2';
import useAuthenticate from '../../recoil/hooks/authenticate';
import StatusFilter from '../CheckinAreas/StatusFilter';
import SelectArea from '../CheckinAreas/SelectArea';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, padding: '0px 24px' }}>{children}</Box>
      )}
    </div>
  );
}

interface ShowAreasProps {
  categories: any[];
  startTime: string;
  eventAreas?: any[];
  areas: number;
  numberDays: number;
  filtersData?: {
    weight: string[];
    track: string[];
    category: string[];
  };
  handleStart: () => void;
  handleChangeAthletes?: (athlete: any, origin: any) => void;
  handleChangeFight?: (fight: any, origin: any) => void;
  handleRedirectArea?: (area: any, item: any) => void;
  reasonDesclassified?: any[];
  handleChangeBroadcast?: (area: string, active: boolean) => Promise<void>;
  handleChangeFightArea?: (fight: any, area: any) => void;
}

const ShowAreas: React.FC<ShowAreasProps> = ({
  categories,
  eventAreas,
  filtersData,
  numberDays,
  handleStart,
  reasonDesclassified,
  handleChangeAthletes,
  handleChangeFight,
  handleRedirectArea,
  handleChangeBroadcast,
  handleChangeFightArea,
}): ReactElement => {
  const { authenticate } = useAuthenticate();

  const [daySelected, setDaySelected] = useState('0');

  const isStaff = authenticate?.user?.role === 'S';

  const [days, setDays] = useState<number[]>([]);

  const [areasSelected, setAreasSelected] = useState<number[]>([]);

  const filterByAreas = (items: any[], areasSelected: number[]): any[] => {
    if (!areasSelected.length) {
      return [];
    }
    const result = items.filter((item) => areasSelected.includes(item.id));

    return result;
  };

  const [filters, setFilters] = useState({
    category: '',
    track: '',
    weight: '',
    gender: '',
    typeCategory: '',
    typeCategoryMultiple: [] as string[],
    categoryMultiple: [] as string[],
    trackMultiple: [] as string[],
    weightMultiple: [] as string[],
    genderMultiple: [] as string[],
  });

  const [statusFilter, setStatusFilter] = useState({
    canceled: true,
    completed: true,
    in_progress: true,
    oneAthlete: false,
    pending: true,
    programed: true,
  });

  useEffect(() => {
    const newDays: number[] = [];

    if (eventAreas?.length) {
      eventAreas.forEach((item) => {
        newDays.push(item.day);
      });

      setDays([...new Set(newDays)].sort());
    }
  }, [eventAreas]);

  const [tabValue, setTabValue] = React.useState(0);

  const handleChangeTabValue = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabValue(newValue);
  };

  const filterByDay = (items: any[], day: number): any[] => {
    return items.filter((item) => item.day === day);
  };

  const eventsAreasToSelect = useMemo(() => {
    return filterByDay(eventAreas || [], Number(daySelected));
  }, [eventAreas, daySelected]);

  const hasFiltersApplied = useMemo(() => {
    return Boolean(
      filters.category ||
        filters.gender ||
        filters.track ||
        filters.weight ||
        filters.typeCategory ||
        filters.typeCategoryMultiple?.length ||
        filters.categoryMultiple?.length ||
        filters.weightMultiple?.length ||
        filters.trackMultiple?.length ||
        filters.genderMultiple?.length
    );
  }, [
    filters.category,
    filters.categoryMultiple?.length,
    filters.gender,
    filters.genderMultiple?.length,
    filters.track,
    filters.trackMultiple?.length,
    filters.typeCategory,
    filters.typeCategoryMultiple?.length,
    filters.weight,
    filters.weightMultiple?.length,
  ]);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        padding: '0px 24px 24px 24px',
        boxSizing: 'border-box',
      }}
    >
      <div style={{ textAlign: 'center', fontSize: 18 }}>Visualizar áreas</div>
      {isStaff && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: 16,
            boxSizing: 'border-box',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            size="small"
            disableRipple
            onClick={handleStart}
            style={{
              letterSpacing: 1,
              fontSize: 14,
            }}
          >
            Editar
          </Button>
        </div>
      )}

      {!!filtersData && (
        <div>
          <div>Filtros</div>
          <div>
            <FilterOrganizeAreaV2
              categories={categories}
              filtersData={filtersData}
              handleSubmit={setFilters}
              hasFiltersApplied={hasFiltersApplied}
            />
          </div>
        </div>
      )}

      <Box
        sx={{
          width: '100%',

          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            borderColor: 'divider',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 12,
          }}
        >
          <Tabs value={tabValue} onChange={handleChangeTabValue}>
            <Tab
              style={{
                width: 200,
                background: tabValue === 0 ? '#083061' : '#ddd',
                color: tabValue === 0 ? '#fff' : undefined,
                marginRight: 0,
              }}
              label="Categorias"
              {...a11yProps(0)}
            />
            <Tab
              style={{
                width: 200,
                background: tabValue === 1 ? '#083061' : '#ddd',
                color: tabValue === 1 ? '#fff' : undefined,
                marginLeft: 0,
              }}
              label="Lutas"
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>

        <div
          style={{
            padding: '24px',
            // display: 'flex',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', gap: 16 }}>
            <Select
              value={daySelected}
              onChange={(event) => setDaySelected(event.target.value)}
              style={{
                width: 320,
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              }}
            >
              {days.map((item, index) => (
                <MenuItem key={index} value={index}>
                  Dia {index + 1}
                </MenuItem>
              ))}
            </Select>

            <SelectArea
              eventAreas={eventsAreasToSelect}
              onChange={setAreasSelected}
              defaultSelected={
                authenticate?.user?.role === 'K'
                  ? authenticate?.user?.areas?.map(Number)
                  : []
              }
            />
          </div>

          <div
            style={{
              display: 'flex',
              gap: 16,
              padding: '0 16px 16px 16px',
              alignItems: 'center',
              marginTop: 12,
              // background: 'red',
              // justifyContent: 'center',
            }}
          >
            <StatusFilter
              onChange={setStatusFilter}
              defaultValue={{
                canceled: true,
                completed: true,
                in_progress: true,
                oneAthlete: false,
                pending: true,
                programed: true,
              }}
              inProgress
            />
          </div>
        </div>

        <CustomTabPanel value={tabValue} index={0}>
          <AreasCategory
            filters={filters}
            numberDays={numberDays}
            daySelected={daySelected}
            eventAreas={filterByAreas(eventAreas || [], areasSelected)}
            redirect={handleRedirectArea}
            handleChangeBroadcast={handleChangeBroadcast}
            statusFilter={statusFilter}
            hasFiltersApplied={hasFiltersApplied}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <AreasFights
            filters={filters}
            numberDays={numberDays}
            eventAreas={filterByAreas(eventAreas || [], areasSelected)}
            daySelected={daySelected}
            reasonDesclassified={reasonDesclassified}
            handleChangeAthletes={handleChangeAthletes}
            handleChangeFight={handleChangeFight}
            handleChangeFightArea={handleChangeFightArea}
            redirect={handleRedirectArea}
            handleChangeBroadcast={handleChangeBroadcast}
            statusFilter={statusFilter}
            hasFiltersApplied={hasFiltersApplied}
          />
        </CustomTabPanel>
      </Box>
    </div>
  );
};

export default ShowAreas;

import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import { toast } from 'react-toastify';
import { StyledRoot } from './styles';
import useAuthenticate from '../../../recoil/hooks/authenticate';

import {
  addUser,
  deleteUser,
  getEvent,
  getEventAreasCode,
  getUsers,
} from '../../../api/events';

import Card from '../../../components/Card';
import DetailsInfo from '../../../components/DetailsInfo';

import useEvent from '../../../recoil/hooks/event';
import ListUsers from './ListUsers';
import ModalAddUser from './ModalAddUser';
import ConfirmModal from '../../../components/ConfirmModal';

const Users: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();

  const { eventId } = useParams();

  const [modalConfirm, setModalConfirm] = useState<{
    open: boolean;
    title: string;
    confirmLabel: string;
    cancelLabel: string;
    message: string;
    onConfirm: () => void;
    onCancel?: () => void;
  }>({
    open: false,
    title: 'Excluir usuário',
    confirmLabel: 'Confirmar',
    cancelLabel: 'Cancelar',
    message:
      'O usuário será removido e não terá mais acesso ao evento. Deseja continuar?',
    onConfirm: () => {
      setModalConfirm((prev) => ({
        ...prev,
        open: false,
      }));
    },
    onCancel: () => {
      setModalConfirm((prev) => ({
        ...prev,
        open: false,
      }));
    },
  });

  const [modalAddUser, setModalAddUser] = useState(false);

  const [event, setEvent] = useState<any>(null);

  const [search, setSearch] = useState<any>('');

  const [editUser, setEditUser] = useState<any>(null);

  const [data, setData] = useState<any>(null);

  const [areas, setAreas] = useState<any>(null);

  const { setEvent: setEventRecoil } = useEvent();

  const [isLoading, setLoading] = useState(true);
  const [isLoadingData, setLoadingData] = useState(true);

  const getEventRequest = useCallback(async () => {
    try {
      setLoading(true);
      if (authenticate.token && eventId) {
        const data = await getEvent(authenticate.token, eventId);

        setEvent(data?.event || null);
        setEventRecoil(data?.event || null);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticate.token, eventId]);

  useEffect(() => {
    getEventRequest();
  }, [getEventRequest]);

  const getAreas = useCallback(async () => {
    try {
      // setLoadingData(true);
      if (authenticate.token && eventId) {
        const result = await getEventAreasCode(authenticate.token, eventId);

        setAreas(result?.areas || null);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    } finally {
      // setLoadingData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticate.token, eventId]);

  useEffect(() => {
    getAreas();
  }, [getAreas]);

  const getData = useCallback(
    async (search?: string) => {
      try {
        if (!search) {
          setLoadingData(true);
        }
        if (authenticate.token && eventId) {
          const result = await getUsers(authenticate.token, eventId, search);

          setData(result?.data || null);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error);
      } finally {
        setLoadingData(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [authenticate.token, eventId]
  );

  useEffect(() => {
    getData(search);
  }, [getData, search]);

  const handleSearch = (search: string) => {
    setSearch(search);
  };

  const handleSubmitAddUser = async (values: any) => {
    try {
      setLoadingData(true);
      if (authenticate.token && eventId) {
        setModalAddUser(false);
        await addUser(authenticate.token, eventId, values);

        getData();

        // setData(result?.data || null);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
      setLoadingData(false);
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    }
  };

  const handleDeleteUser = async (userId: number) => {
    try {
      setLoadingData(true);
      if (authenticate.token && eventId) {
        await deleteUser(authenticate.token, eventId, userId);

        getData();

        // setData(result?.data || null);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
      setLoadingData(true);
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    }
  };

  const handleDeleteUserConfirm = (user: any) => {
    setModalConfirm((prev) => ({
      ...prev,
      message: `O usuário ${user.name} será removido e não terá mais acesso ao evento. Deseja continuar?`,
      open: true,
      onConfirm: () => {
        setModalConfirm((prev) => ({
          ...prev,
          open: false,
        }));

        handleDeleteUser(user.userId);
      },
    }));
  };

  const handleEditUser = (user: any) => {
    setModalAddUser(true);
    setEditUser({
      ...user,
      areas: user?.areas?.length ? user?.areas?.split(',')?.map(Number) : [],
    });
  };

  return (
    <React.Fragment>
      <StyledRoot>
        <Card>
          <div style={{ width: '100%' }}>
            <DetailsInfo
              isLoading={isLoading || isLoadingData}
              sections={[
                {
                  rows: [
                    {
                      key: 'name',
                      label: 'Nome',
                    },
                  ],
                  data: event,
                  title: 'Evento',
                },
              ]}
              actions={[
                {
                  icon: <PersonAddAlt1Icon />,
                  onClick: () => setModalAddUser(true),
                  tooltip: 'Adicionar usuário',
                },
              ]}
            />

            {!isLoading && !isLoadingData && (
              <ListUsers
                data={data}
                areas={areas}
                onSearch={handleSearch}
                handleDelete={handleDeleteUserConfirm}
                handleEdit={handleEditUser}
              />
            )}
          </div>
        </Card>
      </StyledRoot>

      <ModalAddUser
        open={modalAddUser}
        areas={areas}
        defaultValues={editUser}
        handleClose={() => {
          setModalAddUser(false);
          setEditUser(null);
        }}
        onSubmit={handleSubmitAddUser}
      />

      <ConfirmModal
        open={modalConfirm.open}
        handleClose={() => {
          setModalConfirm((prev) => {
            return {
              ...prev,
              open: false,
            };
          });
        }}
        handleCancel={modalConfirm.onCancel}
        handleConfirm={modalConfirm.onConfirm}
        confirmLabel={modalConfirm.confirmLabel}
        title={modalConfirm.title}
        message={modalConfirm.message}
        cancelLabel={modalConfirm.cancelLabel}
      />
    </React.Fragment>
  );
};

export default Users;

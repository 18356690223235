import React, { ReactElement, useEffect, useState } from 'react';

import { IconButton, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { useDebounce } from '../../hooks/useDebounce';

interface InputSearchProps {
  onChange: (value: string) => void;
}

const InputSearch: React.FC<InputSearchProps> = ({
  onChange,
}): ReactElement => {
  const [search, setSearch] = useState('');

  const searchDebounce = useDebounce(search, 500);

  useEffect(() => {
    onChange(searchDebounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDebounce]);

  const handleClickSearch = () => {
    if (search) {
      setSearch('');
    }
  };

  return (
    <TextField
      variant="outlined"
      placeholder="Nome do atleta"
      style={{ width: 420 }}
      InputLabelProps={{
        style: {
          fontSize: 16,
          textTransform: 'uppercase',
          background: '#fff',
          paddingRight: 5,
          color: '#000',
        },
        shrink: true,
      }}
      InputProps={{
        endAdornment: (
          <IconButton onClick={handleClickSearch} edge="end">
            {search ? <CloseIcon /> : <SearchIcon />}
          </IconButton>
        ),
      }}
      value={search}
      onChange={(e) => {
        setSearch(e.target.value);
      }}
    />
  );
};

export default InputSearch;

import styled from 'styled-components';

export const StyleNameArea = styled('div')({
  display: 'flex',
  gap: 8,

  '&:hover > div': {
    // opacity: '1 !important',
  },
});

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface Props {
  open: boolean;
  message: string;
  title: string;
  confirmColor?: string;
  cancelLabel?: string;
  handleClose: () => void;
  handleConfirm: () => void;
  handleCancel?: () => void;
  onAction?: () => void;
  actionLabel?: string;
  confirmLabel?: string;
}

const ConfirmModal: React.FC<Props> = ({
  open,
  message,
  title,
  cancelLabel,
  handleClose,
  handleConfirm,
  handleCancel,
  onAction,
  actionLabel,
  confirmLabel,
}): React.ReactElement => {
  return (
    <Dialog
      open={open}
      onClose={handleCancel ? undefined : handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {onAction && (
          <Button disableRipple style={{ color: '#222' }} onClick={onAction}>
            {actionLabel || 'Cancelar'}
          </Button>
        )}
        {handleCancel && (
          <Button
            disableRipple
            style={{ color: '#222' }}
            onClick={handleCancel}
            variant="outlined"
          >
            {cancelLabel || 'Cancelar'}
          </Button>
        )}
        <Button
          disableRipple
          onClick={handleConfirm}
          autoFocus
          variant="contained"
        >
          {confirmLabel || 'Confirmar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;

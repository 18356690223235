/* eslint-disable no-continue */
/* eslint-disable no-console */
/* eslint-disable no-await-in-loop */
/* eslint-disable camelcase */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import List from 'rc-virtual-list';

import PrintIcon from '@mui/icons-material/PrintOutlined';

import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { jsPDF as JsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import FightItem from './FightItem';
import FightPresentation from './FightPresentation';
import { compareStrings } from '../../../utils/string';
import { sortArrayV2 } from '../../../utils/fight';
import useLoading from '../../../recoil/hooks/loading';
import { StyleNameArea } from './styles';

interface AreasFightsProps {
  eventAreas?: any[];
  numberDays: number;
  filters: any;
  daySelected: string;
  reasonDesclassified?: any[];
  hasFiltersApplied: boolean;
  search?: string;
  statusFilter?: {
    oneAthlete: boolean;
    pending: boolean;
    programed: boolean;
    completed: boolean;
    canceled: boolean;
    in_progress: boolean;
  };
  redirect?: (area: any, item: any) => void;
  handleChangeAthletes?: (athlete: any, origin: any) => void;
  handleChangeFight?: (fight: any, origin: any, fightOver?: any) => void;
  handleChangeBroadcast?: (area: string, active: boolean) => Promise<void>;
  handleChangeFightArea?: (fight: any, area: any, fightOver?: any) => void;
}

const AreasFights: React.FC<AreasFightsProps> = ({
  eventAreas,
  numberDays,
  reasonDesclassified,
  daySelected,
  filters,
  search,
  hasFiltersApplied,
  statusFilter,
  redirect,
  handleChangeAthletes,
  handleChangeFight,
  handleChangeBroadcast,
  handleChangeFightArea,
}): ReactElement => {
  const [areasObject, setAreasObject] = useState<any[]>([]);

  const { setLoadingModal } = useLoading();

  const [days, setDays] = useState<number[]>([]);

  const applySearch = useCallback(
    (items: any[]) => {
      if (!search) {
        return items;
      }

      return items.filter((item) => {
        let hasItem = false;

        for (const athlete of item.athletes) {
          if (compareStrings(athlete.nameAthlete, search)) {
            hasItem = true;
            break;
          }
        }

        return hasItem;
      });
    },
    [search]
  );

  const applyStatusFilters = useCallback(
    (items: any[]) => {
      const status: any[] = [];

      if (!statusFilter) {
        return items;
      }

      if (statusFilter?.pending) {
        status.push('pending');
      }

      if (statusFilter?.programed) {
        status.push('programed');
      }

      if (statusFilter?.completed) {
        status.push('completed');
      }

      if (statusFilter?.canceled) {
        status.push('canceled');
      }

      if (statusFilter?.in_progress) {
        status.push('in_progress');
      }

      return items.filter((item) => {
        if (statusFilter?.oneAthlete) {
          return (
            item.category?.numberFights === 0 && status.includes(item.status)
          );
        }
        return status.includes(item.status);
      });
    },
    [statusFilter]
  );

  useEffect(() => {
    const newDays: number[] = [];

    const newAreas = eventAreas?.map((item) => {
      newDays.push(item.day);

      const fights: any[] = [];

      item.categories.forEach((category: any) => {
        category.fights.forEach((fight: any) => {
          fights.push({
            ...fight,
            category,
          });
        });
      });

      return {
        id: `${item.day}-${item.order}`,
        day: item.day,
        areaId: item.id,
        uuid: item.uuid,
        name: item.name,
        broadcast: item.broadcast,
        categories: [],
        fights,
      };
    });

    setDays([...new Set(newDays)].sort());

    setAreasObject(newAreas || []);
  }, [eventAreas, numberDays]);

  const filterByDay = (items: any[], day: number): any[] => {
    return items.filter((item) => item.day === day);
  };

  const renderObjectDay = useMemo(() => {
    if (typeof daySelected !== 'number') {
      return [days[0]];
    }

    return days.filter((item) => Number(item) === Number(daySelected));
  }, [days, daySelected]);

  const applyFilters = useCallback(
    (items: any) => {
      if (
        filters.category ||
        filters.gender ||
        filters.track ||
        filters.weight ||
        filters.typeCategory ||
        filters.typeCategoryMultiple?.length ||
        filters.categoryMultiple?.length ||
        filters.genderMultiple?.length ||
        filters.weightMultiple?.length ||
        filters.trackMultiple?.length
      ) {
        const itemsFiltered = items.filter((item: any) => {
          let avaliable = true;
          if (
            filters.category &&
            !item?.category?.categoryName?.includes(filters.category)
          ) {
            avaliable = false;
          }
          if (
            filters.gender &&
            !item?.category?.categoryName?.includes(filters.gender)
          ) {
            avaliable = false;
          }
          if (
            filters.weight &&
            !item?.category?.categoryName?.includes(filters.weight)
          ) {
            avaliable = false;
          }
          if (
            filters.track &&
            !item?.category?.categoryName?.includes(filters.track)
          ) {
            avaliable = false;
          }
          if (
            filters.typeCategory &&
            item?.category?.typeCategory !== filters.typeCategory
          ) {
            avaliable = false;
          }

          if (
            filters.typeCategoryMultiple?.length &&
            !filters.typeCategoryMultiple.includes(item?.category?.typeCategory)
          ) {
            avaliable = false;
          }

          if (
            filters.categoryMultiple?.length &&
            !filters.categoryMultiple.find((category: string) =>
              item?.category?.categoryName?.includes(category)
            )
          ) {
            avaliable = false;
          }

          if (
            filters.genderMultiple?.length &&
            !filters.genderMultiple.find((gender: string) =>
              item?.category?.categoryName?.includes(gender)
            )
          ) {
            avaliable = false;
          }

          if (
            filters.weightMultiple?.length &&
            !filters.weightMultiple.find((weight: string) =>
              item?.category?.categoryName?.includes(weight)
            )
          ) {
            avaliable = false;
          }

          if (
            filters.trackMultiple?.length &&
            !filters.trackMultiple.find((track: string) =>
              item?.category?.categoryName?.includes(track)
            )
          ) {
            avaliable = false;
          }

          return avaliable;
        });

        return itemsFiltered;
      }

      return items;
    },
    [filters]
  );

  const [athleteDrag, setAthleteDrag] = useState<any>(null);

  const [fightDrag, setFightDrag] = useState<any>(null);

  const [fightOver, setFightOver] = useState<any>(null);

  const renderFights = useCallback(
    (item: any) => {
      const fightsFiltered = applyFilters(item.fights);

      const itemsSearch = applySearch(fightsFiltered);

      const result = applyStatusFilters(itemsSearch);

      return sortArrayV2(result);
    },
    [applyFilters, applySearch, applyStatusFilters]
  );

  const handleChange = async (event: any, item: any) => {
    const active = event.target.checked;

    if (handleChangeBroadcast) {
      handleChangeBroadcast?.(item.uuid, active);
    }
  };

  const allowDrop = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const handleDrop = (area: any) => (event: React.DragEvent) => {
    event.stopPropagation();

    if (!handleChangeFightArea) {
      return;
    }

    if (athleteDrag) {
      return;
    }

    if (area.areaId === fightDrag?.category?.eventAreaId) {
      return;
    }

    handleChangeFightArea(fightDrag, area);
  };

  const refs = useRef<any>([React.createRef(), React.createRef()]);

  const handleExportPDF = async (index: number) => {
    setLoadingModal(true);

    setTimeout(async () => {
      const element = refs?.current?.[index]?.current;
      if (!element) return;

      const canvas = await html2canvas(element, {
        scale: 1,
        useCORS: true,
      });

      const imgData = canvas.toDataURL('image/jpeg', 0.7);

      const pdf = new JsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: 'a4',
      });

      // const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      const imgWidth = canvas.width * 0.5; // Tamanho real do canvas
      const imgHeight = canvas.height * 0.5;

      let position = 0;
      let heightLeft = imgHeight;

      // Converte a imagem para o mesmo tamanho do canvas no PDF
      pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft > 0) {
        position -= pageHeight;

        pdf.addPage();
        pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(`${index}.pdf`);

      setLoadingModal(false);
    }, 100);
  };

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        gap: 12,
      }}
    >
      <div style={{ width: '100%' }}>
        {renderObjectDay.map((day, index) => (
          <React.Fragment key={`${index}-${day}`}>
            <div
              style={{
                width: '100%',
                overflowX: 'auto',
                display: 'flex',
                gap: 18,
              }}
            >
              {filterByDay(areasObject, day).map((item, index) => {
                const fights = renderFights(item);

                console.log('hasFiltersApplied', hasFiltersApplied);

                if (!fights.length && hasFiltersApplied) {
                  return <></>;
                }
                return (
                  <div
                    key={index}
                    style={{
                      width: 400,
                      maxWidth: '95vw',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: 8,
                        alignItems: 'center',
                      }}
                    >
                      <StyleNameArea>
                        {item.name}

                        <div
                          style={{
                            cursor: 'pointer',
                            opacity: 0,
                            display: 'none',
                            transition: '0.3s',
                          }}
                          onClick={() => handleExportPDF(index)}
                        >
                          <PrintIcon />
                        </div>
                      </StyleNameArea>
                      {handleChangeBroadcast && (
                        <div>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch defaultChecked={item.broadcast} />
                              }
                              label="Transmitir"
                              labelPlacement="start"
                              onChange={(event) => handleChange(event, item)}
                            />
                          </FormGroup>
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        border: '1px solid #ccc',
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                        borderRadius: 8,
                        width: 400,
                        maxWidth: '95vw',
                        minHeight: 100,
                        marginTop: 12,
                        boxSizing: 'border-box',
                        gap: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 500,
                        overflowY: 'auto',
                        marginBottom: 8,
                      }}
                      onDragOver={
                        fightDrag &&
                        fightDrag?.category.eventAreaId !== item.areaId
                          ? allowDrop
                          : undefined
                      }
                      onDrop={handleDrop(item)}
                    >
                      <div ref={refs.current[index]}>
                        <List
                          data={fights}
                          height={466}
                          itemHeight={194}
                          itemKey="id"
                          style={{ padding: 16, boxSizing: 'border-box' }}
                        >
                          {(fight) => (
                            <React.Fragment key={fight.id}>
                              {fight.type === 'presentation' ? (
                                <FightPresentation fight={fight} />
                              ) : (
                                <FightItem
                                  fight={fight}
                                  area={item}
                                  reasons={reasonDesclassified}
                                  handleChangeAthletes={handleChangeAthletes}
                                  handleChangeFight={handleChangeFight}
                                  handleChangeFightArea={handleChangeFightArea}
                                  athleteDrag={athleteDrag}
                                  setAthleteDrag={setAthleteDrag}
                                  fightDrag={fightDrag}
                                  setFightDrag={setFightDrag}
                                  fightOver={fightOver}
                                  setFightOver={setFightOver}
                                  handleClick={(fight) =>
                                    redirect?.(item, fight.category)
                                  }
                                />
                              )}

                              <div data-page-break></div>
                            </React.Fragment>
                          )}
                        </List>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default AreasFights;

/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
import React, { ReactElement, useMemo, useState } from 'react';

import { TablePagination, Tooltip } from '@mui/material';
import {
  mapAthleteStatus,
  mapStage,
  mapStatus,
  sortArrayV2,
} from '../../../utils/fight';
import {
  formatOnlyHour,
  formatSecondsToMinutesAndSeconds,
} from '../../../utils/date';
import { StyledContentPagination } from './styles';
import {
  matchCategoryExactPhrase,
  matchCategoryName,
  stringMatches,
} from '../../../utils/string';

const Athlete: React.FC<{ athlete: any; reasonDesclassified?: any[] }> = ({
  athlete,
  reasonDesclassified,
}) => {
  return (
    <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
      <Tooltip title={mapAthleteStatus(athlete?.status)?.label} arrow>
        <div
          style={{
            width: 12,
            height: 12,
            background: mapAthleteStatus(athlete?.status)?.color,
            borderRadius: 12,
          }}
        />
      </Tooltip>
      <div>{athlete?.nameAthlete || '-'}</div>
      <div style={{ color: '#999' }}>{athlete?.gym || ''}</div>

      {athlete?.result?.win && (
        <div style={{ color: '#8bc34a' }}>
          Vitoria por {athlete?.result?.typeVictoryDescription}
          {Boolean(athlete?.result?.timeFight) && (
            <>
              {' '}
              -{' '}
              {formatSecondsToMinutesAndSeconds(
                athlete?.result?.timeFight || 0
              )}
            </>
          )}
        </div>
      )}

      {/* {Boolean(athlete?.reasonDesclassificationCode) && (
        <div style={{ color: '#e74c3c' }}>
          {reasonDesclassified?.find(
            (item) => item.id === athlete?.reasonDesclassificationCode
          )?.description || ''}
        </div>
      )} */}
    </div>
  );
};

interface AreasFightsV2Props {
  eventAreas?: any[];
  numberDays: number;
  filters: any;
  daySelected: string;
  reasonDesclassified?: any[];
  hasFiltersApplied: boolean;
  search?: string;
  statusFilter?: {
    oneAthlete: boolean;
    pending: boolean;
    programed: boolean;
    completed: boolean;
    canceled: boolean;
    in_progress: boolean;
  };
  redirect?: (area: any, item: any) => void;
  handleChangeAthletes?: (athlete: any, origin: any) => void;
  handleChangeFight?: (fight: any, origin: any, fightOver?: any) => void;
  handleChangeBroadcast?: (area: string, active: boolean) => Promise<void>;
  handleChangeFightArea?: (fight: any, area: any, fightOver?: any) => void;
}

const AreasFightsV2: React.FC<AreasFightsV2Props> = ({
  eventAreas,
  numberDays,
  reasonDesclassified,
  daySelected,
  filters,
  search,
  hasFiltersApplied,
  statusFilter,
  redirect,
  handleChangeAthletes,
  handleChangeFight,
  handleChangeBroadcast,
  handleChangeFightArea,
}): ReactElement => {
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [page, setPage] = useState(0);

  const fights = useMemo(() => {
    const newFights: any[] = [];

    eventAreas?.forEach((item) => {
      if (!filters?.area || item.id === filters?.area) {
        if (Number(item.day) === Number(filters?.day || 0)) {
          for (const category of item.categories) {
            const hasGenderFilter =
              !filters?.gender ||
              matchCategoryExactPhrase(category?.categoryName, filters?.gender);

            const hasCategoryFilter =
              !filters?.category ||
              matchCategoryExactPhrase(
                category?.categoryName,
                filters?.category
              );

            const hasTrackFilter =
              !filters?.track ||
              matchCategoryExactPhrase(category?.categoryName, filters?.track);

            const hasWeightFilter =
              !filters?.weight ||
              matchCategoryExactPhrase(category?.categoryName, filters?.weight);

            if (
              !hasGenderFilter ||
              !hasCategoryFilter ||
              !hasTrackFilter ||
              !hasWeightFilter
            ) {
              // eslint-disable-next-line no-continue
              continue;
            }

            category.fights.forEach((fight: any) => {
              let hasFilterApplied = true;

              if (
                filters?.name &&
                !fight.athletes?.find((item: any) =>
                  stringMatches(item.nameAthlete, filters.name)
                )
              ) {
                hasFilterApplied = false;
              }

              if (
                filters?.gym &&
                !fight.athletes?.find((item: any) => item.gym === filters.gym)
              ) {
                hasFilterApplied = false;
              }

              if (filters?.status && fight.status !== filters?.status) {
                hasFilterApplied = false;
              }

              if (hasFilterApplied) {
                newFights.push({
                  ...fight,
                  category,
                  area: item,
                });
              }
            });
          }
        }
      }
    });

    return sortArrayV2(newFights);
  }, [eventAreas, filters]);

  const paginateFights = (
    fights: any[],
    page: number,
    itemsPerPage: number
  ) => {
    const start = page * itemsPerPage;
    const end = start + itemsPerPage;

    return fights.slice(start, end);
  };

  const getAthlete = (athletes: any[], order: number): any => {
    return athletes.find((item) => item.order === order);
  };

  const renderItems = useMemo(() => {
    return paginateFights(fights, page, itemsPerPage);
  }, [fights, page, itemsPerPage]);

  return (
    <div>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          gap: 12,
        }}
      >
        <div style={{ width: '100%' }}>
          {renderItems?.map((fight, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 8,
                gap: 4,
              }}
            >
              <div style={{ display: 'flex', gap: 5 }}>
                <div>{formatOnlyHour(fight.date)}</div>

                <div style={{ color: '#ccc' }}>|</div>

                <div>
                  {mapStage(fight.stage)}{' '}
                  {!['final', 'bronze'].includes(fight.stage)
                    ? fight.order + 1
                    : ''}
                </div>

                <div style={{ color: '#ccc' }}>|</div>

                <div>{fight.area ? `${fight.area?.name}` : ''}</div>

                <div style={{ color: '#ccc' }}>|</div>

                <div style={{ fontWeight: 'bold' }}>
                  {fight?.category?.categoryName}
                </div>
              </div>

              <div
                style={{
                  background: '#eee',
                  borderRadius: 8,
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '8px 16px',
                  gap: 4,
                  boxSizing: 'border-box',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                  transition: '0.3s',
                }}
                onClick={() => redirect?.(fight.area, fight.category)}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4,
                    boxSizing: 'border-box',
                  }}
                >
                  <Athlete
                    athlete={getAthlete(fight?.athletes, 0)}
                    reasonDesclassified={reasonDesclassified}
                  />

                  <Athlete
                    athlete={getAthlete(fight?.athletes, 1)}
                    reasonDesclassified={reasonDesclassified}
                  />
                </div>
                <div
                  style={{
                    border: '1px solid #ccc',
                    padding: '4px 8px',
                    boxSizing: 'border-box',
                    borderRadius: 8,
                    fontSize: 12,
                    height: 32,
                    width: 138,
                    fontWeight: 'bold',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: mapStatus(fight?.status)?.background,
                    color: mapStatus(fight?.status)?.color,
                  }}
                >
                  {mapStatus(fight?.status)?.label}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {fights.length > 0 && (
        <StyledContentPagination>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            labelRowsPerPage="Itens por página"
            labelDisplayedRows={(item) =>
              `${item.from} - ${item.to} de ${item.count}`
            }
            component="div"
            count={fights.length || 0}
            rowsPerPage={itemsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={(event) =>
              setItemsPerPage(Number(event.target.value))
            }
          />
        </StyledContentPagination>
      )}
    </div>
  );
};

export default AreasFightsV2;

/* eslint-disable no-restricted-syntax */
export const getLatestStageFights = (fights: any[]): any[] => {
  if (!fights || !Array.isArray(fights)) {
    return [];
  }

  const stageOrder = [
    'knockout',
    'eighth-final',
    'quarter-final',
    'semifinal',
    'bronze',
    'final',
  ];

  let minStage: any = null;
  for (const fight of fights) {
    if (
      minStage === null ||
      stageOrder.indexOf(fight.stage) < stageOrder.indexOf(minStage)
    ) {
      minStage = fight.stage;
    }
  }

  return fights.filter((fight: any) => fight.stage === minStage);
};

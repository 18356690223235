import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import {
  StyleBox,
  StyleContent,
  StyleContentButton,
  StyledContentInput,
} from './styles';
import { maskPhone } from '../../../../utils/phone';
import { removeMask } from '../../../../utils/mask';

interface Area {
  id: number;
  name: string;
  day: number;
}
interface Props {
  open: boolean;
  areas: Area[];
  defaultValues?: ValuesForm;
  handleClose: () => void;
  onSubmit: (values: ValuesForm) => void;
}

interface ValuesForm {
  phone: string;
  name: string;
  role: string;
  areas: number[];
}

const initialValues: ValuesForm = {
  phone: '',
  name: '',
  role: '',
  areas: [],
};

export default function ModalAddUser({
  open,
  areas,
  handleClose,
  onSubmit,
  defaultValues,
}: Props): React.ReactElement {
  // const formik = useFormik({
  //   initialValues: initialUsersFormValues,
  //   validationSchema,
  //   validateOnBlur: false,
  //   onSubmit: console.log,
  // });

  const [values, setValues] = React.useState<ValuesForm>(initialValues);

  const [isEditing, setIsEditing] = React.useState(false);

  React.useEffect(() => {
    if (!open) {
      setValues(initialValues);
    }
  }, [open]);

  React.useEffect(() => {
    if (defaultValues) {
      setValues({
        ...defaultValues,
        phone: maskPhone(defaultValues.phone),
        // areas: defaultValues.areas,
      });

      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [defaultValues]);

  const handleChangePhone = (event: any): void => {
    const value = maskPhone(event.target.value);

    setValues({ ...values, phone: value });
  };

  const handleChange = (event: any): void => {
    const { value } = event.target;

    setValues({ ...values, [event.target.name]: value });
  };

  const handleSubmit = (event: any) => {
    event?.preventDefault();

    onSubmit({
      ...values,
      phone: removeMask(values.phone),
      areas: values.role !== 'K' ? [] : values.areas,
    });
  };

  React.useEffect(() => {
    setValues((prev) => ({
      ...prev,
      areas: defaultValues?.areas || [],
    }));
  }, [values.role, defaultValues?.areas]);

  const hasOnlyOneDay = React.useMemo(() => {
    if (!areas?.length) return true;

    const firstDay = areas[0].day;

    const allSame = areas.every((item) => item.day === firstDay);

    return allSame;
  }, [areas]);

  const formatAreaName = (area: Area): string => {
    if (hasOnlyOneDay) {
      return `${area.name}`;
    }
    return `Dia ${area.day + 1} - ${area.name}`;
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <StyleBox>
          <Typography variant="h6" component="h2">
            {isEditing ? 'Editar usuário' : 'Adicionar usuário'}
          </Typography>
          <form onSubmit={handleSubmit}>
            <StyleContent>
              {/* <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Recurso em desenvolvimento
            </Typography> */}

              <StyledContentInput>
                <TextField
                  fullWidth
                  id="name"
                  variant="outlined"
                  name="name"
                  label="Nome"
                  required
                  disabled={isEditing}
                  InputLabelProps={{
                    style: {
                      fontSize: 16,
                      textTransform: 'uppercase',
                      background: '#fff',
                      paddingRight: 5,
                      color: '#000',
                    },
                    shrink: true,
                  }}
                  value={values.name}
                  onChange={(event) => handleChange(event)}
                  // error={formik.touched.name && Boolean(formik.errors.name)}
                  // helperText={formik.touched.name && formik.errors.name}
                />
              </StyledContentInput>

              <StyledContentInput>
                <TextField
                  fullWidth
                  disabled={isEditing}
                  id="phone"
                  variant="outlined"
                  name="phone"
                  required
                  label="Celular"
                  InputLabelProps={{
                    style: {
                      fontSize: 16,
                      textTransform: 'uppercase',
                      background: '#fff',
                      paddingRight: 5,
                      color: '#000',
                    },
                    shrink: true,
                  }}
                  value={values.phone}
                  onChange={(event) => handleChangePhone(event)}
                  // error={formik.touched.phone && Boolean(formik.errors.phone)}
                  // helperText={formik.touched.phone && formik.errors.phone}
                />
              </StyledContentInput>

              <StyledContentInput>
                <TextField
                  fullWidth
                  id="role"
                  variant="outlined"
                  name="role"
                  label="Perfil"
                  required
                  InputLabelProps={{
                    style: {
                      fontSize: 16,
                      textTransform: 'uppercase',
                      background: '#fff',
                      paddingRight: 5,
                      color: '#000',
                    },
                    shrink: true,
                  }}
                  value={values.role}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  // error={
                  //   formik.touched.role &&
                  //   Boolean(formik.errors.role)
                  // }
                  // helperText={
                  //   formik.touched.role && formik.errors.role
                  // }
                  select
                >
                  <MenuItem value="S">Staff</MenuItem>
                  <MenuItem value="M">Mesário</MenuItem>
                  <MenuItem value="C">Checkin</MenuItem>
                  <MenuItem value="P">Premiação</MenuItem>
                  {/* <MenuItem value="E">Estatisticas</MenuItem> */}
                  <MenuItem value="K">Coordenador de área</MenuItem>
                </TextField>
              </StyledContentInput>

              {values.role === 'K' && (
                <StyledContentInput>
                  <FormControl fullWidth>
                    <InputLabel
                      shrink
                      style={{
                        fontSize: 16,
                        textTransform: 'uppercase',
                        background: '#fff',
                        paddingRight: 5,
                        paddingLeft: 5,
                        color: '#000',
                      }}
                    >
                      Áreas
                    </InputLabel>
                    <Select
                      multiple
                      fullWidth
                      required={values.role === 'K'}
                      disabled={values.role !== 'K'}
                      value={values.areas}
                      onChange={handleChange}
                      name="areas"
                      defaultValue={defaultValues?.areas || []}
                      input={<OutlinedInput label="Peso" name="areas" />}
                      renderValue={(selected) =>
                        selected
                          .map((item) => {
                            const area = areas.find((area) => area.id === item);

                            if (area) {
                              return formatAreaName(area);
                            }

                            return '';
                          })
                          ?.join(', ')
                      }
                    >
                      {areas?.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          <Checkbox
                            checked={values?.areas?.includes(item.id)}
                          />
                          <ListItemText primary={formatAreaName(item)} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </StyledContentInput>
              )}
            </StyleContent>
            <StyleContentButton>
              <Button
                color="primary"
                variant="outlined"
                disableRipple
                onClick={() => handleClose()}
                style={{
                  letterSpacing: 1,
                  fontSize: 14,
                  minWidth: 150,
                }}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disableRipple
                style={{
                  letterSpacing: 1,
                  fontSize: 14,
                  minWidth: 150,
                }}
              >
                {isEditing ? 'Editar' : 'Adicionar'}
              </Button>
            </StyleContentButton>
          </form>
        </StyleBox>
      </Fade>
    </Modal>
  );
}

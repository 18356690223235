import styled from 'styled-components';

export const StyledItem = styled('div')<{ status: string }>(
  ({ status, onClick }) => ({
    transition: '0.4s',
    padding: 16,

    boxSizing: 'border-box',
    borderRadius: 8,

    background: ['completed', 'bye'].includes(status) ? '#dcedc8' : '#fff',
    '&:hover':
      !['completed', 'bye'].includes(status) && onClick
        ? {
            cursor: 'pointer',
            background: '#f1f1f1',
          }
        : undefined,
  })
);

export const StyleNameArea = styled('div')({
  display: 'flex',
  gap: 8,

  '&:hover > div': {
    // opacity: '1 !important',
    // display: 'block',
  },
});

/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
import { Button, CircularProgress, Drawer } from '@mui/material';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';

import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { getPublicBracket } from '../../api/events';
import FightItem from '../ShowAreas/AreasFights/FightItem';
import { sortArrayV2 } from '../../utils/fight';

interface DrawerDetailsBracketProps {
  open: boolean;
  category: string;
  handleClose: () => void;
  handleViewBracket?: () => void;
}

const DrawerDetailsBracket: React.FC<DrawerDetailsBracketProps> = ({
  open,
  category,
  handleClose,
  handleViewBracket,
}): ReactElement => {
  const { eventId } = useParams();

  const [data, setData] = useState<any>(null);

  const [isLoading, setLoading] = useState<any>(null);

  const getBracketData = useCallback(async () => {
    try {
      setLoading(true);
      if (eventId && category) {
        const data = await getPublicBracket(eventId, category);

        setData(data);

        // setEvent(data?.event);
        //
        // setCategory(data?.category);

        // setEventAreasCategory(data?.fights || []);
      }
    } catch (error) {
      toast(
        'Não foi possível executar essa ação. Por favor, tente novamente!',
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }, [category, eventId]);

  useEffect(() => {
    getBracketData();
  }, [getBracketData]);

  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <div
        style={{
          padding: 16,
          boxSizing: 'border-box',
          position: 'absolute',
          cursor: 'pointer',
        }}
        onClick={handleClose}
      >
        <CloseIcon htmlColor="#f00" />
      </div>

      {isLoading && (
        <div
          style={{
            width: 490,
            maxWidth: '100vw',
            display: 'flex',
            minHeight: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}

      {!isLoading && (
        <div
          style={{
            padding: 16,
            boxSizing: 'border-box',
            width: 490,
            maxWidth: '100vw',
            gap: 8,
          }}
        >
          <div
            style={{
              fontFamily: 'Lato',
              fontWeight: 500,
              fontSize: '1.25rem',
              lineHeight: 1.6,
              padding: '24px',
              boxSizing: 'border-box',
            }}
          >
            {data?.category?.categoryName}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: 24,
            }}
          >
            <Button variant="contained" onClick={handleViewBracket}>
              Ver chave
            </Button>
          </div>
          {sortArrayV2(data?.fights)?.map((fight: any) => (
            <div key={fight.id}>
              <FightItem fight={fight} />
            </div>
          ))}
        </div>
      )}
    </Drawer>
  );
};

export default DrawerDetailsBracket;

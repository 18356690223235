/* eslint-disable no-plusplus */
import React, { ReactElement, useEffect, useRef, useState } from 'react';

import AreasFights from './AreasFights';
import { useQuery } from '../../hooks/useQuery';
import CheckinLegend from '../CheckinLegend';
import { StyledContentScroll } from './styles';

interface CheckinShareAreasProps {
  categories: any[];
  eventAreas?: any[];
  areas: number;
  numberDays: number;
  reasonDesclassified?: any[];
}

const CheckinShareAreas: React.FC<CheckinShareAreasProps> = ({
  categories,
  eventAreas,
  areas,
  numberDays,
  reasonDesclassified,
}): ReactElement => {
  const query = useQuery();
  const daySelected: string = query.get('day') || '0';

  const areasSelected: number[] | undefined = query.get('areas')
    ? query.get('areas')?.split(',').map(Number)
    : [];

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [direction, setDirection] = useState(1);
  const speed = 1;

  useEffect(() => {
    let animationFrameId: number;

    const animation = () => {
      const scrollContainer = scrollContainerRef.current;

      const scroll = () => {
        if (scrollContainer) {
          scrollContainer.scrollLeft += direction * speed;

          if (
            scrollContainer.scrollLeft + scrollContainer.clientWidth >=
              scrollContainer.scrollWidth ||
            scrollContainer.scrollLeft <= 0
          ) {
            setTimeout(() => {
              setDirection((prevDirection) => -prevDirection);
            }, 5000);
          } else {
            animationFrameId = requestAnimationFrame(scroll);
          }
        }
      };

      animationFrameId = requestAnimationFrame(scroll);
    };

    setTimeout(() => {
      animation();
    }, 5000);

    return () => cancelAnimationFrame(animationFrameId);
  }, [direction]);

  const filterByArea = (items: any[], areasSelected: number[]): any[] => {
    if (!areasSelected.length) {
      return items;
    }

    return items.filter((area) => areasSelected.includes(area.id));
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        padding: '8px 8px 0px',
        boxSizing: 'border-box',
      }}
    >
      <CheckinLegend
        items={[
          {
            color: '#66bb6b',
            label: 'Checkin realizado',
          },
          {
            color: '#9c27b0',
            label: 'Aguardando checkin',
          },
          {
            color: '#e74c3c',
            label: 'Desclassificado',
          },
        ]}
      />

      <StyledContentScroll ref={scrollContainerRef}>
        <AreasFights
          areas={areas}
          categories={categories}
          numberDays={numberDays}
          eventAreas={filterByArea(eventAreas || [], areasSelected || [])}
          daySelected={daySelected}
          reasonDesclassified={reasonDesclassified}
        />
      </StyledContentScroll>
    </div>
  );
};

export default CheckinShareAreas;

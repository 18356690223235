import React, { ReactElement } from 'react';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { StyledContentLogo, StyledLogo } from './styles';
import useAuthenticate from '../../recoil/hooks/authenticate';

import packageJson from '../../../package.json';

interface DrawerItem {
  label: string;
  path: string;
  icon: ReactElement;
}

interface Props {
  open: boolean;
  onClose: () => void;
  variant: 'temporary' | 'permanent' | 'persistent';
  options: DrawerItem[];
}

const DrawerMenu: React.FC<Props> = ({
  options,
  variant,
  open,
  onClose,
}): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();

  const { logout } = useAuthenticate();

  const isSelected = (path: string) => {
    if (location.pathname === '/dash' && location.pathname === path) {
      return true;
    }
    return path !== '/dash' && location.pathname.includes(path);
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      variant={variant}
      hideBackdrop={false}
    >
      <Box
        style={{ background: '#fff', height: '100%', display: 'flex' }}
        sx={{ width: 275 }}
        role="presentation"
        onClick={onClose}
      >
        <List
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <StyledContentLogo>
              <StyledLogo src="/images/ilutas.png" />
            </StyledContentLogo>

            {options?.map((item, index) => (
              <ListItem
                key={index}
                disablePadding
                onClick={() => navigate(item.path)}
              >
                <ListItemButton
                  style={{
                    background: isSelected(item.path) ? '#083061' : '#fff',
                    transition: '0.4s',
                  }}
                >
                  <ListItemIcon
                    style={{
                      transition: '0.4s',
                      color: isSelected(item.path) ? '#fff' : '#083061',
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.label}
                    style={{
                      transition: '0.4s',
                      color: isSelected(item.path) ? '#fff' : '#083061',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: 8,
              boxSizing: 'border-box',
              fontSize: 14,
              color: '#777',
            }}
          >
            v{packageJson.version}
          </div>

          <Divider />

          <ListItem disablePadding onClick={logout}>
            <ListItemButton>
              <ListItemIcon style={{ color: '#083061' }}>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Sair" style={{ color: '#083061' }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default DrawerMenu;

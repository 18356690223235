/* eslint-disable no-plusplus */
import React, { ReactElement } from 'react';

import { Tooltip } from '@mui/material';
import SportsMartialArtsIcon from '@mui/icons-material/SportsMartialArts';
import { StyledItem } from './styles';
import { formatOnlyHour } from '../../../../utils/date';
import { mapStatus, mapStatusFight } from '../../../../utils/fight';

interface FightPresentationProps {
  fight: any;
}

const FightPresentation: React.FC<FightPresentationProps> = ({
  fight,
}): ReactElement => {
  return (
    <StyledItem key={fight.id} status={fight.status}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
            }}
          >
            <Tooltip title={mapStatusFight(fight.status).label} arrow>
              <div
                style={{
                  width: 12,
                  height: 12,
                  background: mapStatusFight(fight.status).background,
                  borderRadius: 12,
                }}
              />
            </Tooltip>
            <div style={{ fontWeight: 'bold' }}>
              {fight.status === 'bye' ? 'XX:XX' : formatOnlyHour(fight.date)}
            </div>
            <div>Apresentação</div>
          </div>
          <div
            style={{
              fontSize: 12,
            }}
          >
            {fight.category?.categoryName}
          </div>
        </div>
        <div
          style={{
            border: '1px solid #ccc',
            padding: '4px 8px',
            boxSizing: 'border-box',
            borderRadius: 8,
            fontSize: 12,
            background: mapStatus(fight?.status)?.background,
            color: mapStatus(fight?.status)?.color,
          }}
        >
          {mapStatus(fight?.status)?.label}
        </div>
      </div>

      <div
        style={{
          margin: '4px 0px',
          borderRadius: 4,
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            background: '#ddd',
            padding: 8,
            boxSizing: 'border-box',
            height: 80,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 8,
          }}
        >
          <div style={{ display: 'flex', gap: 12 }}>
            <SportsMartialArtsIcon />
            <SportsMartialArtsIcon style={{ transform: 'rotate(90deg)' }} />
          </div>
          <div>Apresentação</div>
        </div>
      </div>
    </StyledItem>
  );
};

export default FightPresentation;

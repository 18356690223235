import React from 'react';
import { useFormik } from 'formik';
import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';

import {
  StyledContentButtons,
  StyledContentInputs,
  StyledContentTitle,
  StyledForm,
  StyledRoot,
  StyledTitle,
} from './styles';
import {
  EventFormProps,
  initialEventFormValues,
  validationSchema,
} from './schema';

interface Props {
  isLoading?: boolean;
  events: any[];
  handleSubmit: (event: EventFormProps) => void;
}

const SelectEvent: React.FC<Props> = ({
  isLoading,
  events,
  handleSubmit,
}): React.ReactElement => {
  const formik = useFormik({
    initialValues: initialEventFormValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleChangeEvent = (_event: any, value: any) => {
    formik.setFieldValue('event', value?.value || '');
  };

  return (
    <StyledRoot>
      <StyledContentTitle>
        <StyledTitle variant="h5" gutterBottom>
          Para continuar, selecione um evento
        </StyledTitle>
      </StyledContentTitle>
      <StyledForm onSubmit={formik.handleSubmit}>
        <StyledContentInputs>
          <Autocomplete
            options={events.map((event) => ({
              label: `${event.name} - ${event.startDate}`,
              value: event.keyEvent,
            }))}
            isOptionEqualToValue={(option, value) => {
              return value.value === option.value;
            }}
            fullWidth
            onChange={handleChangeEvent}
            onBlur={formik.handleBlur}
            noOptionsText="Nenhuma opção encontrada"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                id="event"
                variant="outlined"
                name="event"
                label="Evento"
                value={formik.values.event}
                error={formik.touched.event && Boolean(formik.errors.event)}
                helperText={formik.touched.event && formik.errors.event}
                InputLabelProps={{
                  style: {
                    fontSize: 16,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
              />
            )}
          />
        </StyledContentInputs>

        <StyledContentButtons>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            disableRipple
            type="submit"
            disabled={isLoading}
            style={{
              letterSpacing: 1,
              fontSize: 16,
            }}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Continuar'}
          </Button>
        </StyledContentButtons>
      </StyledForm>
    </StyledRoot>
  );
};

export default SelectEvent;

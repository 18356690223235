import React, { ReactElement } from 'react';

import {
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { StyledRoot } from './styles';

export interface DetailsSectionRowsProps {
  label: string;
  key: string;
  subkey?: string;
  isHtml?: boolean;
  ignoreEmptyValue?: boolean;
  transform?: (text: any) => string;
  transformBody?: (data: any) => string;
  transformIcon?: (text: any) => ReactElement;
}

export interface DetailsSectionProps {
  title?: string;
  onBack?: () => void;
  rows: DetailsSectionRowsProps[];
  data: any;
}

export interface ActionsProps {
  tooltip: string;
  icon: React.ReactElement;
  onClick: () => void;
}

interface Props {
  isLoading: boolean;
  noPadding?: boolean;
  sections: DetailsSectionProps[];
  actions?: ActionsProps[];
}

const DetailsInfo: React.FC<Props> = ({
  isLoading,
  sections,
  actions,
  noPadding,
}): React.ReactElement => {
  const handleValue = (
    data: any,
    key: DetailsSectionRowsProps
  ): string | ReactElement => {
    const value = key.subkey ? data?.[key.key]?.[key.subkey] : data?.[key.key];
    if (
      !key?.ignoreEmptyValue &&
      (value === undefined || value === null || value === '')
    ) {
      return '-';
    }

    if (key.isHtml) {
      return (
        <div
          style={{ padding: 5, boxSizing: 'border-box' }}
          dangerouslySetInnerHTML={{ __html: value.replaceAll('\n', '<br/>') }}
        />
      );
    }

    if (key.transformBody) {
      return key.transformBody(data);
    }

    if (key.transformIcon) {
      return key.transformIcon(value);
    }

    if (key.transform) {
      return key.transform(value);
    }

    return value;
  };
  return (
    <StyledRoot nopadding={noPadding ? true : undefined}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
          flex: 1,
        }}
      >
        {sections.map((item, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {item.onBack && (
                <IconButton onClick={item.onBack}>
                  <ArrowBackIcon />
                </IconButton>
              )}
              {item.title && <Typography variant="h6">{item.title}</Typography>}
            </div>

            {isLoading && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 32,
                  height: 150,
                  boxSizing: 'border-box',
                }}
              >
                <CircularProgress />
              </div>
            )}

            {!isLoading &&
              !!item.data &&
              item.rows?.map((row, indexRow) => (
                <div key={`${index}-${indexRow}`} style={{ color: '#555' }}>
                  {row.label}:{' '}
                  <span style={{ color: '#000' }}>
                    {handleValue(item.data, row)}
                  </span>
                </div>
              ))}
          </div>
        ))}
      </div>
      {!isLoading && Boolean(actions?.length) && (
        <div style={{ display: 'flex', gap: 8 }}>
          {actions?.map((item, index) => (
            <Tooltip key={index} title={item.tooltip} arrow>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#fff',
                  border: '1px solid #ccc',
                  borderRadius: 8,
                  padding: '4px 12px',
                  boxSizing: 'border-box',
                  gap: 16,
                  minWidth: 50,
                  width: 50,
                  height: 50,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  item.onClick();
                }}
              >
                {item.icon}
              </div>
            </Tooltip>
          ))}
        </div>
      )}
    </StyledRoot>
  );
};

export default DetailsInfo;

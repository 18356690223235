import api from '../services/api';

export const getAreas = async (
  token: string,
  eventId?: string
): Promise<any> => {
  const result = await api.get(
    `/api/areas?eventId=${eventId || ''}`,

    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

interface AddAreaRequest {
  name: string;
  description: string;
}

export const addArea = async (
  eventId: string,
  data: AddAreaRequest,
  token: string
): Promise<void> => {
  await api.post(`/api/events/${eventId}/areas`, data, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });
};

export const updateAreaBroadcast = async (
  eventId: string,
  areaId: string,
  active: boolean,
  token: string
): Promise<void> => {
  await api.post(
    `/api/events/${eventId}/areas/${areaId}/broadcast`,
    { active },
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );
};

export default {
  getAreas,
  addArea,
  updateAreaBroadcast,
};

/* eslint-disable camelcase */
import React, { ReactElement, useEffect, useState } from 'react';

import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

interface StatusFilterProps {
  inProgress?: boolean;
  defaultValue: {
    oneAthlete: boolean;
    pending: boolean;
    programed: boolean;
    completed: boolean;
    canceled: boolean;
    in_progress: boolean;
  };
  onChange: (values: any) => void;
}

const StatusFilter: React.FC<StatusFilterProps> = ({
  inProgress,
  defaultValue,
  onChange,
}): ReactElement => {
  const [values, setValues] = useState(defaultValue);

  useEffect(() => {
    onChange(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <React.Fragment>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              value={values.oneAthlete}
              checked={values.oneAthlete}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setValues({ ...values, oneAthlete: e.target.checked });
              }}
            />
          }
          label="Lutas com apenas 1 atleta na chave"
        />
      </FormGroup>

      {inProgress && (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                value={values.in_progress}
                checked={values.in_progress}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setValues({ ...values, in_progress: e.target.checked });
                }}
              />
            }
            label="Em andamento"
          />
        </FormGroup>
      )}

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              value={values.pending}
              checked={values.pending}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setValues({ ...values, pending: e.target.checked });
              }}
            />
          }
          label="A confirmar"
        />
      </FormGroup>

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              value={values.programed}
              checked={values.programed}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setValues({ ...values, programed: e.target.checked });
              }}
            />
          }
          label="Aguardando luta"
        />
      </FormGroup>

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              value={values.completed}
              checked={values.completed}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setValues({ ...values, completed: e.target.checked });
              }}
            />
          }
          label="Finalizadas"
        />
      </FormGroup>

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              value={values.canceled}
              checked={values.canceled}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setValues({ ...values, canceled: e.target.checked });
              }}
            />
          }
          label="Canceladas"
        />
      </FormGroup>
    </React.Fragment>
  );
};

export default StatusFilter;

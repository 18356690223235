import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useAuthenticate from '../../../recoil/hooks/authenticate';
import { getScoreboardDetails } from '../../../api/scoreboard';
import Presentation from './Presentation';
import Fight from './Fight';
import useEvent from '../../../recoil/hooks/event';
import { EventTypeDrawFight } from '../../../interfaces/event';
import useLoading from '../../../recoil/hooks/loading';
import { createAreaBroadcastRequest } from '../../../api/broadcast';
import { mapStage } from '../../../utils/fight';

const Scoreboard: React.FC = (): ReactElement => {
  const navigate = useNavigate();

  const { setLoadingModal } = useLoading();

  const { authenticate } = useAuthenticate();

  const { event } = useEvent();

  const isGoldenScore = useMemo(() => {
    return event?.typeDrawFight === EventTypeDrawFight.GOLDEN_SCORE;
  }, [event]);

  const { eventId, areaId, boardId } = useParams();

  const [scoreDetails, setScoreDetails] = useState<any>(null);

  const initAreaBroadcast = useCallback(
    async (token: string, data: any) => {
      const athleteOne = data?.athletes?.[0];
      const athleteTwo = data?.athletes?.[1];

      const body = {
        Categoria: data?.eventAreaCategory?.categoryName,
        Etapa: mapStage(data?.stage),
        NomeAtleta1: athleteOne?.nameAthlete,
        Equipe1: athleteOne?.gym || '',
        Pontos1: 0,
        Vantagens1: 0,
        Faltas1: 0,
        FotoAtleta1: athleteOne?.photo || '',
        TeamFoto1: '',
        NomeAtleta2: athleteTwo?.nameAthlete,
        Equipe2: athleteTwo?.gym || '',
        Pontos2: 0,
        Vantagens2: 0,
        Faltas2: 0,
        FotoAtleta2: athleteTwo?.photo || '',
        TeamFoto2: '',
        TempoCronograma: data?.eventAreaCategory?.timeCategory * 60,
        EstadoLuta: 0,
        TeveAtletaGanhador: false,
        Motivo: '',
        AtletaGanhador: '',
        EquipeGanhador: '',
        FotoAtletaGanhador: '',
        NomeEvento: event?.name || '',
        // token: 'token',
      };

      try {
        await createAreaBroadcastRequest(
          token,
          data?.eventAreaCategory?.eventArea?.uuid,
          body
        );
      } catch (erro) {
        // eslint-disable-next-line no-console
        console.log('erro', erro);
      }
    },
    [event]
  );

  const getData = useCallback(async () => {
    try {
      setLoadingModal(true);
      if (authenticate.token && boardId) {
        const data = await getScoreboardDetails(authenticate.token, boardId);

        if (
          data?.details?.status === 'completed' ||
          data?.details?.status === 'bye'
        ) {
          navigate(`/dash/events/${eventId}/scoreboard/${areaId}`);
          return;
        }

        const broadcast =
          data?.details?.eventAreaCategory?.eventArea?.broadcast || false;

        setScoreDetails(data?.details || null);

        if (broadcast) {
          await initAreaBroadcast(authenticate.token, data?.details);
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    } finally {
      setLoadingModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticate.token, boardId]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (scoreDetails?.type === 'presentation') {
    return <Presentation scoreDetails={scoreDetails} />;
  }

  return (
    <Fight
      scoreDetails={scoreDetails}
      getData={getData}
      isGoldenScore={isGoldenScore}
    />
  );
};

export default Scoreboard;

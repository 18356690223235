/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import api from '../services/api';

export const getEvents = async (token: string): Promise<any> => {
  const result = await api.get('/api/events', {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const selectEvent = async (
  token: string,
  eventId: string
): Promise<any> => {
  const result = await api.post(
    '/api/select-event',
    { eventId },
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const getPublicEvents = async (): Promise<any> => {
  const result = await api.get('/api/public/events', {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result?.data;
};

export const getPublicEventAreas = async (eventId: string): Promise<any> => {
  const result = await api.get(`/api/public/events/${eventId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result?.data;
};

export const getPublicBracket = async (
  eventId: string,
  bracketId: string
): Promise<any> => {
  const result = await api.get(
    `/api/public/events/${eventId}/brackets/${bracketId}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return result?.data;
};

export interface EditEventRequest {
  startDate: string;
  endDate: string;
  name: string;
  startTimeFight: string;
  numberDaysFight: string;
  timeBetweenFight: string;
  timeBetweenCategory: string;
  timeCategoryOneAthlete: string;
  numberAreas: string;
  numberThird: string;
  typeKey3: string;
}

export const editEvent = async (
  event: string,
  data: EditEventRequest,
  token: string
): Promise<void> => {
  await api.put(`/api/events/${event}`, data, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });
};

export const getEvent = async (
  token: string,
  eventId: string
): Promise<any> => {
  const result = await api.get(`/api/events/${eventId}`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const getStatistics = async (
  token: string,
  eventId: string
): Promise<any> => {
  const result = await api.get(`/api/events/${eventId}/statistics`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const getUsers = async (
  token: string,
  eventId: string,
  search?: string
): Promise<any> => {
  const result = await api.get(
    `/api/events/${eventId}/users?search=${search || ''}`,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

interface AddUserBody {
  phone: string;
  name: string;
  role: string;
  areas: string[];
}

export const addUser = async (
  token: string,
  eventId: string,
  body: AddUserBody
): Promise<any> => {
  const result = await api.post(`/api/events/${eventId}/users`, body, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const deleteUser = async (
  token: string,
  eventId: string,
  userId: number
): Promise<any> => {
  const result = await api.delete(`/api/events/${eventId}/users/${userId}`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const getEventCategories = async (
  token: string,
  eventId: string
): Promise<any> => {
  const result = await api.get(`/api/events/${eventId}/categories`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const getEventAreas = async (
  token: string,
  eventId: string
): Promise<any> => {
  const result = await api.get(`/api/events/${eventId}/areas`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const getEventAreasCode = async (
  token: string,
  eventId: string
): Promise<any> => {
  const result = await api.get(`/api/events/${eventId}/areas-code`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const getEventAreasFights = async (
  token: string,
  eventId: string
): Promise<any> => {
  const result = await api.get(`/api/events/${eventId}/areas-fights`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const changeAthletesRequest = async (
  token: string,
  eventId: string,
  from: string,
  to: string
): Promise<any> => {
  const result = await api.patch(
    `/api/events/${eventId}/change-athletes`,
    {
      from,
      to,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const changeFightAreaRequest = async (
  token: string,
  eventId: string,
  fightId: string,
  areaId: string,
  fightIdToOver?: string
): Promise<any> => {
  const result = await api.patch(
    `/api/events/${eventId}/change-fight-area`,
    {
      fightId,
      areaId,
      fightIdToOver,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const changeFightsRequest = async (
  token: string,
  eventId: string,
  from: string,
  to: string
): Promise<any> => {
  const result = await api.patch(
    `/api/events/${eventId}/change-fights`,
    {
      from,
      to,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const verifyAthleteRequest = async (
  token: string,
  eventId: string,
  athleteUuid: string
): Promise<any> => {
  await api.post(
    `/api/events/${eventId}/areas-fights-athletes/${athleteUuid}/verify-athlete`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );
};

export const finishCategoryAwardRequest = async (
  token: string,
  eventId: string,
  categoryUuid: string
): Promise<any> => {
  await api.post(
    `/api/events/${eventId}/areas-fights-category/${categoryUuid}/finish-award`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );
};

export const deliveryAwardRequest = async (
  token: string,
  eventId: string,
  athleteUuid: string
): Promise<any> => {
  await api.post(
    `/api/events/${eventId}/areas-fights-athletes/${athleteUuid}/delivery-award`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getAbsoluteCategories = async (
  token: string,
  eventId: string,
  athleteUuid: string
): Promise<any> => {
  const result = await api.get(
    `/api/events/${eventId}/areas-fights-athletes/${athleteUuid}/absolute`,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const subscribeAthleteAbsoluteRequest = async (
  token: string,
  eventId: string,
  athleteUuid: string,
  action: boolean,
  category?: string
): Promise<any> => {
  await api.post(
    `/api/events/${eventId}/areas-fights-athletes/${athleteUuid}/absolute-subscribe`,
    { action, category },
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getEventAreaFights = async (
  token: string,
  eventId: string,
  areaId: string
): Promise<any> => {
  const result = await api.get(
    `/api/events/${eventId}/areas-fights/${areaId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const getEventFilters = async (eventId: string): Promise<any> => {
  const result = await api.get(`/api/events/${eventId}/filters`, {
    headers: {
      'Content-Type': 'application/json',
      // authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const saveEventAreas = async (
  token: string,
  eventId: string,
  generateFights: boolean,
  data: any
): Promise<any> => {
  await api.post(
    `/api/events/${eventId}/areas-fight`,
    { data, generateFights },
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );
};

interface AddEventRequest {
  name: string;
  location: string;
  date: Date;
  photo: string;
}

export const addEvent = async (
  data: AddEventRequest,
  token: string
): Promise<void> => {
  await api.post('/api/events', data, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });
};

export const getReasonDesclassifiedRequest = async (
  token?: string
): Promise<any> => {
  const result = await api.get(
    `/api/public/reason-desclassified`,

    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const handleCheckinRequest = async (
  token: string,
  data: any
): Promise<any> => {
  await api.post(`/api/checkin/${data.id}`, data, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });
};

export default {
  getEvent,
  editEvent,
  getEventCategories,

  getEvents,
  addEvent,
};

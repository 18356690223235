import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { Avatar, Badge, Button, CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { StyleBox, StyleContent, StyleContentButton } from './styles';
import { mapStage } from '../../../../utils/fight';
import { formatSecondsToMinutesAndSeconds } from '../../../../utils/date';

interface Props {
  open: boolean;
  handleClose: () => void;
  fight?: any;
  handleChangeWinner: (fightId: string) => void;
  handleResetFight: (fightId: string) => void;
  isLoading: boolean;
}

function Athlete({ athlete }: any) {
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 24,
        padding: '24px 0px',
      }}
    >
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={
          athlete?.win ? (
            <div style={{ position: 'relative' }}>
              <CheckCircleIcon
                style={{
                  width: 36,
                  height: 36,
                  zIndex: 2,
                  position: 'relative',
                }}
                htmlColor="lightgreen"
              />
              <div
                style={{
                  background: '#fff',
                  width: 36,
                  height: 36,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  zIndex: 1,
                  borderRadius: 100,
                }}
              ></div>
            </div>
          ) : undefined
        }
      >
        <Avatar
          alt={athlete?.nameAthlete}
          src={athlete?.image}
          sx={{ width: 96, height: 96 }}
          style={{
            border: athlete?.win ? '2px solid lightgreen' : '2px solid #777',
          }}
        />
      </Badge>
      <div style={{ textAlign: 'center' }}>{athlete?.name}</div>
    </div>
  );
}

export default function ModalDetails({
  open,
  handleClose,
  fight,
  handleChangeWinner,
  handleResetFight,
  isLoading,
}: Props): React.ReactElement {
  const getTeam = (teams: any[], index: number) => {
    return teams?.find((item) => item.order === index);
  };

  const winReason = fight?.teams?.[0]?.result?.typeVictoryDescription;
  const time = fight?.teams?.[0]?.result?.timeFight;

  const athleteOne = getTeam(fight?.teams, 0);
  const athleteTwo = getTeam(fight?.teams, 1);

  const hasDesclassification = React.useMemo(() => {
    return fight?.teams?.some(
      (team: any) => !!team?.reasonDesclassificationCode
    );
  }, [fight?.teams]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open && Boolean(fight)}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <StyleBox>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Detalhes da luta
          </Typography>
          <StyleContent>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 4,
                padding: '8px 0px',
              }}
            >
              <div>Round</div>
              <div style={{ fontWeight: 'bold' }}>{mapStage(fight?.stage)}</div>
            </div>
            <div style={{ width: '100%', height: 1, background: '#ccc' }}></div>
            <div style={{ display: 'flex' }}>
              <Athlete athlete={athleteOne} />
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  fontSize: 36,
                }}
              >
                {formatSecondsToMinutesAndSeconds(time || 0)}
              </div>
              <Athlete athlete={athleteTwo} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 4,
                padding: '8px 0px',
              }}
            >
              <div>Venceu por</div>
              <div style={{ color: 'green' }}>{winReason}</div>
            </div>
            <div style={{ display: 'flex', padding: '24px 0px' }}>
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 4,
                }}
              >
                <div
                  style={{
                    background: '#eee',
                    borderRadius: 8,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 20,
                    padding: 8,
                    boxSizing: 'border-box',
                  }}
                >
                  {athleteOne?.result?.points || '0'}
                </div>
                <div
                  style={{
                    background: '#eee',
                    borderRadius: 8,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 20,
                    padding: 8,
                    boxSizing: 'border-box',
                  }}
                >
                  {athleteOne?.result?.advantages || '0'}
                </div>
                <div
                  style={{
                    background: '#eee',
                    borderRadius: 8,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 20,
                    padding: 8,
                    boxSizing: 'border-box',
                  }}
                >
                  {athleteOne?.result?.faults || '0'}
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 4,
                }}
              >
                <div
                  style={{
                    borderRadius: 8,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 20,
                    padding: 8,
                    boxSizing: 'border-box',
                  }}
                >
                  Pontos
                </div>
                <div
                  style={{
                    borderRadius: 8,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 20,
                    padding: 8,
                    boxSizing: 'border-box',
                  }}
                >
                  Vantagens
                </div>
                <div
                  style={{
                    borderRadius: 8,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 20,
                    padding: 8,
                    boxSizing: 'border-box',
                  }}
                >
                  Faltas
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 4,
                }}
              >
                <div
                  style={{
                    background: '#eee',
                    borderRadius: 8,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 20,
                    padding: 8,
                    boxSizing: 'border-box',
                  }}
                >
                  {athleteTwo?.result?.points || '0'}
                </div>
                <div
                  style={{
                    background: '#eee',
                    borderRadius: 8,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 20,
                    padding: 8,
                    boxSizing: 'border-box',
                  }}
                >
                  {athleteTwo?.result?.advantages || '0'}
                </div>
                <div
                  style={{
                    background: '#eee',
                    borderRadius: 8,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 20,
                    padding: 8,
                    boxSizing: 'border-box',
                  }}
                >
                  {athleteTwo?.result?.faults || '0'}
                </div>
              </div>
            </div>
          </StyleContent>
          <StyleContentButton>
            <Button
              color="primary"
              variant="outlined"
              disableRipple
              onClick={() => handleClose()}
              disabled={isLoading}
              style={{
                flex: 1,
                letterSpacing: 1,
                fontSize: 14,
                marginTop: 16,
              }}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Fechar'}
            </Button>
            {fight?.teams?.length !== 1 && !hasDesclassification && (
              <>
                <Button
                  color="primary"
                  variant="contained"
                  disableRipple
                  onClick={() => handleChangeWinner(fight?.id)}
                  disabled={isLoading}
                  style={{
                    flex: 1,
                    letterSpacing: 1,
                    fontSize: 14,
                    marginTop: 16,
                  }}
                >
                  {isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    'Alterar vencedor'
                  )}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  disableRipple
                  onClick={() => handleResetFight(fight?.id)}
                  disabled={isLoading}
                  style={{
                    flex: 1,
                    letterSpacing: 1,
                    fontSize: 14,
                    marginTop: 16,
                  }}
                >
                  {isLoading ? <CircularProgress size={24} /> : 'Resetar luta'}
                </Button>
              </>
            )}
          </StyleContentButton>
        </StyleBox>
      </Fade>
    </Modal>
  );
}

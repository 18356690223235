import styled from 'styled-components';

export const StyledRoot = styled.span`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const StyledDrawer = styled('div')({
  maxWidth: 275,
  minHeight: '100vh',
  '@media (min-width: 900px) ': {
    width: 275,
  },
});

export const StyledFightOne = styled('div')<{ ligth: boolean }>(
  ({ ligth }) => ({
    flex: 1,
    display: 'flex',

    padding: '1vw',
    boxSizing: 'border-box',
    background: !ligth ? '#2B4755' : undefined,
    color: !ligth ? '#FFF' : undefined,
  })
);

export const StyledLeft = styled('div')({
  // flex: 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  // alignItems: 'center',
  gap: '2vh',
  width: '60%',
});

export const StyledRight = styled('div')({
  // flex: 1,
  display: 'flex',
  justifyContent: 'center',
  gap: '1vw',
  width: '40%',
});

export const StyledContentTime = styled('div')({
  flex: 1,
  display: 'flex',
  background: '#333',
});

export const StyledContentAvatar = styled('div')({
  // flex: 1,
  display: 'flex',
  justifyContent: 'center',
});

export const StyledContentName = styled('div')({
  display: 'flex',

  flexDirection: 'column',
  gap: 24,
});

export const StyledName = styled('div')({
  display: 'flex',
  fontSize: '8vh',
  fontWeight: 600,
  width: '60vw',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const StyledContentScorePoints = styled('div')({
  // background: '#333',
});

export const StyledContainerScore = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '1vw',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledReasonVictory = styled('div')({
  display: 'flex',

  fontSize: 52,
  fontWeight: 600,
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 32,
  textDecoration: 'underline',
});

export const StyledTeam = styled('div')({
  display: 'flex',
  fontSize: 32,
  textAlign: 'center',
});

export const StyledContentScore = styled('div')<{ color?: string }>(
  ({ color }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    background: color,
    height: '100%',
  })
);

export const StyledContentNumberPoints = styled('div')({
  minWidth: 300,
  width: '18vw',
  height: '100%',
  fontSize: '15vh',
  fontWeight: 600,
  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#4caf50',
  borderRadius: 8,
});

export const StyledContentNumber = styled('div')<{ color?: string }>(
  ({ color }) => ({
    minWidth: 250,
    width: '9vw',
    height: 600,
    flex: 1,
    fontSize: '10vh',
    fontWeight: 600,
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: color,
    borderRadius: 8,
  })
);

export const StyledContentControl = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 12,
  fontSize: 28,
});

export const StyledContentControlButton = styled('div')({
  background: 'gray',
  width: 40,
  height: 40,
  borderRadius: 40,
  color: '#fff',
  display: 'flex',
  cursor: 'pointer',
  justifyContent: 'center',
  alignItems: 'center',
});
